import React, { Component } from "react";
import PropTypes from "prop-types";
import button from "./button-simple.svg";

class Button extends Component {
  render() {
    return (
      <span className="slider-button">
        <img
          src={button}
          width={37}
          alt=""
          onDragStart={e => {
            e.preventDefault();
            return false;
          }}
        />
      </span>
    );
  }
}

Button.propTypes = {
  patient: PropTypes.string.isRequired,
  isRevealed: PropTypes.bool.isRequired,
  currentTourStepOpen: PropTypes.number.isRequired,
  onTourStep: PropTypes.func.isRequired,
  onTourClose: PropTypes.func.isRequired
};

export default Button;
