import React, { Component } from "react";
import { Page } from "../../components/Page";

import AnnualChangeTKV from "../../images/annual-change-tkv.jpg";

import "../../css/OtherRiskFactors.scss";

export default class OtherRiskFactors extends Component {
  render() {
    return (
      <Page
        title="Prognostic Tools - Other Risk Factors"
        className="other-risk-factors"
      >
        <h1 className="title">Other Risk Factors</h1>
        <h3 className="title">Overweight and Obesity</h3>
        <p>
          <strong>
            Obesity was associated with nearly four times greater adjusted odds
            of progressing at an annual rate of change in TKV of{" "}
            <code>&ge;</code> 7% compared with &lt; 5%. Obesity was also
            independently associated with greater eGFR decline (slope) versus
            normal weight.<sup>1</sup>
          </strong>
        </p>
        <div className="flex-container">
          <ul>
            <li>
              Four hundred forty-one participants with early ADPKD who
              participated in HALT-PKD Study A, were included in the analysis of
              the association of overweight and obesity with change in TKV.
            </li>
            <li>
              Participants were categorized by baseline BMI*:
              <ul>
                <li>
                  Normal weight: 18.5–24.9 kg/m<sup>2</sup> (n<code>=</code>192)
                </li>
                <li>
                  Overweight: 25.0–29.9 kg/m<sup>2</sup> (n<code>=</code>168)
                </li>
                <li>
                  Obese: <code>&ge;</code> 30 kg/m<sup>2</sup> (n=81)
                </li>
              </ul>
            </li>
            <li>
              Overweight and obesity was associated with increased odds of
              annual percent change in TKV <code>&ge;</code> 7% compared with
              &lt; 5% (overweight: OR, 2.02; 95% CI, 1.15 to 3.56; obese: OR,
              3.76; 95% CI, 1.81 to 7.80)+.
            </li>
          </ul>
          <div style={{ flex: 1.5 }}>
            <img src={AnnualChangeTKV} alt="Annual % Change in TKV" />
          </div>
        </div>
        <p>
          <h3 className="title">Albuminuria and Proteinuria in ADPKD</h3>
          <strong>
            CRISP cohort provided longitudinal evidence for associations between
            elevated albumin-to-creatinine ratio (ACR), increased TKV and
            decreased eGFR2<sup>2</sup>
          </strong>
        </p>
        <div className="flex-container">
          <div className="box">
            <h4>Albuminuria</h4>
            <ul>
              <li>
                Microalbuminuria is more common than proteinuria, occurring in
                35% of ADPKD individuals.<sup>2</sup>
              </li>
              <li>
                Direct evidence that reducing albuminuria in ADPKD affects
                progression to ESKD is not currently available<sup>3</sup>
                <ul>
                  <li>
                    Some evidence that an elevated ACR is a risk factor for
                    progressive disease and should be included in the decision
                    to target lower blood pressures and increase the dose of
                    RAAS inhibitors.<sup>3</sup>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div className="box">
            <h4>Proteinuria</h4>
            <ul>
              <li>
                Dipstick-detectable proteinuria occurs in &lt;18% with most
                demonstrating &lt;1 g/24 h.<sup>2</sup>
              </li>
              <li>
                Overt proteinuria is uncommon in ADPKD, with only 27%
                demonstrating 300 mg/d.<sup>4</sup>
              </li>
              <li>
                Proteinuria &gt;2 g/d is unusual and suggests the presence of
                another kidney disease.<sup>4</sup>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer-section">
          <p>
            *BMI was calculated from an adjusted weight removing the
            contribution of weight of the kidney and liver; +fully adjusted
            model.
          </p>
          <p className="glossary">
            eGFR=estimated glomerular filtration rate; ADPKD=autosomal dominant
            polycystic kidney disease; TKV=total kidney volume; CI=confidence
            interval; ESKD=end stage kidney disease; htTKV=height-adjusted total
            kidney volume; BMI=body mass index; HALT-PKD study= HALT Progression
            of Polycystic Kidney Disease (HALT-PKD) study; ACR=
            albumin-to-creatinine ratio; RAAS=Renin-angiotensin-aldosterone
            system
          </p>
          <div className="references">
            <strong className="title">References:</strong>
            <p>1. Nowak et al. J Am Soc Nephrol 29: 571–578, 2018.</p>
            <p>
              2. Rizk, D et al. Cystic and Inherited Kidney Diseases American
              Journal of Kidney Diseases, Vol 42, No 6 (December), 2003: pp
              1305-1317.
            </p>
            <p>
              3. Lanktree MB, et al. New treatment paradigms for ADPKD: moving
              towards precision medicine. Nat Rev Nephrol. 2017;13(12):750-768.
            </p>
            <p>
              4. Chapman AB. Autosomal dominant polycystic kidney disease: time
              for a change? J Am Soc Nephrol. 2007;18(5):1399-407.
            </p>
          </div>
        </div>
      </Page>
    );
  }
}
