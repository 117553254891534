import React, { Component } from "react";
import { Page } from "../components/Page"
import "../css/HomePage.scss";

export default class PageNotFound extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Page title="Page Not Found (404)" className="adpkd-homepage not-found">
        <h1 className="title">We're sorry.</h1>
        <p>The page you're looking for could not be found.</p>
        <p>Try using the navigation above to find the information you need.</p>
      </Page>
    );
  }
}
