import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { Motion, spring } from "react-motion";
import { Point } from "victory";
import Callout from "./eGFRCallout";
const size = 4;

class AnimatedPoint extends PureComponent {
  renderChildren() {
    const children = React.Children.toArray(this.props.children);
    return children.map(child => {
      const style = {
        ...child.props.style,
        ...this.props.style
      };
      return React.cloneElement(
        child,
        Object.assign({}, child.props, this.props, {
          style
        })
      );
    });
  }

  render() {
    const { seekYear, scale, datum } = this.props;
    const fill =
      datum.history === true || datum.baseline === true ? "#1e2f35" : "#66b6ca";
    return datum.year <= seekYear ? (
      <g transform={`translate(${this.props.x}, ${this.props.y})`}>
        <Motion
          defaultStyle={{
            size: 10 * scale,
            opacity: 0
          }}
          style={{
            size: spring(size * scale, {
              stiffness: 100,
              damping: 26
            }),
            opacity: spring(1, {
              stiffness: 100,
              damping: 26
            })
          }}
        >
          {value => (
            <g>
              <Point
                datum={datum}
                size={value.size}
                x={0}
                y={0}
                style={{ fill, opacity: value.opacity }}
              />

              {datum.callout !== undefined && (
                <Callout
                  text={datum.callout}
                  opacity={value.opacity}
                  scale={scale}
                />
              )}
            </g>
          )}
        </Motion>

        {this.renderChildren()}
      </g>
    ) : null;
  }
}

AnimatedPoint.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  seekYear: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired,
  datum: PropTypes.object
};

export default AnimatedPoint;
