import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Page } from "../../components/Page";
import PageNotFound from "../404";
import videoData from "./videoData";
import VideoModal from "./videoModal";
// import { shuffleArray } from "../../helpers/shuffleArray";
import NavigatorModal from "../../components/NavigatorModal";
import NephuLogo from "../../images/NephU-Logo.jpg";
import assessmentTool from "../../images/assessmentTool.jpg";
import burdensOfPKD from "../../images/burdens-of-pkd-thumb.jpg";
import determiningRateOfProgressionPoster from "../../images/determining-rate-of-progression-poster.jpg";
import diseaseProgression from "../../images/diseaseProgression.jpg";
import glomerularFiltration from "../../images/glomerularFiltration.jpg";
import imagingMethodology from "../../images/imagingMethodology.jpg";
import lifeChangingEffectsOfPKD from "../../images/life-changing-effects.jpg";
import physicalSymptomsOfPKD from "../../images/physical-symptoms-of-pkd.jpg";
import understandingADPKD from "../../images/understandingADPKD.jpg";

import AccurateDiagnosisModal from "../../components/AccurateDiagnosisModal";
import "../../css/ResourcesHomepage.scss";
import PdfModal from "./pdfModal";

import { isMobileApp } from "../../helpers/compat";

const pageTitles = [
  "Autosomal Dominant Polycystic Kidney Disease (ADPKD) Clinical Education Resources",
  "Autosomal Dominant Polycystic Kidney Disease (ADPKD) Patient Perspectives",
  "Autosomal Dominant Polycystic Kidney Disease (ADPKD) Additional Information"
];

const ResourceCrumbs = {
  resources: "ADPKD Clinical Education",
  "adpkd-clinical-education": "ADPKD Clinical Education",
  "adpkd-patient-perspectives": "ADPKD Patient Perspective",
  "additional-information": "Additional Information"
};

const pageIndex = {
  resources: 0,
  "adpkd-clinical-education": 0,
  "adpkd-patient-perspectives": 1,
  "additional-information": 2
};

const videoTitles = [
  [
    "determiningRateOfProgression",
    "diseaseProgression",
    "glomerularFiltration",
    "assessmentTool",
    "imagingMethodology",
    "understandingADPKD"
  ],
  ["burdensOfPKD", "lifeChangingEffectsOfPKD", "physicalSymptomsOfPKD"]
];

const videoPosters = {
  diseaseProgression: diseaseProgression,
  glomerularFiltration: glomerularFiltration,
  assessmentTool: assessmentTool,
  imagingMethodology: imagingMethodology,
  determiningRateOfProgression: determiningRateOfProgressionPoster,
  understandingADPKD: understandingADPKD,
  burdensOfPKD: burdensOfPKD,
  lifeChangingEffectsOfPKD: lifeChangingEffectsOfPKD,
  physicalSymptomsOfPKD: physicalSymptomsOfPKD
};

export default class ResourcesHomepage extends Component {
  constructor(props) {
    super(props);
    const tabIndex = pageIndex[this.props.match.params.page || "resources"];
    this.state = {
      tabIndex,
      currentVideo: "",
      currentPdf: "",
      loadingPdf: false,
      isModalOpen: false,
      showNavigationModal: false,
      showAccurateDiagnosisModal: false
    };

    this.handleVideoOpen = this.handleVideoOpen.bind(this);
    this.handleVideoClose = this.handleVideoClose.bind(this);
    this.handlePdfOpen = this.handlePdfOpen.bind(this);
    this.handlePdfClose = this.handlePdfClose.bind(this);
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.page &&
      this.state.tabIndex !== pageIndex[this.props.match.params.page]
    ) {
      const tabIndex = pageIndex[this.props.match.params.page];
      this.setState({ tabIndex });
    }
  }
  handleVideoOpen(e) {
    this.setState({
      currentVideo: videoData[e.target.attributes.name.value],
      isModalOpen: true
    });
  }
  handleVideoClose() {
    this.setState({
      currentVideo: false,
      isModalOpen: false
    });
  }
  handlePdfOpen(pdf) {
    this.setState({
      loadingPdf: true
    });
    setTimeout(() => {
      this.setState({
        currentPdf: pdf,
        isModalOpen: true
      });
    }, 100);
  }
  handlePdfClose() {
    this.setState({
      currentPdf: false,
      isModalOpen: false
    });
  }
  render() {
    const currentLocation = this.props.match.params.page || "resources";
    const currentResourcePage = this.state.tabIndex;
    const randomVideo = videoTitles[currentResourcePage];
    const videoRows = randomVideo
      ? randomVideo.map((el, index) => {
          return (
            <div key={el} className={index === 0 ? "hero" : "row"}>
              <div className="media-container">
                <img
                  name={el}
                  src={videoPosters[el]}
                  alt={videoData[el].title}
                  onClick={this.handleVideoOpen}
                />
              </div>
              <div className="media-description">
                <h1 className="title">{videoData[el].title}:</h1>
                <h3 className="subtitle">{videoData[el].subtitle}</h3>
                <p className="description">{videoData[el].description}</p>
                <div className="link-container">
                  <span
                    name={el}
                    className={`video-link${videoData[el].pdf ? "" : " last"}`}
                    onClick={this.handleVideoOpen}
                  >
                    Watch Video
                  </span>
                  {videoData[el].pdf && navigator.userAgent !== "ReactSnap" ? (
                    isMobileApp() ? (
                      <span
                        className="pdf-link"
                        onClick={() => {
                          this.handlePdfOpen(videoData[el].pdf);
                        }}
                      >
                        View PDF
                      </span>
                    ) : (
                      <a
                        className="pdf-link"
                        href={
                          process.env.REACT_APP_PUBLIC_URL + videoData[el].pdf
                        }
                        rel="noopener noreferrer"
                        target="_blank"
                        title={videoData[el].title}
                      >
                        Download as PDF
                      </a>
                    )
                  ) : null}
                </div>
              </div>
            </div>
          );
        })
      : null;
    const videoPage = (
      <>
        <h1 className="title">{ResourceCrumbs[currentLocation]}</h1>
        {videoRows}
        {this.state.currentVideo && (
          <VideoModal
            isShowing={this.state.isModalOpen}
            video={this.state.currentVideo}
            onClose={this.handleVideoClose}
          />
        )}
        {this.state.loadingPdf && (
          <div className="pdf-loading">
            <p>Loading...</p>
          </div>
        )}
        {this.state.currentPdf && (
          <PdfModal
            isShowing={this.state.isModalOpen}
            pdf={this.state.currentPdf}
            onClose={this.handleVideoClose}
            onLoaded={() => this.setState({ loadingPdf: false })}
          />
        )}
      </>
    );
    const pkdPage = (
      <div className="pkd-page">
        <div className="image-container">
          <img src={NephuLogo} alt="PKD Network Logo" />
        </div>
        <div className="information">
          <p>
             NephU is an engaged community of health care professionals and
            advocates collaborating to improve kidney health. Through increased
            awareness, collaboration, and information-sharing, their mission is
            to improve the future outcomes for individuals with kidney disease
            and other related conditions. They provide the latest information
            and resources regarding kidney disease topics. {" "}
          </p>
          <button
            className="button confirm"
            onClick={() => this.setState({ showNavigationModal: true })}
          >
            Go to NephU
          </button>
          <hr />
          <p>
            Latest guidance from Chebib and Torres 2021, on considering the
            age-adjusted kidney function and cystic burden for accurate
            diagnosis and prognosis of ADPKD cases
          </p>
          <button
            className="button confirm large"
            onClick={() => this.setState({ showAccurateDiagnosisModal: true })}
          >
            Establishing Accurate Diagnosis and Prognosis of ADPKD cases
          </button>
          <AccurateDiagnosisModal
            showModal={this.state.showAccurateDiagnosisModal}
            handleClose={() =>
              this.setState({ showAccurateDiagnosisModal: false })
            }
          />
        </div>
      </div>
    );
    return currentResourcePage !== undefined ? (
      <Page
        title={pageTitles[currentResourcePage]}
        className="resources-homepage"
      >
        <p className="page-crumb">Resources</p>
        <div className="tabbed-section-container">
          <div className="tabbed-top">
            <nav className="tab-nav">
              <Link
                className={currentResourcePage === 0 ? "tab selected" : "tab"}
                to="/expert/resources/adpkd-clinical-education"
              >
                <span>ADPKD Clinical Education</span>
              </Link>
              <Link
                className={currentResourcePage === 1 ? "tab selected" : "tab"}
                to="/expert/resources/adpkd-patient-perspectives"
              >
                <span>ADPKD Patient Perspective</span>
              </Link>
              <Link
                className={currentResourcePage === 2 ? "tab selected" : "tab"}
                to="/expert/resources/additional-information"
              >
                <span>Additional Information</span>
              </Link>
            </nav>
          </div>
        </div>
        {currentLocation === "additional-information" ? pkdPage : videoPage}
        <NavigatorModal
          showNavigationModal={this.state.showNavigationModal}
          link="https://nephu.org/"
          handleClose={() => this.setState({ showNavigationModal: false })}
        />
      </Page>
    ) : (
      <PageNotFound />
    );
  }
}
