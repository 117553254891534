import PropTypes from "prop-types";
import React, { Component } from "react";
import Kidney from "./Kidney";
import Slider from "./Slider";
import ZoomingCharts from "./ZoomingCharts";
// import ProfileTour from "../ProfileTour";
import { Motion, spring } from "react-motion";
import dataLayer from "../../../helpers/dataLayer";
import "./index.scss";
import legend from "./legend.svg";

const normalEgfr = [
  { year: 35, level: 85 },
  { year: 47, level: 50 }
];

const patientReferences = {
  Bill: "2-5,7-10",
  Angela: "3,6-10",
  Denise: "2,3,6-11",
  Frank: "2-6,8-10",
  Jeremy: "2,3,5-10",
  Marcus: "3-5",
  Jennifer: "4-6"
};

class Timeline extends Component {
  seek_ = 0;
  index_ = 7;
  isDragging_ = false;
  isResetting_ = true;

  constructor(props) {
    super(props);

    this.state = {
      seek: 0,
      index: 7,
      isDragging: false,
      tkvVal: 0,
      egfrVal: 0,
      kidneyDamageValue: 0,
      kidneyFunctionValue: 0
    };
  }

  render() {
    const { charts, tag } = this.props.patient;
    const segments = charts.tkv.data
      .filter((obj) => obj.ignoreTick !== true)
      .map((obj) => {
        const history = obj.history === true ? true : false;
        const baseline = obj.baseline === true ? true : false;
        return { year: obj.year, history, baseline };
      });

    return (
      <Motion
        defaultStyle={{ seek: 0.54 }}
        style={{
          seek:
            this.state.isDragging || this.isResetting_
              ? this.state.seek
              : spring(this.state.seek)
        }}
      >
        {({ seek }) => (
          <div className="patient-timeline">
            <h3 className="progression">
              <span className="header">
                Disease Progression<sup>{patientReferences[tag]}</sup>
              </span>
            </h3>
            <img className="legend" src={legend} alt="" />
            <div className="viz">
              <ZoomingCharts
                variant={this.props.variant}
                seek={seek}
                index={this.state.index}
                isRevealed={this.props.isRevealed}
                tkvVal={
                  this.state.isDragging
                    ? this.state.tkvVal
                    : this.getPartialValue(seek, charts.tkv.data)
                }
                egfrVal={
                  this.state.isDragging
                    ? this.state.egfrVal
                    : this.getPartialValue(seek, charts.egfr.data)
                }
                patientData={this.props.patient}
                normalEgfr={normalEgfr}
                currentTourStepOpen={this.props.currentTourStepOpen}
                onTourStep={this.props.onTourStep}
                onTourClose={this.props.onTourClose}
              />
              <Kidney seek={seek} patient={tag} />
              <div className="patient-slider">
                <Slider
                  variant={this.props.variant}
                  onStart={this.handleSlideStart}
                  onSlide={this.handleSlide}
                  onEnd={this.handleSlideEnd}
                  seek={seek}
                  segments={segments}
                  zoomFactor={this.props.zoomFactor}
                  isRevealed={this.props.isRevealed}
                  hasPartialData={this.props.hasPartialData}
                  patient={tag}
                  currentTourStepOpen={this.props.currentTourStepOpen}
                  onTourStep={this.props.onTourStep}
                  onTourClose={this.props.onTourClose}
                />
                {/** Tour Step End */}
                {/* {(tag === "Denise" || tag === "Jeremy") &&
              this.props.isRevealed ? (
                <ProfileTour variant={this.props.variant} 
                  stepNumber={6}
                  name={tag}
                  direction={"vert"}
                  currentTourStepOpen={this.props.currentTourStepOpen}
                  onTourStep={this.props.onTourStep}
                  onTourClose={this.props.onTourClose}
                />
              ) : tag === "Bill" || tag === "Frank" ? (
                <ProfileTour variant={this.props.variant} 
                  stepNumber={5}
                  name={tag}
                  direction={"vert"}
                  currentTourStepOpen={this.props.currentTourStepOpen}
                  onTourStep={this.props.onTourStep}
                  onTourClose={this.props.onTourClose}
                />
              ) : tag === "Angela" ? (
                <ProfileTour variant={this.props.variant} 
                  stepNumber={4}
                  name={tag}
                  direction={"vert"}
                  currentTourStepOpen={this.props.currentTourStepOpen}
                  onTourStep={this.props.onTourStep}
                  onTourClose={this.props.onTourClose}
                />
              ) : null} */}
              </div>
            </div>
          </div>
        )}
      </Motion>
    );
  }

  componentWillMount() {
    this.resetValues(this.props.patient.charts.tkv.data);
    // this.updateValues(baseTimelineSeek[this.props.patient.tag]);
    setTimeout(() => {
      this.isResetting_ = false;
    }, 500);
  }

  componentWillReceiveProps(nextProps) {
    if (
      this.props.patient.name !== nextProps.patient.name ||
      !nextProps.isRevealed
    ) {
      this.resetValues(nextProps.patient.charts.tkv.data);
    } else {
      this.updateValues(this.state.seek);
    }
  }

  resetValues = (data) => {
    this.isResetting_ = true;
    var index = 0;

    const values = data.filter((obj) => obj.ignoreTick !== true);
    for (var i = 0; i < values.length; i++) {
      const seg = values[i];
      if (seg.history !== true) {
        index = i;
        break;
      }
    }

    const seek = index / (values.length - 1);
    this.seek_ = seek;
    this.index_ = index;
    this.setState({ seek, index });
    this.updateValues(seek);

    setTimeout(() => {
      this.isResetting_ = false;
    }, 500);
  };

  updateValues = (seek) => {
    const { charts } = this.props.patient;
    const tkvVal = this.getPartialValue(seek, charts.tkv.data);
    const egfrVal = this.getPartialValue(seek, charts.egfr.data);
    if (seek !== this.state.seek) {
      this.setState({ seek, tkvVal, egfrVal });
    } else {
      this.setState({ tkvVal, egfrVal });
    }
  };

  getPartialValue = (seek, data) => {
    const ticks = data.filter((obj) => obj.ignoreTick !== true);
    const indexFloat = seek * (ticks.length - 1);
    var lowIndex = Math.floor(indexFloat);
    var highIndex = Math.ceil(indexFloat);

    if (highIndex === lowIndex) {
      if (lowIndex === 0) {
        highIndex = 1;
      } else if (highIndex === ticks.length - 1) {
        lowIndex -= 1;
      } else {
        highIndex += 1;
      }
    }

    var highVal = ticks[highIndex].level;
    var lowVal = ticks[lowIndex].level;
    const remainder = (indexFloat + 1) % (lowIndex + 1);

    return lowVal === 0 ? highVal : (highVal - lowVal) * remainder + lowVal;
  };

  handleSlideStart = () => {
    this.isDragging_ = true;
    this.setState({ isDragging: true });

    // Add event to tag manager data layer
    dataLayer.push({
      event: "simulator.event",
      category: "Interactive Tools",
      action: "Used Age Slider",
      label: `${this.props.patient.tag} - TKV Slider Used`
    });

    // Clean values so they are not picked up by future tags
    dataLayer.clean("category", "action", "label");

    requestAnimationFrame(this.handleAnimationFrame);
  };

  handleSlideEnd = ({ seek, index }) => {
    // console.debug("slide end", seek, index);
    this.isDragging_ = false;
    this.seek_ = seek;
    this.index_ = index;
    this.setState({ isDragging: false });
    requestAnimationFrame(this.handleAnimationFrame);
  };

  handleSlide = ({ seek, index }) => {
    this.seek_ = seek * this.props.zoomFactor;
    this.index_ = index;
  };

  handleAnimationFrame = () => {
    // console.debug("animation frame");
    // console.debug(this.seek_);
    // console.debug(this.index_);

    this.setState({
      seek: this.seek_,
      index: this.index_
    });

    this.updateValues(this.seek_);

    if (this.isDragging_ === true) {
      requestAnimationFrame(this.handleAnimationFrame);
    }
  };
}

Timeline.propTypes = {
  patient: PropTypes.object.isRequired,
  zoomFactor: PropTypes.number.isRequired,
  isRevealed: PropTypes.bool.isRequired,
  hasPartialData: PropTypes.bool.isRequired,
  currentTourStepOpen: PropTypes.number.isRequired,
  onReveal: PropTypes.func,
  onTourStep: PropTypes.func.isRequired,
  onTourClose: PropTypes.func.isRequired
};

export default Timeline;
