import React, { Component } from "react";
import Constants from "../helpers/Constants";
import calcVolume from "../helpers/CalculateVolume";
import dataLayer from "../helpers/dataLayer";

const steps = [
  <span className="steps">
    Start with the <br />
    basic formula:
  </span>,
  <span className="steps">
    Enter height <br />
    and calculate:
  </span>
];
const INCHES_TO_METERS = 39.37;

export default class CalculateHTTKVForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animateValues: false,
      animateResults: false,
      viewTracked: false,
      displayConverter: false,
      inchHeight: "",
      hasError: false,
      hasModalError: false
    };

    this.AnimatedHTTKVValuesRow = React.createRef();

    this.handleInchHeightChange = this.handleInchHeightChange.bind(this);
    this.handleHeightChange = this.handleHeightChange.bind(this);
    this.handleCalculateHTTKV = this.handleCalculateHTTKV.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.closeConverter = this.closeConverter.bind(this);
    this.openConverter = this.openConverter.bind(this);
    this.convertToMeters = this.convertToMeters.bind(this);
    this.timeouts = [];
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      // Accordion has been opened
      if (this.props.open) {
        this.animateTextInput();
        if (!this.state.viewTracked) {
          this.trackView();
          this.setState({ viewTracked: true });
        }
      } else {
        // Accordion has been closed
        this.timeouts.forEach(timeout => clearTimeout(timeout));
        this.timeouts = [];
        this.setState({
          animateValues: false,
          animateResults: false
        });
      }
    }
  }

  trackView() {
    // Send to data layer
    dataLayer.push({
      event: "tkv.calculator",
      action: "Viewed: Calculate htTKV"
    });
    // Clean values so they are not picked up by future tags
    dataLayer.clean("action");
  }
  // checkAnimateSteps(step, stepState) {
  //   const currentOffset =
  //     boxHeight < 4 ? step.offsetParent.offsetTop : step.offsetTop;
  //   const yOffset = window.pageYOffset;
  //   const scrollInstructionsIntoViewThreshold =
  //     currentOffset -
  //     window.innerHeight +
  //     step.getClientRects()[0].height * boxHeight;
  //   if (yOffset > scrollInstructionsIntoViewThreshold) {
  //     if (!this.state[stepState]) {
  //       window.removeEventListener("scroll", this.throttledScrollHandler);
  //       stepState.forEach((elem, k) => {
  //         setTimeout(() => {
  //           this.setState({ [elem]: true });
  //         }, k * 2 * Constants.TYPING_SPEED);
  //       });
  //       this.trackView();
  //     }
  //   }
  // }

  animateTextInput() {
    let i = 0;
    const stateKeys = ["animateValues", "animateResults"];

    stateKeys.forEach((elem, k) => {
      const timeout = setTimeout(() => {
        this.setState({ [stateKeys[i]]: true });
        i++;
      }, k * Constants.TYPING_SPEED);

      this.timeouts.push(timeout);
    });
  }
  handleInchHeightChange(e) {
    if (!isNaN(Number(e.target.value))) {
      this.setState({
        [e.target.name.split(" ").join("")]: e.target.value
      });
    }
  }
  handleHeightChange(e) {
    this.props.getHeight(e);
  }
  handleCalculateHTTKV() {
    this.setState({ hasError: false, hasTKVError: false });

    if (this.props.manualCalculation && !this.props.userInputTKVValue.length) {
      this.setState({ hasTKVError: true });
      return;
    }

    if (!this.props.height.length || parseFloat(this.props.height) >= 2.73) {
      this.setState({ hasError: true });
      return;
    }

    this.setState({ hasError: false, hasTKVError: false });
    this.props.calculateHTTKV();
  }
  handleClose(e) {
    if (e.target.className === "modal-shade") {
      this.closeConverter();
    }
  }
  closeConverter() {
    this.setState({ displayConverter: false, hasModalError: false });
  }
  openConverter() {
    this.setState({ displayConverter: true });
  }
  convertToMeters() {
    if (this.state.inchHeight === "") {
      this.setState({ hasModalError: true });
    } else {
      this.setState({ hasModalError: false });
      const height = Number(
        (Number(this.state.inchHeight) / INCHES_TO_METERS).toFixed(2)
      );
      this.props.getHeight(height);
      this.setState({ inchHeight: "", displayConverter: false });
    }
  }
  render() {
    const getHeight = this.props.calculateTKV;
    const calcHTTKVValue = this.props.displayResults;
    const leftTKV = calcVolume(
      this.props.leftKidneyLength,
      this.props.leftKidneyWidth,
      this.props.leftKidneyDepth
    );
    const rightTKV = calcVolume(
      this.props.rightKidneyLength,
      this.props.rightKidneyWidth,
      this.props.rightKidneyDepth
    );
    let TKVValue = 0;
    if (getHeight) {
      if (this.props.manualCalculation) {
        TKVValue = parseFloat(this.props.userInputTKVValue);
      } else {
        TKVValue = Number((Number(leftTKV) + Number(rightTKV)).toFixed(1));
      }
    }
    const htTKVValue = calcHTTKVValue
      ? Number((Number(TKVValue) / Number(this.props.height)).toFixed(1))
      : null;
    const animateText = this.props.patient !== "user";
    return (
      <div className={["calculate-httkv-form", this.props.className].join(" ")}>
        <div className="flex">
          {steps[this.props.step]}
          {getHeight ? (
            <div
              className="htTKV-calculation-container"
              ref={
                this.props.patient !== "user" && this.props.step
                  ? this.AnimatedHTTKVValuesRow
                  : null
              }
            >
              {this.state.hasError ? (
                <div className="error-container">
                  <p className="error">Please enter a valid height.</p>
                </div>
              ) : null}
              {this.state.hasTKVError ? (
                <div className="error-container">
                  <p className="error">Please enter a valid TKV value.</p>
                </div>
              ) : null}
              <div
                className={`formula-container${
                  this.props.manualCalculation ? " two-inputs" : ""
                }`}
              >
                {animateText ? (
                  <div
                    className={
                      this.state.animateValues
                        ? `animated ${Constants.animation.data}`
                        : "vis-hidden"
                    }
                  >
                    <span className="tkv-value">
                      {TKVValue.toLocaleString()}&nbsp;mL
                    </span>
                  </div>
                ) : this.props.manualCalculation ? (
                  <span className="tkv-value">
                    <input
                      placeholder="TKV"
                      value={this.props.userInputTKVValue}
                      maxLength="4"
                      onChange={this.props.getTKV}
                      onFocus={e => (e.target.placeholder = "")}
                      tabIndex={8}
                    />
                    &nbsp;mL
                  </span>
                ) : (
                  <span className="tkv-value">
                    {TKVValue.toLocaleString()}&nbsp;mL
                  </span>
                )}
                {this.props.patient === "user" ? (
                  <span className="height-value">
                    <input
                      placeholder="height"
                      value={this.props.height}
                      maxLength="4"
                      onChange={this.handleHeightChange}
                      onFocus={e => (e.target.placeholder = "")}
                      tabIndex={8}
                    />
                    &nbsp;m
                  </span>
                ) : animateText ? (
                  <div
                    className={
                      this.state.animateValues
                        ? `animated ${Constants.animation.data}`
                        : "vis-hidden"
                    }
                  >
                    <span className="height-value">
                      {this.props.height}&nbsp;m
                    </span>
                  </div>
                ) : (
                  <span className="height-value">
                    {this.props.height}&nbsp;m
                  </span>
                )}
              </div>
              {this.props.patient === "user" ? (
                <span
                  className="convert-open-link"
                  onClick={this.openConverter}
                  tabIndex={9}
                >
                  Prefer to use inches?
                </span>
              ) : null}
              {this.state.displayConverter ? (
                <div className="modal-shade" onClick={this.handleClose}>
                  <div className="converter-container">
                    <span className="close" onClick={this.closeConverter} />
                    <span className="prompt">
                      Enter height in inches below and convert.
                    </span>
                    {this.state.hasModalError ? (
                      <div className="error-container">
                        <p className="error">Please enter a valid height.</p>
                      </div>
                    ) : null}
                    <span className="height-value">
                      <input
                        name="inchHeight"
                        placeholder="height"
                        value={this.state.inchHeight}
                        maxLength="2"
                        onChange={this.handleInchHeightChange}
                        onFocus={e => (e.target.placeholder = "")}
                      />
                      &nbsp;in
                    </span>
                    <span
                      className="convert-confirm-link"
                      onClick={this.convertToMeters}
                    >
                      Convert to Meters
                    </span>
                  </div>
                </div>
              ) : null}
            </div>
          ) : (
            <div className="formula-container">
              <span className="tkv-value black">TKV</span>
              <span className="height-value black">height</span>
            </div>
          )}
          {getHeight ? (
            <span
              className="formula-result"
              ref={
                this.props.patient !== "user"
                  ? el => (this.AnimatedResultsRow = el)
                  : null
              }
            >
              =&nbsp;&nbsp;
              {this.props.displayResults ? (
                animateText ? (
                  <div
                    className={
                      this.state.animateResults
                        ? `animated ${Constants.animation.result}`
                        : "vis-hidden"
                    }
                  >
                    <span className="htTKV-value-container">
                      <div className="htTKV-title">htTKV</div>
                      <span className="htTKV-value">
                        {htTKVValue.toLocaleString()} mL/m
                      </span>
                    </span>
                  </div>
                ) : (
                  <span className="htTKV-value-container">
                    <div className="htTKV-title">htTKV</div>
                    <span className="htTKV-value">
                      {htTKVValue.toLocaleString()} mL/m
                    </span>
                  </span>
                )
              ) : (
                <button
                  className="calculate-htTKV-button"
                  onClick={this.handleCalculateHTTKV}
                  tabIndex={11}
                >
                  Calculate htTKV
                </button>
              )}
            </span>
          ) : (
            <span className="formula-result">=&nbsp;&nbsp;htTKV</span>
          )}
        </div>
      </div>
    );
  }
}
