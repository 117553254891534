import React, { Component } from "react";
import data from '../../profileData';

export default class NavDropdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }
  componentDidMount() { }
  render() {
    const patientParam = this.props.match.params.patient
      ? this.props.match.params.patient
      : "Bill";

    const caseStudies = data[this.props.variant];

    const patientNames = caseStudies.map((caseStudy, i) => {
      return (
        <a key={`${i}`} href={`${process.env.REACT_APP_PUBLIC_URL}/${this.props.variant}/adpkd-simulator/patient-profiles/${caseStudy.tag}`}>
          {caseStudy.tag}
        </a>
      );
    });


    return (
      <span
        className={`mobile-only mobile-patient-dropdown${this.state.isOpen ? " open" : ""}`}
      >
        <div
          className={`dropdown-shade${this.state.isOpen ? " open" : ""}`}
          onClick={() => this.setState({ isOpen: false })}
        />
        <div
          className={`current-patient${this.state.isOpen ? " open" : ""}`}
          onClick={() => this.setState({ isOpen: true })}
        >
          {patientParam}
          <div
            className={`dropdown-options${this.state.isOpen ? " open" : ""}`}
          >
            {patientNames}
          </div>
        </div>
      </span>
    );
  }
}
