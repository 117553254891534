import React, { Component } from "react";
import Select, { components } from "react-select";
import dataLayer from "../helpers/dataLayer";
import SelectArrow from "../images/select-arrow";
import "whatwg-fetch";

const API_ROOT = "/api/v1";
const EmailRegExp = RegExp(".+@.+\\..+");

export default class SignupModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedState: null,
      zipcode: "",
      selectedOccupation: null,
      agreeOptIn: false,
      firstName: "",
      lastName: "",
      email: "",
      hasStateError: false,
      hasZipcodeError: false,
      hasOccupationError: false,
      hasFirstNameError: false,
      hasLastNameError: false,
      hasEmailError: false,
      hasFormError: false,
      hasOptInError: false,
      isSubmitted: false,
      stateValues: "",
      occupationsValues: "",
      trackedView: false
    };

    this.form = React.createRef();
    this.validate = this.validate.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.getZipcode = this.getZipcode.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.trackView = this.trackView.bind(this);
  }
  componentDidMount() {
    if (navigator.userAgent === "ReactSnap") {
      return;
    }

    this.trackView();

    fetch(`${API_ROOT}/regions`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then(
        response => {
          return response.json();
        },
        error => {
          console.log("error: ", error);
        }
      )
      .then(values => {
        this.setState({ stateValues: values });
      });
    fetch(`${API_ROOT}/occupations`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json"
      }
    })
      .then(
        response => {
          return response.json();
        },
        error => {
          console.log("error: ", error);
        }
      )
      .then(values => {
        this.setState({ occupationsValues: values });
      });
  }
  componentDidUpdate() {
    this.trackView();
  }
  trackView() {
    // if we have already tracked the view this is a no-op
    if (this.state.trackedView) {
      return;
    }

    // Only track showing of modal
    if (!this.props.showModal) {
      return;
    }

    // Update state
    this.setState({ trackedView: true });

    dataLayer.push({
      event: "displayed.sign-up-form",
      modalReason: this.props.reason
    });
    dataLayer.clean("modalReason");
  }
  validate() {
    if (this.validateForm()) {
      this.setState({
        hasFormError: true,
        hasOptInError: !this.state.agreeOptIn
      });
    } else {
      let formData = {
        firstName: this.state.firstName,
        lastName: this.state.lastName,
        email: this.state.email,
        region: this.state.selectedState.value,
        zipcode: this.state.zipcode,
        occupation: this.state.selectedOccupation.value,
        optIn: true
      };

      fetch(`${API_ROOT}/signups`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify(formData)
      })
        .then(
          response => {
            return response.json();
          },
          error => {
            console.log("error: ", error);
          }
        )
        .then(values => {
          this.setState({ isSubmitted: values.success });
          dataLayer.push({
            event: "submitted.sign-up-form"
          });
        });
    }
  }
  validateForm() {
    let hasStateError = false,
      hasZipcodeError = false,
      hasOccupationError = false,
      hasFirstNameError = false,
      hasLastNameError = false,
      hasEmailError = false;

    if (this.state.firstName === "") {
      hasFirstNameError = true;
    }
    if (this.state.lastName === "") {
      hasLastNameError = true;
    }
    if (!EmailRegExp.test(this.state.email)) {
      hasEmailError = true;
    }
    if (this.state.selectedState === null) {
      hasStateError = true;
    }
    if (this.state.zipcode === "" || this.state.zipcode.length < 5) {
      hasZipcodeError = true;
    }
    if (this.state.selectedOccupation === null) {
      hasOccupationError = true;
    }

    this.setState({
      hasStateError,
      hasZipcodeError,
      hasOccupationError,
      hasFirstNameError,
      hasLastNameError,
      hasEmailError
    });
    return (
      hasStateError ||
      hasZipcodeError ||
      hasOccupationError ||
      hasFirstNameError ||
      hasLastNameError ||
      hasEmailError ||
      !this.state.agreeOptIn
    );
  }
  onAgreeChange(e) {
    this.setState({
      agreeOptIn: e.target.checked
    });
    trackFormField("legal opt-in", e.target.value);
  }
  onOptInChange(e) {
    this.setState({
      optIn: e.target.value === "true"
    });
    trackFormField("receive updates", e.target.value);
  }
  getZipcode(e) {
    let inputNum = e.target.value.trim().toString();
    let state = {};

    // reset field
    if (inputNum.length === 0) {
      state.zipcode = inputNum;
    }

    // insert '-'
    if (inputNum.length === 6) {
      if (e.target.value.charCodeAt(e.target.value.length - 1) === 45) {
        state.zipcode = inputNum;
      }
    } else if (!isNaN(Number(e.target.value[e.target.value.length - 1]))) {
      state.zipcode = inputNum;
    }

    // Track zip code
    if (inputNum.length >= 5 && this.state.zipcodeTracked !== true) {
      trackFormField("zip code", inputNum.substring(0, 3) + "xx");
    }

    this.setState(state);
  }
  handleClose(e) {
    this.setState({
      selectedState: null,
      zipcode: "",
      selectedOccupation: null,
      agreeOptIn: false,
      firstName: "",
      lastName: "",
      email: "",
      hasStateError: false,
      hasZipcodeError: false,
      hasOccupationError: false,
      hasFirstNameError: false,
      hasLastNameError: false,
      hasEmailError: false,
      hasFormError: false,
      hasOptInError: false,
      isSubmitted: false,
      trackedView: false
    });
    this.props.handleClose();
  }
  handleChange = (selectedOption, field) => {
    const stateName = `selected${field.name}`;
    this.setState({ [stateName]: selectedOption });
    trackFormField(field.name.toLowerCase(), selectedOption.label);
  };
  render() {
    const showModal = this.props.showModal;
    const stateOptions = this.state.stateValues
      ? this.state.stateValues.map(val => {
          return { value: val.code, label: val.name };
        })
      : [];
    const occupationsOptions = this.state.occupationsValues
      ? this.state.occupationsValues.map(val => {
          return { value: val, label: val };
        })
      : [];
    const customStyles = {
      option: () => ({
        cursor: "pointer",
        padding: 20,
        textAlign: "left"
      }),
      indicatorSeparator: () => ({
        display: "none"
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        position: "relative",
        backgroundColor: "#FFFFFF",
        borderWidth: 1,
        borderColor: "#B7B7B7",
        borderStyle: "solid",
        borderRadius: 5,
        cursor: "pointer",
        height: 36,
        width: 270,
        display: "flex"
      })
    };
    const DropdownIndicator = props => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <SelectArrow />
          </components.DropdownIndicator>
        )
      );
    };
    const hasFormError = this.state.hasFormError;
    return (
      showModal && (
        <div className="signup-modal">
          <div className="modal-shade" onClick={this.handleClose} />
          <div className={`content${this.state.isSubmitted ? " success" : ""}`}>
            <span
              className="close sign-up-button"
              onClick={this.handleClose}
              title="Close"
            />
            {this.state.isSubmitted ? (
              <div className="submitted">
                <h1>Thank you!</h1>
                <p>
                  You have successfully signed up to receive news and updates.
                </p>
                <button className="button submit" onClick={this.handleClose}>
                  Done
                </button>
              </div>
            ) : (
              <div className="form-container">
                <h1 className="title">Stay informed.</h1>
                <p>
                  We are committed to making the ADPKD Simulator even more
                  valuable for healthcare professionals. Sign up today to
                  receive news and updates on our educational tools and
                  resources.
                </p>
                <form ref={this.form} onSubmit={e => e.preventDefault()}>
                  <div className="opt-in-section">
                    <div className="field">
                      <p className="right-aligned">All fields required</p>
                    </div>
                    {hasFormError ? (
                      <div className="error-container">
                        <p className="error">
                          Please help us by completing all fields, and
                          correcting any fields marked with an X.
                        </p>
                      </div>
                    ) : null}
                    <div
                      className={`field${
                        this.state.hasFirstNameError ? " hasError" : ""
                      }`}
                    >
                      <label>First name</label>
                      <span>
                        <input
                          maxLength={50}
                          onChange={e => {
                            this.setState({ firstName: e.target.value });
                            trackFormField("first name", "xxxxxxxx");
                          }}
                        />
                        <div className="error-text">
                          Please provide your first name
                        </div>
                      </span>
                    </div>
                    <div
                      className={`field${
                        this.state.hasLastNameError ? " hasError" : ""
                      }`}
                    >
                      <label>Last name</label>
                      <span>
                        <input
                          maxLength={50}
                          onChange={e => {
                            this.setState({ lastName: e.target.value });
                            trackFormField("last name", "xxxxxxxx");
                          }}
                        />
                        <div className="error-text">
                          Please provide your last name
                        </div>
                      </span>
                    </div>
                    <div
                      className={`field${
                        this.state.hasEmailError ? " hasError" : ""
                      }`}
                    >
                      <label>Email address</label>
                      <span>
                        <input
                          type="email"
                          maxLength={100}
                          onChange={e => {
                            this.setState({ email: e.target.value });
                            trackFormField("email", "xxxxxxxx");
                          }}
                        />
                        <div className="error-text">
                          A valid email is required
                        </div>
                      </span>
                    </div>
                    <div className="other-fields-container">
                      <div
                        className={`field${
                          this.state.hasStateError ? " hasError" : ""
                        }`}
                      >
                        <label>State</label>
                        <span>
                          <Select
                            isSearchable={false}
                            className="select-container"
                            classNamePrefix="react-select"
                            components={{ DropdownIndicator }}
                            styles={customStyles}
                            placeholder=""
                            name="State"
                            value={this.state.selectedState}
                            onChange={this.handleChange.bind(this)}
                            options={stateOptions}
                          />
                          <div className="error-text">
                            Please select a state
                          </div>
                        </span>
                      </div>
                      <div
                        className={`field${
                          this.state.hasZipcodeError ? " hasError" : ""
                        }`}
                      >
                        <label>Zip code</label>
                        <span>
                          <input
                            value={this.state.zipcode}
                            maxLength={10}
                            onChange={this.getZipcode}
                            title="Zipcode"
                          />
                          <div className="error-text">
                            Please enter a valid zipcode
                          </div>
                        </span>
                      </div>
                      <div
                        className={`field${
                          this.state.hasOccupationError ? " hasError" : ""
                        }`}
                      >
                        <label>Primary Category of Occupation</label>
                        <span>
                          <Select
                            isSearchable={false}
                            className="select-container"
                            classNamePrefix="react-select"
                            components={{ DropdownIndicator }}
                            styles={customStyles}
                            placeholder=""
                            name="Occupation"
                            value={this.state.selectedOccupation}
                            onChange={this.handleChange.bind(this)}
                            options={occupationsOptions}
                          />
                          <div className="error-text">
                            Please select your occupation
                          </div>
                        </span>
                      </div>
                    </div>
                    <div className="opt-in-container">
                      {this.state.hasOptInError ? (
                        <div className="error-container">
                          <p className="error">
                            To continue, please check the box to indicate you
                            agree to the terms below.
                          </p>
                        </div>
                      ) : null}
                      <label className="opt-in-field">
                        <input
                          type="checkbox"
                          name="AgreeOptIn"
                          value={this.state.AgreeOptIn}
                          onChange={this.onAgreeChange.bind(this)}
                        />
                        <span className="checkmark" />
                        <p>
                          I certify that I am a Healthcare Professional. I
                          understand that any information provided on this
                          website is supplied as a professional courtesy for
                          educational purposes only and that I must continue to
                          form my own conclusions and make my own decisions. I
                          am also aware that Otsuka Pharmaceutical Development
                          &amp; Commercialization, Inc. (OPDC) intends to
                          regularly revise this website and reserves the right
                          to decline to provide these educational resources to
                          individuals who OPDC determines do not meet the
                          aforementioned certification requirements.
                        </p>
                        <p>
                          By providing your name, email address, and other
                          information, you are giving Otsuka Pharmaceutical
                          Development &amp; Commercialization, Inc. and
                          companies working with OPDC permission to send you
                          information from ADPKDsim.org. OPDC will not sell or
                          transfer your name, address, or email address. You
                          agree that, should you use any material provided
                          through ADPKDsim.org you will attribute the source of
                          that information to “ADPKDsim.org sponsored by Otsuka
                          Pharmaceutical Development &amp; Commercialization,
                          Inc.” For additional information, please read our{" "}
                          <a
                            className="text-link"
                            href="https://www.otsuka-us.com/oapi-and-opdc-privacy-policy"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                          . By submitting your information, you agree to the
                          terms provided above, and the terms provided in our{" "}
                          <a
                            className="text-link"
                            href="https://www.otsuka-us.com/terms-and-conditions"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Terms of Use
                          </a>{" "}
                          and our{" "}
                          <a
                            className="text-link"
                            href="https://www.otsuka-us.com/oapi-and-opdc-privacy-policy"
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            Privacy Policy
                          </a>
                          .
                        </p>
                      </label>
                    </div>
                  </div>
                  <div className="button-container">
                    <button
                      className="button cancel sign-up-button"
                      onClick={this.handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      className="button submit sign-up-button"
                      onClick={this.validate}
                    >
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      )
    );
  }
}

var trackedFields = []; // fields which have already been tracked

function trackFormField(field, value) {
  // Notify GTM
  let hasTrackedEvent = trackedFields.indexOf(field) > -1;

  // Only send event data if it has not been tracked yet
  let data = {};
  if (!hasTrackedEvent) {
    data.event = "completed-field.sign-up-form";
    data.fieldName = field;
    data.fieldValue = value;
  }

  // Remember that we've tracked this
  trackedFields.push(field);

  // Set custom dimensions based on form field. These are always updated,
  // even if the event has already been tracked.
  if (field === "occupation") {
    data.occupation = value;
  } else if (field === "state") {
    data.state = value;
  } else if (field === "zip code") {
    data.zipcode = value;
  }

  // Send to data layer
  dataLayer.push(data);
  // Clean values so they are not picked up by future tags
  dataLayer.clean("fieldName", "fieldValue");
}
