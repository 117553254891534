import React, { Component } from "react";
import Image from "../images/effect-age-gender-progression.jpg";

import "../css/AccurateDiagnosisModal.scss";

export default class GenderEffectsModal extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    if (e.target.className === "diagnosis-modal") {
      this.props.handleClose();
    }
  }
  render() {
    const showModal = this.props.showModal;
    const modalContent = (
      <div className="modal-content">
        <span className="close" onClick={this.props.handleClose} />
        <h1>
          Effect of Age and Gender on Disease Progression<sup>1</sup>
        </h1>
        <img
          src={Image}
          alt="Effect of Age and Gender on Disease Progression"
        />

        <p className="glossary">MIC=Mayo Imaging Classification</p>
        <div className="references">
          <strong>References:</strong>
          <p>
            1. Shukoor SS, Vaughan LE, Edwards ME, Lavu S, Kline TL, Senum SR,
            Mkhaimer Y, Zaatari G, Irazabal MV, Neal R, Hogan MC.
            Characteristics of Patients with End-Stage Kidney Disease in ADPKD.
            <i>Kidney international reports</i>. 2021; 6(3):755-67.
          </p>
        </div>
      </div>
    );
    return (
      showModal && (
        <div className="diagnosis-modal" onClick={this.handleClick}>
          {modalContent}
        </div>
      )
    );
  }
}
