import React, { Component } from "react";
import PropTypes from "prop-types";
import NavDropdown from "./NavDropdown";
import Angela from "../../images/angela.png";
import Bill from "../../images/bill.png";
import Denise from "../../images/denise.png";
import Frank from "../../images/frank.png";
import Jeremy from "../../images/jeremy.png";

import "./Header.scss";


class Header extends Component {
  state = { modalIsOpen: false, refsAreOpen: false };

  render() {
    const { patient } = this.props;

    return (
      <div className="header">
        <div className="image-container">
          <img src={patient.profilePic} alt={patient.tag} />
          <NavDropdown {...this.props} />
        </div>
        <div>
          <h1>{patient.tag}</h1>
          <p className="patient-summary">
            {patient.summary}
            <sup>{patient.summaryRefs}</sup>
          </p>
          <ul>
            <li>
              <span className="cKey">Age</span>
              <span className={`large ${this.makeValClass(patient.age)}`}>
                {this.getVal(patient.age)}
              </span>
            </li>
            <li>
              <span className="cKey">Height</span>
              <span className={this.makeValClass(patient.height)}>
                {this.getVal(patient.height)}
              </span>
            </li>
            <li>
              <span className="cKey">Weight</span>
              <span className={this.makeValClass(patient.weight)}>
                {this.getVal(patient.weight)}&nbsp;lbs
              </span>
            </li>
            <li>
              <span className="cKey">Sex</span>
              <span className={this.makeValClass(patient.sex)}>
                {this.getVal(patient.sex)}
              </span>
            </li>
            <li>
              <span className="cKey">Race&nbsp;(AA/O)</span>
              <span className={this.makeValClass(patient.race)}>
                {this.getVal(patient.race)}
              </span>
            </li>
          </ul>
        </div>
      </div>
    );
  }

  makeValClass = entry => {
    if (entry.value === undefined || entry.value === null) {
      return "cVal unavailable";
    } else if (entry.available === false) {
      if (entry.reveal !== undefined && this.props.isRevealed === true) {
        return "cVal";
      } else {
        return "cVal unavailable";
      }
    } else {
      return "cVal";
    }
  };

  getVal = entry => {
    if (this.props.isRevealed === true && entry.reveal !== undefined) {
      return entry.reveal;
    } else {
      return entry.value;
    }
  };
}

Header.propTypes = {
  patient: PropTypes.object.isRequired
};

export default Header;
