import React, { Component, useMemo, useState } from "react";
import "./index.scss";
import Profile from "./Profile";
import data from "../../profileData";
import Tabs from "../Tabs";

const Profiles = (props) => {
  const patientsSubset = data[props.variant];

  const tabs = patientsSubset.map((data) => ({
    tag: data.tag,
    age: data.age.value,
    content: (
      <div className="tab-section-content-static">
        <svg
          style={{
            height: 0,
            display: "block"
          }}
        >
          {/* GRADIENT DEFS USED IN CHARTS */}
          <defs>
            <linearGradient id="gradient1">
              <stop offset="0" stopColor="white" />
              <stop offset="1" stopColor="#f6f6f7" />
            </linearGradient>
            <linearGradient id="gradient2">
              <stop offset="0%" stopColor="white" />
              <stop offset="100%" stopColor="#e4e4e4" />
            </linearGradient>
            <linearGradient id="gradient3">
              <stop offset="0%" stopColor="white" />
              <stop offset="100%" stopColor="#cbcbcb" />
            </linearGradient>
            <linearGradient id="gradient4">
              <stop offset="0%" stopColor="white" />
              <stop offset="100%" stopColor="#b4b4b4" />
            </linearGradient>
            <linearGradient id="gradient5">
              <stop offset="0%" stopColor="white" />
              <stop offset="100%" stopColor="#9e9e9e" />
            </linearGradient>
            <linearGradient id="gradient6">
              <stop offset="0%" stopColor="white" />
              <stop offset="100%" stopColor="#a8aaad" />
            </linearGradient>
          </defs>
        </svg>
        <Profile patient={data} {...props} />
      </div>
    )
  }));

  const patientParam = props.match.params.patient
    ? props.match.params.patient
    : "Bill";

  const currentTabIndex = tabs.findIndex((tab) => tab.tag === patientParam);

  return (
    <div className="tabbed-section-container">
      <div className="breadcrumbs mobile-only">
        <a className="link-text" href="/adpkd-simulator">
          Case Profiles
        </a>
        <p className="page-crumb">
          <span>&gt;</span>
          {tabs[currentTabIndex].tag}
        </p>
      </div>

      <Tabs
        current={patientParam}
        data={patientsSubset.map((patient) => ({
          title: `${patient.tag} (${patient.age.value})`,
          slug: patient.tag,
          href: `/${props.variant}/adpkd-simulator/patient-profiles/${
            patient.tag
          }`
        }))}
      />

      {tabs[currentTabIndex].content}
    </div>
  );
};

export default Profiles;
