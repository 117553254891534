import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import { Motion, spring } from "react-motion";
import "./modal.css";

const body = document.getElementsByTagName("body")[0];
const modalRoot = document.createElement("div");
modalRoot.id = "modal-root";
body.appendChild(modalRoot);

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isInDom: props.isShowing === true
    };
  }

  render() {
    return ReactDOM.createPortal(
      this.state.isInDom && (
        <Motion
          defaultStyle={{ opacity: 0 }}
          style={{
            opacity: this.props.isShowing ? spring(1) : spring(0)
          }}
          onRest={this.handleRest}
        >
          {() => (
            <div
              className="modal-container"
              style={{
                opacity: 1
              }}
            >
              <div
                className="modal-screen"
                onClick={this.handleShadeClick}
                onTouchEnd={this.handleShadeClick}
              />
              <div className="modal-center"> {this.props.children} </div>
            </div>
          )}
        </Motion>
      ),
      modalRoot
    );
  }

  componentWillReceiveProps(nextProps) {
    // console.log(this.state);
    // console.log(nextProps);
    if (this.state.isInDom === false && nextProps.isShowing === true) {
      console.debug("go big!");
      this.setState({
        isInDom: true
      });
    } else {
      // this.state.isin
    }
  }

  handleRest = () => {
    if (this.props.isShowing === false) {
      this.setState({
        isInDom: false
      });
    }
  };

  handleShadeClick = e => {
    if (e.target.className === "modal-screen") {
      this.props.onHide();
    }
  };
}

Modal.propTypes = {
  isShowing: PropTypes.bool.isRequired,
  onHide: PropTypes.func
};

export default Modal;
