import React, { Component } from "react";
import { Page } from "../../components/Page";
import MobileAlgorithmFlowchart from "../../components/MobileAlgorithmFlowchart";
import AlgorithmFlowchartImg from "../../images/algorithm-flowchart.png";

export default class AlgorithmFlowchart extends Component {
  render() {
    return (
      <Page
        title="Prognostic Tools - ERA-EDTA Algorithm"
        className="era-edta-algorithm"
      >
        <h1 className="title">
          European Renal Association − European Dialysis and Transplant
          Association position statement - ERA-EDTA Algorithm
        </h1>
        <p>
          This algorithm hierarchically evaluates risk factors in order of
          reliability to determine likelihood of rapid progression.<sup>1</sup>
        </p>
        <div className="flowchart-container">
          <MobileAlgorithmFlowchart />
          <img src={AlgorithmFlowchartImg} alt="ERA-EDTA Algorithm Flowchart" />
        </div>
        <div className="footer-section">
          <p className="adapted-container">
            Adapted with permission of Oxford University Press, from Gansevoort
            RT, et al. <i>Nephrol Dial Transplant.</i> 2016;31(3):337-348.
          </p>
          <div className="limitation-container">
            <strong>Limitations</strong>
            <ul>
              <li>
                The premise that eGFR indexed for age above high cut off values
                can distinguish rapidly from slowly progressive disease in the
                majority of patients with ADPKD is not accurate.<sup>2</sup>
              </li>
              <li>
                Inclusion by rapid historical eGFR decline may not account for
                high variability of eGFR values &gt; 60 mL/min/1.73 m
                <sup>2</sup> and historical factors affecting historical values
                often unavailable.<sup>2</sup>
              </li>
              <li>
                Serial TKV measurements may not be practical.<sup>2</sup>
              </li>
            </ul>
          </div>
          <p className="glossary">
            ERA-EDTA=European Renal Association – European Dialysis and
            Transplant Association; eGFR=estimated glomerular filtration rate;
            CKD=chronic kidney disease; ADPKD=autosomal dominant polycystic
            kidney disease; htTKV=height-adjusted total kidney volume;
            MRI=magnetic resonance imaging; CT=computed tomography;
            US=ultrasound; PKD=polycystic kidney disease; ESKD=end-stage kidney
            disease.
          </p>
          <div className="references">
            <strong>References:</strong>
            <p>
              1. Gansevoort RT, et al. Recommendations for the use of tolvaptan
              in autosomal dominant polycystic kidney disease: a position
              statement on behalf of the ERA-EDTA Working Groups on Inherited
              Kidney Disorders and European Renal Best Practice. <i>Nephrol Dial
              Transplant</i>. 2016;31(3):337-48.
            </p>
            <p>
              2. Chebib FT, Perrone RD, Chapman AB, Dahl NK, Harris PC, Mrug M,
              Mustafa RA, Rastogi A, Watnick T, Alan SL, Torres VE. A practical
              guide for treatment of rapidly progressive ADPKD with tolvaptan.
              <i>Journal of the American Society of Nephrology</i>. 2018;
              29(10):2458-70
            </p>
          </div>
        </div>
      </Page>
    );
  }
}
