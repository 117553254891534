import React, { Component } from "react";
import PageNotFound from "../404";

import "../../css/ToolsHomepage.scss";

import tools from "./Tools";
import Tabs from "../../components/Tabs";

// const RootPath = "../";

class ToolLoader extends Component {
  constructor(props) {
    super(props);

    const { variant } = this.props;

    const PrognosticToolsTabsIndex = tools[variant].reduce((tabs, tool) => {
      tabs.push(tool.param);
      return tabs;
    }, []);

    this.state = {
      tabIndex: this.props.match.params.tool
        ? PrognosticToolsTabsIndex.indexOf(this.props.match.params.tool)
        : 0
    };
    // this.handeClick = this.handeClick.bind(this);
  }
  // handeClick(tabIndex) {
  //   this.setState({ tabIndex });
  // }
  // shouldComponentUpdate(nextProps, nextState) {
  //   if (
  //     PrognosticToolsTabsIndex.indexOf(nextProps.match.params.tool) !==
  //     nextState
  //   ) {
  //     nextState.tabIndex = PrognosticToolsTabsIndex.indexOf(
  //       nextProps.match.params.tool
  //     );
  //     window.scrollTo(0, 0);
  //     return true;
  //   }
  //   return false;
  // }
  render() {
    const { variant } = this.props;

    const PrognosticTools = tools[variant].reduce((components, tool) => {
      const Tool = tool.component;
      const toolProps = tool.param === "tkv-calculator" ? this.props : {};
      components[tool.param] = <Tool {...toolProps} />;
      return components;
    }, {});

    const PrognosticToolsCrumb = tools[variant].reduce((crumbs, tool) => {
      crumbs[tool.param] = tool.name;
      return crumbs;
    }, {});

    // const currentElement = PrognosticTools[this.state.tabIndex];
    // const rootedDestinations = PrognosticToolsTabsIndex.map((el) => {
    //   if (this.props.match.params.calc === undefined) {
    //     return el;
    //   }
    //   return RootPath + el;
    // });
    const currentCrumb = this.props.match.params.tool
      ? this.props.match.params.tool
      : this.props.match.params.calc;
    const currentTool = PrognosticTools[currentCrumb];
    return currentTool ? (
      <div className="prognostic-tools-indexer">
        <div className="breadcrumbs mobile-only">
          <a className="link-text" href="/prognostic-tools">
            Prognostic Tools
          </a>
          <p className="page-crumb">
            <span>&gt;</span>
            {PrognosticToolsCrumb[currentCrumb]}
          </p>
        </div>
        <div className="tabbed-section-container">
          <Tabs
            current={currentCrumb}
            data={tools[variant].map((tool) => ({
              title: tool.name,
              slug: tool.param,
              href: tool.url + "/" + tool.param
            }))}
          />
        </div>
        {currentTool}
      </div>
    ) : (
      <PageNotFound />
    );
  }
}

export default ToolLoader;
