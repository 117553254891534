import React, { Component } from "react";
import { Page } from "../../components/Page";

import "../../css/ToolsHomepage.scss";
import ToolsMenu from "./ToolsMenu";

import tools from "./Tools";

export default class PrognosticToolsHomepage extends Component {
  render() {
    return (
      <Page
        title="ADPKD Prognostic Tools to Assess Progression Rate"
        className="prognostic-tools"
      >
        <p className="page-crumb">Prognostic Tools</p>
        <h1 className="title">Clinical Risk Factors and Prognostic Tools</h1>
        <p className="centered">
          There are a number of clinical risk factors associated with rapid
          disease progression in ADPKD. If one or more of these risk factors are
          present, different prognostic tools can be used to confirm the risk of
          rapid progression. Some of these prognostic tools can also assess how
          rapidly the disease may progress and even estimate time to ESKD.
          <sup>1-3</sup>
        </p>
        <p className="section-subtitle">
          For more details on each of the risk factors and prognostic tools,
          click on the icon below.
        </p>
        <ToolsMenu rows={3} columns={2} tools={tools.expert} />
        <div className="footer-section">
          <div className="glossary">
            <p><dfn id="def-ADPKD">ADPKD</dfn> = autosomal dominant polycystic kidney disease</p>
            <p><dfn id="def-ESKD">ESKD</dfn> = end-stage kidney disease</p>
            <p><dfn id="def-ADPKD">MRI</dfn> = magnetic resonance imaging</p>
            <p><dfn id="def-CT">CT</dfn> = computed tomography</p>
            <p><dfn id="def-ERA-EDTA">ERA-EDTA</dfn> = European Renal Association – European Dialysis and Transplant Association</p>
          </div>
          <div className="references">
            <strong>References:</strong>
            <p>
              1. Schrier RW, et al. Predictors of autosomal dominant polycystic
              kidney disease progression. <i>J Am Soc Nephrol</i>.
              2014;25:2399-2418.
            </p>
            <p>
              2. Gansevoort RT, et al. Recommendations for the use of tolvaptan
              in autosomal dominant polycystic kidney disease: a position
              statement on behalf of the ERA-EDTA Working Groups on Inherited
              Kidney Disorders and European Renal Best Practice.  <i>Nephrol Dial
                Transplant</i>. 2016;31(3):337-48.
            </p>
            <p>
              3. Bhutani H, et al. A comparison of ultrasound and magnetic
              resonance imaging shows that kidney length predicts chronic kidney
              disease in autosomal dominant polycystic kidney disease.{" "}
              <i>Kidney In</i>. 2015;88:146-151.
            </p>
          </div>
        </div>
      </Page>
    );
  }
}
