import React, { Component } from "react";
import { Link } from "react-router-dom";
import HomePageKidney from "../images/left-kidney.png";
// import KidneyIcon from "../images/kidney-icon";
import PrognosticToolIcon from "../images/icon-prognostic-tools";
import ADPKDSimToolIcon from "../images/icon-adpkd-sim";
import { Page } from "../components/Page";

import "../css/HomePage.scss";

export default class ExpertHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Page
        title="Autosomal Dominant Polycystic Kidney Disease (ADPKD) Simulator"
        className="adpkd-homepage"
      >
        <div className="flex">
          <img className="main-image" src={HomePageKidney} alt="Kidney" />
          <div>
            <h1 className="title">
              <strong>ADPKDsim</strong> is an educational resource with
              information on assessing the rate of ADPKD progression
            </h1>
            <p className="sub-header">
              In patients with autosomal dominant polycystic kidney disease
              (ADPKD), assessing the{" "}
              <strong>rate of disease progression</strong> is an important part
              of disease management.
            </p>
            <div className="card-container">
              <Link className="link-reset" to="/expert/prognostic-tools">
                <div className="card">
                  <PrognosticToolIcon />
                  <div>
                    <h2>Prognostic tools</h2>
                    <p>
                      See how different methods evaluate risk factors to <strong>assess the rate of disease progression</strong> in ADPKD.
                    </p>
                    <span className="link-text">
                      review the tools
                      <span className="arrow" />
                    </span>
                  </div>
                </div>
              </Link>
              <Link className="link-reset" to="/expert/adpkd-simulator">
                <div className="card">
                  <ADPKDSimToolIcon />
                  <div>
                    <h2>ADPKD Simulator</h2>
                    <p>
                      Select from hypothetical patient profiles with <strong>simulated disease progression</strong> to see utilization of prognostic tools in ADPKD.
                    </p>
                    <span className="link-text">
                      explore the simulator
                      <span className="arrow" />
                    </span>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>


      </Page>
    );
  }
}
