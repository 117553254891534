import React, { Component } from "react";
import dataLayer, { dataLayerIsPresent } from "../../helpers/dataLayer";

export default class videoModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sentToDataLayer: []
    };

    this.handleClick = this.handleClick.bind(this);
    this.handlePlay = this.handlePlay.bind(this);
    this.handleTimeUpdate = this.handleTimeUpdate.bind(this);
    this.pushToDataLayer = this.pushToDataLayer.bind(this);
  }

  handleClick(e) {
    if (e.target.className === "video-modal") {
      this.props.onClose();
    }
  }

  handlePlay(e) {
    this.pushToDataLayer("Played");
  }

  handleTimeUpdate(e) {
    var time = Math.floor(e.target.currentTime);

    // Only track every 15s, do not track time 0
    if (time === 0 || time % 15 !== 0) {
      return;
    }

    var min = Math.floor(time / 60);
    if (min < 9) {
      min = "0" + min;
    }

    var sec = time % 60;
    if (sec < 9) {
      sec = "0" + sec;
    }

    this.pushToDataLayer("Watched " + min + "m" + sec + "s");
  }

  pushToDataLayer(action) {
    // If there is no data layer on the page exit immediately
    if (!dataLayerIsPresent()) {
      return;
    }

    // If the event has been sent to the data layer already exit immediately,
    // this prevents double-tracking
    if (this.state.sentToDataLayer.indexOf(action) > -1) {
      return;
    }

    // Send to data layer
    dataLayer.push({
      event: "video.event",
      action: action,
      video: this.props.video.title
    });
    // Clean values so they are not picked up by future tags
    dataLayer.clean("action", "video");

    // Record that we have sent this
    this.setState({
      sentToDataLayer: this.state.sentToDataLayer.concat([action])
    });
  }

  render() {
    const { isShowing, video, onClose } = this.props;
    const modalContent = (
      <div className="modal-content">
        <span className="close" onClick={onClose} onTouchEnd={onClose} />
        <video
          controls
          autoPlay
          disablepictureinpicture="true"
          controlsList="nodownload"
          title={video.title}
          onPlay={this.handlePlay}
          onTimeUpdate={this.handleTimeUpdate}
        >
          <source
            src={`${video.baseURL}-hls/index.m3u8`}
            type="application/x-mpegURL"
          />
          <source
            src={`${process.env.REACT_APP_PUBLIC_URL + video.baseURL}.mp4`}
            type="video/mp4"
          />
          <source
            src={`${process.env.REACT_APP_PUBLIC_URL + video.baseURL}.webm`}
            type="video/webm"
          />
          Your browser does not support the video tag.
        </video>
      </div>
    );
    return (
      isShowing && (
        <div className="video-modal" onClick={this.handleClick}>
          {modalContent}
        </div>
      )
    );
  }
}
