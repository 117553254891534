import React, { Component, Fragment } from "react";
// import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import ProfileTour from "../../ProfileTour";
import ModalCharts from "./ModalCharts";

import Chart from "./Chart";
import "./index.scss";

// Add an element to the DOM to hold the modal shade.
// Shade is added via a ReactDOM Portal and animated with Motion
// const body = document.getElementsByTagName("body")[0];
// const modalRoot = document.createElement("div");
// modalRoot.id = "modal-root";
// body.appendChild(modalRoot);

export const graphWidth = 300;
export const graphHeight = 246;
export const graphScaleFactor = 1.65;

class ZoomingCharts extends Component {
  state = {
    isZoomed: false
  };

  render() {
    return (
      <Fragment>
        <div
          className="zooming-charts-container"
          ref={(r) => (this.container = r)}
        >
          <div style={{ width: "60px", height: "100%" }}>
            {this.props.variant === "expert" ? (
              <ProfileTour
                variant={this.props.variant}
                stepNumber={2}
                name={this.props.patientData.tag}
                direction={"right"}
                currentTourStepOpen={this.props.currentTourStepOpen}
                onTourStep={this.props.onTourStep}
                onTourClose={this.props.onTourClose}
              />
            ) : null}
          </div>
          <Chart
            seek={this.props.seek}
            index={this.props.index}
            subtitle={
              <span>
                <strong>E</strong>stimated <strong>G</strong>lomerular{" "}
                <strong>F</strong>iltration <strong>R</strong>ate
              </span>
            }
            isTkv={false}
            currentValue={this.props.egfrVal}
            graphWidth={graphWidth}
            graphHeight={graphHeight}
            scaleFactor={1}
            patientData={this.props.patientData}
            normalEgfr={this.props.normalEgfr}
            onClick={this.handleZoom}
            isRevealed={this.props.isRevealed}
            expanderVisible={true}
          />
          <Chart
            seek={this.props.seek}
            index={this.props.index}
            subtitle={
              <span>
                <strong>H</strong>eigh<strong>t</strong> Adjusted{" "}
                <strong>T</strong>otal <strong>K</strong>idney{" "}
                <strong>V</strong>olume
              </span>
            }
            isTkv={true}
            currentValue={this.props.tkvVal}
            graphWidth={graphWidth}
            graphHeight={graphHeight}
            scaleFactor={1}
            patientData={this.props.patientData}
            normalEgfr={this.props.normalEgfr}
            onClick={this.handleZoom}
            isRevealed={this.props.isRevealed}
            expanderVisible={true}
          />

          {/** Tour Step 2 */}
        </div>
        <ModalCharts
          isZoomed={this.state.isZoomed}
          unZoom={this.handleUnZoom}
          {...this.props}
        />
      </Fragment>
    );
  }

  handleZoom = () => {
    console.debug("go zoom");
    this.setState({
      isZoomed: true
    });
  };

  handleUnZoom = () => {
    console.debug("handle unzoom");
    this.setState({
      isZoomed: false
    });
  };
}

ZoomingCharts.propTypes = {
  seek: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isRevealed: PropTypes.bool.isRequired,
  tkvVal: PropTypes.number.isRequired,
  egfrVal: PropTypes.number.isRequired,
  patientData: PropTypes.object.isRequired,
  normalEgfr: PropTypes.array.isRequired,
  currentTourStepOpen: PropTypes.number.isRequired,
  onTourStep: PropTypes.func.isRequired,
  onTourClose: PropTypes.func.isRequired
};

export default ZoomingCharts;
