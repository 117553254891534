import PropTypes from "prop-types";
import React, { Component } from "react";
import Draggable from "react-draggable";
import ProfileTour from "../../ProfileTour";
import Background from "./Background";
import Button from "./Button";
import Labels from "./Labels";
import "./index.scss";

const buttonWidth = 50;

class Slider extends Component {
  didDragDuringDown_ = false;

  state = {
    buttonX: 0,
    fullWidth: 0,
    innerWidth: 0,
    dragWidth: 0
  };

  render() {
    const ProfileTourOnSlider = () =>
      (this.props.patient === "Denise" || this.props.patient === "Jeremy") &&
      this.props.isRevealed ? (
        <ProfileTour
          variant={this.props.variant}
          stepNumber={6}
          name={this.props.patient}
          direction={"vert"}
          currentTourStepOpen={this.props.currentTourStepOpen}
          onTourStep={this.props.onTourStep}
          onTourClose={this.props.onTourClose}
          scrollSelector=".kidney-image-container"
        />
      ) : this.props.patient === "Bill" || this.props.patient === "Frank" ? (
        <ProfileTour
          variant={this.props.variant}
          stepNumber={this.props.patient === "Bill" ? 7 : 5}
          name={this.props.patient}
          direction={"vert"}
          currentTourStepOpen={this.props.currentTourStepOpen}
          onTourStep={this.props.onTourStep}
          onTourClose={this.props.onTourClose}
          scrollSelector=".kidney-image-container"
        />
      ) : this.props.patient === "Angela" ? (
        <ProfileTour
          variant={this.props.variant}
          stepNumber={4}
          name={this.props.patient}
          direction={"vert"}
          currentTourStepOpen={this.props.currentTourStepOpen}
          onTourStep={this.props.onTourStep}
          onTourClose={this.props.onTourClose}
          scrollSelector=".kidney-image-container"
        />
      ) : null;
    return (
      <div class="flip-deck-container">
        <div
          className="container"
          onTouchStart={this.handleTouchDown}
          onTouchEnd={this.handleContainerClick}
          onMouseDown={this.handleTouchDown}
          onMouseUp={this.handleContainerClick}
          ref={(r) => (this.container = r)}
        >
          <Background
            segments={this.props.segments}
            seek={this.props.seek}
            buttonWidth={buttonWidth}
            width={this.state.innerWidth}
            isRevealed={this.props.isRevealed}
            hasPartialData={this.props.hasPartialData}
          />
          <Labels
            segments={this.props.segments}
            width={this.state.innerWidth}
            offset={buttonWidth / 2}
            isRevealed={this.props.isRevealed}
            hasPartialData={this.props.hasPartialData}
          />
          <div
            className="drag-container"
            style={{
              width: this.state.dragWidth
            }}
          >
            <Draggable
              axis="x"
              bounds="parent"
              scale={this.props.zoomFactor}
              position={{ x: this.state.buttonX, y: 0 }}
              onStart={this.handleDragStart}
              onStop={this.handleDragStop}
              onDrag={this.handleDrag}
            >
              <div
                style={{
                  width: buttonWidth,
                  position: "absolute",
                  left: 7,
                  top: 0
                }}
              >
                <Button
                  patient={this.props.patient}
                  isRevealed={this.props.isRevealed}
                  currentTourStepOpen={this.props.currentTourStepOpen}
                  onTourStep={this.props.onTourStep}
                  onTourClose={this.props.onTourClose}
                />
              </div>
            </Draggable>
          </div>
          {/** Tour Step End */}
          <p className="slider-label">Years</p>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "start",
            justifyContent: "start",
            paddingLeft: "20px",
            width: "100%",
            height: "100%"
          }}
        >
          {this.props.variant === "expert" ? (
            <ProfileTourOnSlider variant={this.props.variant} />
          ) : null}
        </div>
      </div>
    );
  }

  componentDidMount() {
    const { width } = this.container.getBoundingClientRect();

    this.setState({
      fullWidth: width,
      dragWidth: width,
      innerWidth: width - buttonWidth,
      buttonX: this.props.seek * (width - buttonWidth)
    });

    // Add resize event listener
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Remove resize event listener
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = () => {
    const { width } = this.container.getBoundingClientRect();
    this.setState({
      fullWidth: width,
      dragWidth: width,
      innerWidth: width - buttonWidth,
      // Update the buttonX position based on the new innerWidth and current seek value
      buttonX: this.props.seek * (width - buttonWidth)
    });
  };

  componentWillReceiveProps(nextProps) {
    const { seek, segments, isRevealed, hasPartialData } = nextProps;
    var buttonX = this.state.buttonX;
    var dragWidth = this.state.fullWidth;

    if (!isNaN(seek) && this.props.seek !== seek) {
      buttonX = seek * this.state.innerWidth;
    }

    if (!isRevealed || hasPartialData) {
      var index = 0;
      for (var i = 0; i < segments.length; i++) {
        const seg = segments[i];
        if (seg.history !== true) {
          index = i;
          break;
        }
      }

      const percent = index / (segments.length - 1);
      dragWidth = dragWidth * percent + 43;
    }

    this.setState({ buttonX, dragWidth });
  }

  handleTouchDown = (evt) => {
    this.didDragDuringDown_ = false;
  };

  handleContainerClick = (evt) => {
    if (this.didDragDuringDown_ === false) {
      const eventType = evt.type;
      const eventNumerator =
        eventType === "touchend"
          ? evt.nativeEvent.changedTouches[0].clientX - 20
          : evt.clientX - 20;
      const r = eventNumerator / this.state.innerWidth;
      this.sendRatio(r / this.props.zoomFactor, true);
    }
  };

  handleDrag = (mouseEvent, drag) => {
    this.didDragDuringDown_ = true;
    const r = drag.x / this.state.innerWidth;
    this.sendRatio(r);
  };

  handleDragStart = (mouseEvent, drag) => {
    this.props.onStart();
  };

  handleDragStop = (mouseEvent, drag) => {
    const r = drag.x / this.state.innerWidth;
    this.sendRatio(r, true);
  };

  sendRatio = (ratio, isEnd = false) => {
    const { segments, isRevealed, hasPartialData } = this.props;
    const scaledRatio = ratio / (isEnd ? 1 : this.props.zoomFactor);
    const clampedRatio = Math.min(Math.max(scaledRatio, 0), 1);
    const numSegments = segments.length - 1;
    const tickFloat = Math.min(Math.max(ratio * numSegments, 0), numSegments);

    const nearestTick = isEnd ? Math.round(tickFloat) : Math.floor(tickFloat);
    const finalR = isEnd ? nearestTick / numSegments : clampedRatio;

    // console.log("seek", finalR, "index", nearestTick);

    if (isEnd) {
      const segment = segments[nearestTick];
      // console.log(ratio);
      if (
        segment.history === true ||
        segment.baseline === true ||
        (isRevealed && !hasPartialData)
      ) {
        this.props.onEnd({ seek: finalR, index: nearestTick });
      }
    } else {
      this.props.onSlide({ seek: finalR, index: nearestTick });
    }
  };
}

Slider.propTypes = {
  onStart: PropTypes.func.isRequired,
  onEnd: PropTypes.func.isRequired,
  onSlide: PropTypes.func.isRequired,
  seek: PropTypes.number.isRequired,
  segments: PropTypes.array.isRequired,
  zoomFactor: PropTypes.number.isRequired,
  isRevealed: PropTypes.bool.isRequired,
  hasPartialData: PropTypes.bool.isRequired,
  patient: PropTypes.string.isRequired,
  currentTourStepOpen: PropTypes.number.isRequired,
  onTourStep: PropTypes.func.isRequired,
  onTourClose: PropTypes.func.isRequired
};

export default Slider;
