import React, { Component } from "react";
import { BrowserRouter, HashRouter, Route, Switch } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import PageNotFound from "./pages/404";
import HomePage from "./pages/Index";
import ResourcesHomepage from "./pages/resources/Index";
import ADPKDSimulator from "./pages/simulator/ADPKDSimulator";
import SimulatorHomepage from "./pages/simulator/Index";
import PrognosticToolsHomepage from "./pages/tools/Index";
import PatientInformationHomepage from "./pages/tools/patient/Index";
import PrognosticToolsLoader from "./pages/tools/ToolLoader";
import Disclaimer from "./components/Disclaimer";

import "./css/App.scss";
import { getVariant, isMobileApp } from "./helpers/compat";
import ExpertHomePage from "./pages/Expert";
import PatientHomePage from "./pages/Patient";

class Router extends Component {
  render() {
    const { children, ...rest } = this.props;
    const RouterComponent = isMobileApp() ? HashRouter : BrowserRouter;
    return <RouterComponent {...rest}>{children}</RouterComponent>;
  }
}

export default class App extends Component {
  constructor(props) {
    super(props);
    this.state = { variant: "" };
  }
  componentDidMount() {
    if (
      !document.cookie
        .split(";")
        .filter((item) => item.trim().startsWith("signup=")).length
    ) {
      const pageViews = localStorage.getItem("pageViews")
        ? Number(localStorage.getItem("pageViews")) + 1
        : 1;
      localStorage.setItem("pageViews", pageViews);
    }
  }
  render() {
    return (
      <Router basename={process.env.REACT_APP_PUBLIC_URL} forceRefresh={true}>
        <div id="adpkd-app">
          <Header {...this.props}/>
          <Switch>
            <Route exact path="/" component={HomePage} />
            <Route exact path="/expert" component={ExpertHomePage} />
            <Route exact path="/patient" component={PatientHomePage} />
            <Route
              exact
              path="/expert/prognostic-tools"
              component={PrognosticToolsHomepage}
            />
            <Route
              exact
              path="/patient/information"
              component={PatientInformationHomepage}
            />
            <Route
              exact
              path="/expert/prognostic-tools/:tool"
              render={(props) => (
                <PrognosticToolsLoader {...props} variant={"expert"} />
              )}
            />
            <Route
              exact
              path="/patient/information/:tool"
              render={(props) => (
                <PrognosticToolsLoader {...props} variant={"patient"} />
              )}
            />
            <Route
              path="/expert/prognostic-tools/:calc/:patient"
              render={(props) => (
                <PrognosticToolsLoader {...props} variant={"expert"} />
              )}
            />
            <Route
              path="/patient/prognostic-tools/:calc/:patient"
              render={(props) => (
                <PrognosticToolsLoader {...props} variant={"patient"} />
              )}
            />
            <Route
              exact
              path="/expert/adpkd-simulator"
              render={(props) => (
                <SimulatorHomepage {...props} variant={"expert"} />
              )}
            />
            <Route
              exact
              path="/patient/adpkd-simulator"
              render={(props) => (
                <SimulatorHomepage {...props} variant={"patient"} />
              )}
            />
            <Route
              path="/expert/adpkd-simulator/patient-profiles/:patient?"
              render={(props) => (
                <ADPKDSimulator {...props} variant={"expert"} />
              )}
            />
            <Route
              path="/patient/adpkd-simulator/patient-profiles/:patient?"
              render={(props) => (
                <ADPKDSimulator {...props} variant={"patient"} />
              )}
            />
            <Route
              exact
              path="/expert/resources"
              component={ResourcesHomepage}
            />
            <Route
              exact
              path="/expert/resources/:page?"
              component={ResourcesHomepage}
            />
            <Route component={PageNotFound} />
          </Switch>
          <Disclaimer />
          <Footer />
        </div>
      </Router>
    );
  }
}
