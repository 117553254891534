import React from "react";
import { Link } from "react-router-dom";
import JenniferrofilePic from "./images/Jennifer.png";
import MarcusProfilePic from "./images/Marcus.png";
import AngelaProfilePic from "./images/angela.png";
import BillProfilePic from "./images/bill.png";
import DeniseProfilePic from "./images/denise.png";
import FrankProfilePic from "./images/frank.png";
import JeremyProfilePic from "./images/jeremy.png";

export default {
  patient: [
    {
      audiences: ["patient", "expert"],
      name: "Marcus",
      tag: "Marcus",
      profileIntro:
        "Click on the flags below to see the testing used to confirm risk of rapid disease progression as well as Marcus’ individual risk",
      tourData: [
        <div>
          <h4>eGFR</h4>
          <p>
            Baseline eGFR of 97 (mL/min/1.73m<sup>2</sup>) places Marcus at CKD
            Stage 1.
          </p>
        </div>,
        <figure class="tour-figures">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/images/Marcus-Blood-100.jpg`}
          />
          {/* <img src='https://random42.com/wp-content/uploads/2021/04/random42-scientific-communication-medical-animation.jpg' /> */}
        </figure>,
        <figure class="tour-figures">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/images/Marcus-pain-100.jpg`}
          />
          {/* <img src='https://random42.com/wp-content/uploads/2021/04/random42-scientific-communication-medical-animation.jpg' /> */}
        </figure>
      ],
      profilePic: MarcusProfilePic,
      riskDescription: (
        <>
          <p>Being male</p>
          <p>Hypertension before age 35</p>
          <p>Urologic events before age 35</p>
          <p>
            Truncating <i>PKD1</i> mutation
          </p>
          <p>TKV greater than expected for age</p>
        </>
      ),
      references: [
        <p>
          Schrier RW, et al. Predictors of autosomal dominant polycystic kidney
          disease progression.&nbsp;<em>J Am Soc Nephrol</em>.
          2014;25:2399-2418.
        </p>,
        <p>
          Gansevoort RT, et al. Recommendations for the use of tolvaptan in
          autosomal dominant polycystic kidney disease: a position statement on
          behalf of the ERA-EDTA Working Groups on Inherited Kidney Disorders
          and European Renal Best Practice.&nbsp;
          <em>Nephrol Dial Transplant</em>. 2016;31(3):337-48.
        </p>,
        <p>
          Delgado, C. <em>et al.</em> (2022) &ldquo;A unifying approach for GFR
          estimation: Recommendations of the NKF-ASN task force on reassessing
          the inclusion of race in diagnosing kidney disease,&rdquo;{" "}
          <em>American Journal of Kidney Diseases</em>, 79(2). Available at:{" "}
          <a href="https://doi.org/10.1053/j.ajkd.2021.08.003">
            https://doi.org/10.1053/j.ajkd.2021.08.003
          </a>
          .
        </p>,
        <p>
          Irazabal MV, et al. Imaging classification of autosomal dominant
          polycystic kidney disease: a simple model for selecting patients for
          clinical trials. J Am Soc Nephrol. 2015;26:160-172.
        </p>,
        <p>
          Imaging classification of ADPKD: a simple model for selecting patients
          for clinical trials.{" "}
          <u>
            <a href="https://protect-eu.mimecast.com/s/twPTCB11EtQG9hNbBYg?domain=mayo.edu">
              http://www.mayo.edu/research/documents/pkd-center-adpkd-classification/doc-20094754
            </a>
          </u>
          . Accessed 1/11/2023.
        </p>
      ],
      summary: (
        <div>
          <p>
            Marcus is an active 35-year-old and has been diagnosed with
            Autosomal Dominant Polycystic Kidney Disease (ADPKD). He has high
            blood pressure, but it is well-controlled with medication. PKD has
            affected Marcus' daily life as he has suffered from lower back and
            side pain for the past 10 years. Marcus has also had a cyst
            infection and a few episodes of blood in the urine. Marcus does not
            have a family history of kidney disease and he is very anxious about
            what his future holds.
          </p>
          <p>
            Let’s take a closer look at some tests Marcus' kidney doctor has
            ordered to better understand how quickly Marcus might progress to
            kidney failure<sup>1,2</sup>.
          </p>
        </div>
      ),
      summaryRefs: "",
      acronymDefinitions:
        "ADPKD=autosomal dominant polycystic kidney disease; PKD=polycystic kidney disease; TKV=total kidney volume; AA=African American; O=other; eGFR=estimated glomerular filtration rate; ESKD=end stage kidney disease; htTKV=height-adjusted TKV; MRI=magnetic resonance imaging; CT=computed tomography; CKD=chronic kidney disease.",
      hasReveal: false,
      hasPartialData: false,

      age: {
        value: 35,
        available: true
      },
      sex: {
        value: "M",
        available: true
      },
      height: {
        value: "5'11\"",
        available: true
      },
      weight: {
        value: "171",
        available: true
      },
      race: {
        value: "AA",
        available: true
      },
      mutations: {
        value: "Truncating PKD1",
        available: true
      },
      serumCreatine: {
        value: "1.15",
        unit: "mg/dL",
        standardRange: "0.50 - 1.20",
        available: true
      },
      kidneyLength: {
        value: "Not obtained",
        available: false
      },
      bloodPressure: {
        value: "130/80",
        unit: "mm/Hg",
        comment: "High, Controlled with Medication",
        standardRange: (
          <span>
            <span style={{ fontFamily: "aerial" }}>{" < "}</span>
            <span>60</span>
          </span>
        )
      },
      //
      labTests: {
        "Serum Creatinine": {
          value: "1.15",
          unit: "mg/dL",
          standardRange: "0.50 - 1.20",
          comment: "",
          callout: { tag: "none" }
        },
        eGFR: {
          value: "97",
          unit: "mL/min/1.73m²",
          standardRange: (
            <span>
              <span style={{ fontFamily: "aerial" }}>{" ≥ "}</span>
              <span>60</span>
            </span>
          ),
          comment: "",
          callout: {
            tag: "some",
            value: { stepNumber: 1 }
          }
        },
        "Blood Pressure": {
          value: "130/80",
          unit: "mmHg",
          standardRange: (
            <span>
              <span style={{ fontFamily: "aerial" }}>{" < "}</span>
              <span>120/80</span>
            </span>
          ),
          comment: "High, Controlled with Medication",
          callout: { tag: "none" }
        },
        "Blood in Urine": {
          value: "Yes",
          unit: "",
          standardRange: "",
          comment: "",
          callout: { tag: "some", value: { stepNumber: 2 } }
        },
        Pain: {
          value: "Yes",
          unit: "",
          standardRange: "",
          comment: "",
          callout: { tag: "some", value: { stepNumber: 3 } }
        },
        "Family History": {
          value: "No",
          unit: "",
          standardRange: "",
          comment: "",
          callout: { tag: "none" }
        }
      },
      imagingTests: {
        "MRI Ordered?": {
          value: "Yes",
          unit: "",
          standardRange: "",
          comment: "",
          callout: { tag: "none" }
        },
        "Kidney Size": {
          value: "1343",
          unit: "mL/m",
          standardRange: "150-250",
          comment: "",
          callout: { tag: "none" }
        }
      },
      pain: {
        value: "Yes"
      },
      familyHistory: {
        value: "No"
      },
      MRIOrdered: {
        value: "Yes",
        standardRange: "90-190"
      },
      kidneySize: {
        value: 1342,
        unit: "mL/m",
        standardRange: ""
      },
      eGFR: {
        value: "97",
        unit: "mL/min/1.73m²",
        standardRange: (
          <span>
            <span style={{ fontFamily: "aerial" }}>{" ≥ "}</span>
            <span>60</span>
          </span>
        ),
        available: true
      },
      tKV: {
        value: 1343,
        available: true
      },
      proPKDScore: {
        value: "9 -  HIGH risk of progression to ESKD",
        available: true
      },
      mayoImagingClassification: {
        available: true,
        value: {
          mayoImagingClassificationTitle: {
            value: "Class 1E: High Risk for eGFR decline",
            available: true
          },
          mayoImagingClassificationDescription: {
            value: ">6% estimated yearly percentage increase in kidney growth",
            available: true
          }
        }
      },
      estimatedAgeAtESRD: {
        available: true,
        value: "51"
      },
      history: {
        hyperBefore35: {
          value: "Yes",
          available: true
        },
        urologicalBefore35: {
          value: "Yes",
          available: true
        },
        familyESRD: {
          value: "No",
          available: true
        }
      },
      kidneyDimensions: {
        left: {
          sagittal: "TBD",
          coronal: "188 mm",
          transverse: { width: "105 mm", depth: "110 mm" }
        },
        right: {
          sagittal: "TBD",
          coronal: "203 mm",
          transverse: { width: "107 mm", depth: "113 mm" }
        }
      },
      charts: {
        tkv: {
          yAxis: {
            ticks: [0, 500, 1000, 1500, 2000, 2500, 3000, 3500, 4000, 4500],
            min: 0,
            max: 4218.1
          },
          data: [
            {
              year: 20,
              level: 427.4,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },

            {
              year: 21,
              level: 437.4,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 22,
              level: 477.6,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 23,
              level: 520.7,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 24,
              level: 566.7,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 25,
              level: 616.0,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 26,
              level: 668.7,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 27,
              level: 725.1,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 28,
              level: 785.4,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 29,
              level: 849.9,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 30,
              level: 918.8,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 31,
              level: 992.7,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 32,
              level: 1071.6,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 33,
              level: 1156.0,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 34,
              level: 1246.4,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 35,
              level: 1343.0,
              baseline: true,
              ignorePoint: false,
              ignoreTick: false,
              history: false
            },
            {
              year: 36,
              level: 1446.4,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 37,
              level: 1556.9,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 38,
              level: 1675.2,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 39,
              level: 1801.7,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 40,
              level: 1937.0,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 41,
              level: 2081.8,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 42,
              level: 2236.6,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 43,
              level: 2402.3,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 44,
              level: 2579.5,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 45,
              level: 2769.0,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 46,
              level: 2971.7,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 47,
              level: 3188.6,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 48,
              level: 3420.6,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 49,
              level: 3668.7,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 50,
              level: 3934.2,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 51,
              level: 4218.1,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            }
          ]
        },
        egfr: {
          yAxis: {
            ticks: [0, 50, 100, 150],
            min: 0,
            max: 110
          },
          data: [
            {
              year: 20,
              level: 108.2,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 21,
              level: 107.95,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 22,
              level: 107.67,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 23,
              level: 107.34,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 24,
              level: 106.96,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 25,
              level: 106.54,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 26,
              level: 106.05,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 27,
              level: 105.49,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 28,
              level: 104.85,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 29,
              level: 104.12,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 30,
              level: 103.28,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 31,
              level: 102.33,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 32,
              level: 101.24,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 33,
              level: 100.0,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 34,
              level: 98.58,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 35,
              level: 97,
              ignorePoint: false,
              ignoreTick: false,
              history: false,
              baseline: true
            },
            {
              year: 36,
              level: 95.09,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 37,
              level: 92.97,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 38,
              level: 90.55,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 39,
              level: 87.78,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 40,
              level: 84.61,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 41,
              level: 80.99,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 42,
              level: 76.86,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 43,
              level: 72.14,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 44,
              level: 66.74,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 45,
              level: 60.57,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 46,
              level: 53.52,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 47,
              level: 45.47,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 48,
              level: 36.27,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 49,
              level: 25.75,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 50,
              level: 15.73,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 51,
              level: 4.2,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            }
          ]
        }
      }
    },
    {
      audiences: ["patient", "expert"],
      name: "Jennifer",
      tag: "Jennifer",
      profilePic: JenniferrofilePic,
      tourData: [
        <div>
          <h4>eGFR</h4>
          <p>
            Baseline eGFR of 88 (mL/min/1.73 m<sup>2</sup>) places Jennifer at
            CKD Stage 2.
          </p>
        </div>,
        <figure class="tour-figures">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/images/Jennifer-Blood-100.jpg`}
          />
        </figure>,
        <figure class="tour-figures">
          <img
            src={`${process.env.REACT_APP_PUBLIC_URL}/images/Jennifer-pain-100.jpg`}
          />
        </figure>
      ],
      profileIntro:
        "Click on the flags below to see the testing used to confirm risk of rapid disease progression as well as Jennifer’s individual risk",
      riskDescription: (
        <>
          <p>US-KL greater than expected for age*</p>
          <p>Hypertension before age 35</p>
          <p>Urologic events before age 35</p>
          <p>Family history of ESKD by age 58</p>
        </>
      ),
      references: [
        <p>
          Schrier RW, et al. Predictors of autosomal dominant polycystic kidney
          disease progression.&nbsp;<em>J Am Soc Nephrol</em>. 2014;25:2399-2418
        </p>,
        <p>
          Gansevoort RT, et al. Recommendations for the use of tolvaptan in
          autosomal dominant polycystic kidney disease: a position statement on
          behalf of the ERA-EDTA Working Groups on Inherited Kidney Disorders
          and European Renal Best Practice.&nbsp;
          <em>Nephrol Dial Transplant</em>. 2016;31(3):337-48.
        </p>,
        <p>
          Wetzels JFM, et al. Age- and gender-specific reference values of
          estimated GFR in caucasians: the Nijmegen biomedical study.&nbsp;
          <em>Kidney Int</em>. 2007;72:632-637.
        </p>,
        <p>
          Irazabal MV, et al. Imaging classification of autosomal dominant
          polycystic kidney disease: a simple model for selecting patients for
          clinical trials. J Am Soc Nephrol. 2015;26:160-172.
        </p>,
        <p>
          Imaging classification of ADPKD: a simple model for selecting patients
          for clinical trials.{" "}
          <u>
            <a href="http://www.mayo.edu/research/documents/pkd-center-adpkd-classification/doc-20094754.%20Accessed%201/11/2023">
              http://www.mayo.edu/research/documents/pkd-center-adpkd-classification/doc-20094754
            </a>
          </u>
          <a href="http://www.mayo.edu/research/documents/pkd-center-adpkd-classification/doc-20094754.%20Accessed%201/11/2023">
            . Accessed 1/11/2023
          </a>
          .
        </p>,
        <p>
          Delgado, C. <em>et al.</em> (2022) &ldquo;A unifying approach for GFR
          estimation: Recommendations of the NKF-ASN task force on reassessing
          the inclusion of race in diagnosing kidney disease,&rdquo;{" "}
          <em>American Journal of Kidney Diseases</em>, 79(2). Available at:
          https://doi.org/10.1053/j.ajkd.2021.08.003.
        </p>
      ],
      summary: (
        <div>
          <p>
            Jennifer is 41 years old and has been diagnosed with Autosomal
            Dominant Polycystic Kidney Disease (ADPKD). Jennifer is considered
            obese and has uncontrolled high blood pressure. She suffers from
            repeated bouts of lower back pain and had one episode of blood in
            her urine. She has a family history of ADPKD and her father reached
            kidney failure and needed to begin dialysis at the age of 55.
          </p>
          <p>
            Let’s take a closer look at some tests Jennifer's kidney doctor has
            ordered to better understand how quickly Jennifer might progress to
            kidney failure <sup>1,2,3</sup>.
          </p>
        </div>
      ),
      summaryRefs: "",
      footnote: <span></span>,
      acronymDefinitions:
        "ADPKD=autosomal dominant polycystic kidney disease; US-KL=ultrasound kidney length; ESKD=end stage kidney disease; AA=African American; O=other; eGFR=estimated glomerular filtration rate; PKD=polycystic kidney disease; htTKV=height-adjusted total kidney volume; MRI=magnetic resonance imaging; CT=computed tomography; CKD=chronic kidney disease.",
      hasReveal: false,
      hasPartialData: false,
      age: {
        value: 41,
        available: true
      },
      sex: {
        value: "F",
        available: true
      },
      height: {
        value: "5'4\"",
        available: true
      },
      weight: {
        value: "210",
        available: true
      },
      race: {
        value: "O",
        available: true
      },
      mutations: {
        value: "Not available",
        available: false
      },
      serumCreatine: {
        value: "0.96",
        available: true
      },
      kidneyLength: {
        value: "17.2",
        available: true
      },

      //
      labTests: {
        "Serum Creatinine": {
          value: "0.96",
          unit: "mg/dL",
          standardRange: "0.50 - 1.20",
          comment: "",
          callout: { tag: "none" }
        },
        eGFR: {
          value: "88",
          unit: "mL/min/1.73m²",
          standardRange: (
            <span>
              <span style={{ fontFamily: "aerial" }}>{" ≥ "}</span>
              <span>60</span>
            </span>
          ),
          comment: "",
          callout: { tag: "some", value: { stepNumber: 1 } }
        },
        "Blood Pressure": {
          value: "140/90",
          unit: "mmHg",
          standardRange: (
            <span>
              <span style={{ fontFamily: "aerial" }}>{" < "}</span>
              <span>120/80</span>
            </span>
          ),
          comment: "High, Uncontrolled",
          callout: { tag: "none" }
        },
        "Blood in Urine": {
          value: "Yes",
          unit: "",
          standardRange: "",
          comment: "",
          callout: { tag: "some", value: { stepNumber: 2 } }
        },
        Pain: {
          value: "Yes",
          unit: "",
          standardRange: "",
          comment: "",
          callout: { tag: "some", value: { stepNumber: 3 } }
        },
        "Family History": {
          value: "Yes",
          unit: "",
          standardRange: "",
          comment: "Father reached kidney failure at 55",
          callout: { tag: "none" }
        },
        "Other Risk Factors ": {
          value: "Obesity",
          unit: "",
          standardRange: "",
          comment: "",
          callout: { tag: "none" }
        }
      },
      imagingTests: {
        "Ultrasound Ordered?": {
          value: "Yes",
          unit: "",
          standardRange: "",
          comment: "",
          callout: { tag: "none" }
        },
        "Kidney Length": {
          value: "17.2",
          unit: "cm",
          standardRange: "10-13",
          comment: "",
          callout: { tag: "none" }
        },
        "MRI Ordered?": {
          value: "Yes",
          unit: "",
          standardRange: "",
          comment: "",
          callout: { tag: "none" }
        },
        "Kidney Size": {
          value: "502",
          unit: "mL/m",
          standardRange: "150-250",
          comment: "",
          callout: { tag: "none" }
        }
      },
      //
      eGFR: {
        value: "88",
        available: true
      },
      tKV: {
        value: "Perform MRI/CT",
        reveal: "502",
        available: false
      },
      proPKDScore: {
        value: "Not available",
        available: false
      },
      otherRiskFactors: {
        value: "Obesity",
        available: true
      },
      mayoImagingClassification: {
        value: "",
        available: false,
        reveal: {
          mayoImagingClassificationTitle: {
            value: "Class 1C: High Risk for eGFR decline",
            available: true
          },
          mayoImagingClassificationDescription: {
            value:
              "3-4.5% estimated yearly percentage increase in kidney growth",
            available: true
          }
        }
      },
      estimatedAgeAtESRD: {
        available: true,
        value: "65"
      },
      history: {
        hyperBefore35: {
          value: "Yes",
          available: true
        },
        urologicalBefore35: {
          value: "Yes",
          available: true
        },
        familyESRD: {
          value: "Yes (55 yrs)",
          available: true
        }
      },
      kidneyDimensions: {
        left: {
          sagittal: "TBD",
          coronal: "166 mm",
          transverse: { width: "71 mm", depth: "72 mm" }
        },
        right: {
          sagittal: "TBD",
          coronal: "172 mm",
          transverse: { width: "68 mm", depth: "66 mm" }
        }
      },
      charts: {
        tkv: {
          yAxis: {
            ticks: [0, 500, 1000, 1500, 2000, 2500],
            min: 0,
            max: 2500
          },
          data: [
            // { year: 31, level: 465.21, ignorePoint: true, ignoreTick: false, history: true },
            // { year: 32, level: 465.21, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 33, level: 465.21, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 34, level: 465.21, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 35, level: 465.21, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 36, level: 470.84, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 37, level: 478.51, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 38, level: 488.25, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 39, level: 500.11, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 40, level: 514.13, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 41, level: 530.35, ignorePoint: false, ignoreTick: false, history: false, baseline: true },
            // { year: 42, level: 548.83, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 43, level: 569.6, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 44, level: 592.71, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 45, level: 618.21, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 46, level: 646.14, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 47, level: 676.53, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 48, level: 709.45, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 49, level: 744.92, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 50, level: 783.0, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 51, level: 823.73, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 52, level: 867.16, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 53, level: 913.32, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 54, level: 962.27, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 55, level: 1014.04, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 56, level: 1068.68, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 57, level: 1126.24, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 58, level: 1186.76, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 59, level: 1250.28, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 60, level: 1316.86, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 61, level: 1386.52, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 62, level: 1459.32, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 63, level: 1535.31, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 64, level: 1614.51, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 65, level: 1696.99, ignorePoint: true, ignoreTick: false, history: false }

            // { year: 31, level: 460.48, ignorePoint: true, ignoreTick: false, history: true },
            // { year: 32, level: 464.71, ignorePoint: true, ignoreTick: false, history: true },
            // { year: 33, level: 465.8, ignorePoint: true, ignoreTick: false, history: true },
            // { year: 34, level: 468.44, ignorePoint: true, ignoreTick: false, history: true },
            {
              year: 35,
              level: 465.21,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 36,
              level: 464.59,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 37,
              level: 466.92,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 38,
              level: 472.45,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 39,
              level: 481.37,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 40,
              level: 493.77,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 41,
              level: 502,
              ignorePoint: false,
              ignoreTick: false,
              history: false,
              baseline: true
            },
            {
              year: 42,
              level: 529.01,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 43,
              level: 551.73,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 44,
              level: 577.69,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 45,
              level: 606.73,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 46,
              level: 638.67,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 47,
              level: 673.31,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 48,
              level: 710.44,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 49,
              level: 749.87,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 50,
              level: 791.41,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 51,
              level: 834.91,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 52,
              level: 880.24,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 53,
              level: 927.32,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 54,
              level: 976.12,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 55,
              level: 1026.65,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 56,
              level: 1079.03,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 57,
              level: 1133.43,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 58,
              level: 1190.11,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 59,
              level: 1249.45,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 60,
              level: 1311.91,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 61,
              level: 1378.08,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 62,
              level: 1448.69,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 63,
              level: 1524.57,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 64,
              level: 1606.72,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 65,
              level: 1696.29,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            }
            // { year: 66, level: 1794.6, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 67, level: 1903.12, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 68, level: 2023.53, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 69, level: 2157.68, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 70, level: 2307.62, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 71, level: 2475.63, ignorePoint: true, ignoreTick: false, history: false }
          ]
        },
        egfr: {
          yAxis: {
            ticks: [0, 25, 50, 75, 100, 125],
            min: 0,
            max: 110
          },
          data: [
            // { year: 31, level: 93.03, ignorePoint: true, ignoreTick: false, history: true },
            // { year: 32, level: 93.03, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 33, level: 93.03, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 34, level: 93.03, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 35, level: 93.03, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 36, level: 92.5, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 37, level: 91.85, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 38, level: 91.08, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 39, level: 90.17999999999999, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 40, level: 89.16, ignorePoint: true, ignoreTick: true, history: true },
            // { year: 41, level: 88.0, ignorePoint: false, ignoreTick: false, history: false, baseline: true },
            // { year: 42, level: 86.7, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 43, level: 85.25999999999999, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 44, level: 83.67, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 45, level: 81.92999999999999, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 46, level: 80.03, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 47, level: 77.97, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 48, level: 75.74, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 49, level: 73.34, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 50, level: 70.75999999999999, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 51, level: 68.00999999999999, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 52, level: 65.07000000000001, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 53, level: 61.93, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 54, level: 58.61, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 55, level: 55.08, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 56, level: 51.36, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 57, level: 47.419999999999995, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 58, level: 43.269999999999996, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 59, level: 38.9, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 60, level: 34.32, ignorePoint: true, ignoreTick: true, history: false },
            // { year: 61, level: 29.5, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 62, level: 24.45, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 63, level: 19.17, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 64, level: 13.649999999999999, ignorePoint: false, ignoreTick: false, history: false },
            // { year: 65, level: 7.88, ignorePoint: true, ignoreTick: false, history: false },

            // { year: 31, level: 90.99, ignorePoint: true, ignoreTick: false, history: true },
            // { year: 32, level: 90.99, ignorePoint: true, ignoreTick: false, history: true },
            // { year: 33, level: 90.98, ignorePoint: true, ignoreTick: false, history: true },
            // { year: 34, level: 90.94, ignorePoint: true, ignoreTick: false, history: true },
            {
              year: 35,
              level: 90.93,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 36,
              level: 90.81,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 37,
              level: 90.52,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 38,
              level: 90.03,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 39,
              level: 89.34,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 40,
              level: 88.43,
              ignorePoint: true,
              ignoreTick: false,
              history: true
            },
            {
              year: 41,
              level: 88,
              ignorePoint: false,
              ignoreTick: false,
              history: false,
              baseline: true
            },
            {
              year: 42,
              level: 85.95,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 43,
              level: 84.39,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 44,
              level: 82.62,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 45,
              level: 80.65,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 46,
              level: 78.49,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 47,
              level: 76.16,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 48,
              level: 73.65,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 49,
              level: 71.0,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 50,
              level: 68.19,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 51,
              level: 65.25,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 52,
              level: 62.18,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 53,
              level: 58.98,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 54,
              level: 55.65,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 55,
              level: 52.2,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 56,
              level: 48.61,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 57,
              level: 44.87,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 58,
              level: 40.96,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 59,
              level: 36.87,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 60,
              level: 32.54,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 61,
              level: 27.96,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 62,
              level: 23.06,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 63,
              level: 17.8,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 64,
              level: 12.11,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            },
            {
              year: 65,
              level: 6,
              ignorePoint: true,
              ignoreTick: false,
              history: false
            }
            // { year: 66, level: 2.8, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 67, level: 0.8, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 68, level: 0.4, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 69, level: 0.38, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 70, level: 0.36, ignorePoint: true, ignoreTick: false, history: false },
            // { year: 71, level: 0.33, ignorePoint: true, ignoreTick: false, history: false }
          ]
        }
      }
    }
  ],
  expert: [
    {
      audiences: ["patient", "expert"],
      name: "Bill W.",
      tag: "Bill",
      profileIntro:
        "Click on the flags below to walk through an assessment that confirms rapid disease progression risk and assesses how rapidly Bill’s disease may progress",
      tourData: [
        <div>
          <h4>eGFR</h4>
          <p>
            Baseline eGFR of 82 (mL/min/1.73m<sup>2</sup>) places Bill at CKD
            Stage 2.
          </p>
        </div>,
        <div>
          <h4>eGFR</h4>
          <p>
            Bill’s estimated historical eGFR shows an inflection point at about
            age 30, while he was still at CKD Stage 1. At this point, Bill’s
            rate of eGFR decline increased.
          </p>
        </div>,
        <div>
          <p>
            Not all truncating mutations lead to rapidly progressing disease.
          </p>
          <p>
            <Link to="/expert/prognostic-tools/genetic-testing">
              More information
            </Link>{" "}
            on the genetics in ADPKD
          </p>
        </div>,
        <div>
          <h4>PROPKD Score</h4>
          <p>
            Since Bill had a genetic test, we know his PROPKD score is 9 (the
            highest score within the high-risk group). His PROPKD score confirms
            his high risk for rapid disease progression and also provides some
            information on how rapidly his disease may progress. The median age
            for ESKD onset in the high-risk group is 49.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            Baseline htTKV is 1,343 mL/m, which is greater than expected for age
            (normal adult htTKV range at this age is ~150-250).
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            With ADPKD Imaging Classification based on MRI/CT-calculated htTKV,
            we can assess Bill’s risk for eGFR decline.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            With MRI-calculated htTKV, we can estimate historical values for
            htTKV growth. In early disease progression, htTKV steadily increased
            even when eGFR levels remained within a normal range.
          </p>
          <p>
            We can also estimate projected values for htTKV growth and future
            eGFR decline.
          </p>
          <p>
            Drag the slider to see a simulation of projected disease progression
            for Bill.
          </p>
        </div>
      ],
      profilePic: BillProfilePic,
      riskDescription: (
        <>
          <p>Being male</p>
          <p>Hypertension before age 35</p>
          <p>Urologic events before age 35</p>
          <p>
            Truncating <i>PKD1</i> mutation
          </p>
          <p>TKV greater than expected for age</p>
        </>
      ),
      references: [
        <p>
          Schrier RW, et al. Predictors of autosomal dominant polycystic kidney
          disease progression. <i>J Am Soc Nephrol</i>. 2014;25:2399-2418.
        </p>,
        <p>
          Gansevoort RT, et al. Recommendations for the use of tolvaptan in
          autosomal dominant polycystic kidney disease: a position statement on
          behalf of the ERA-EDTA Working Groups on Inherited Kidney Disorders
          and European Renal Best Practice. <i>Nephrol Dial Transplant</i>.
          2016;31(3):337-48.
        </p>,
        <p>
          Wetzels JFM, et al. Age- and gender-specific reference values of
          estimated GFR in caucasians: the Nijmegen biomedical study.{" "}
          <i>Kidney Int</i>. 2007;72:632-637.
        </p>,
        <p>
          Irazabal MV, et al. Imaging classification of autosomal dominant
          polycystic kidney disease: a simple model for selecting patients for
          clinical trials. <i>J Am Soc Nephrol</i>. 2015;26:160-172.
        </p>,
        <p>
          Imaging classification of ADPKD: a simple model for selecting patients
          for clinical trials.
          http://www.mayo.edu/research/documents/pkd-center-adpkd-classification/doc-20094754.
          Accessed January 09, 2019.
        </p>,
        <p>
          Cornec-Le Gall E, et al. The PROPKD score: a new algorithm to predict
          renal survival in autosomal dominant polycystic kidney disease.{" "}
          <i>J Am Soc Nephrol</i>. 2016;27(3):942-951.
        </p>,
        <p>
          Cheong B, et al. Normal values for renal length and volume as measured
          by magnetic resonance imaging. <i>Clin J Am Soc Nephrol</i>.
          2007;2:38-45.
        </p>,
        <p>
          Levey AS, et al. Definition and classification of chronic kidney
          disease: a position statement from kidney disease: improving global
          outcomes (KDIGO). <i>Kidney Int</i>. 2005;67:2089-2100.
        </p>,
        <p>
          PKD Charity. Fast Facts about ADPKD. The Polycystic Kidney Disease
          Charity. 2017.
          https://pkdcharity.org.uk/about-adpkd/just-diagnosed/fast-facts-about-adpkd
          Accessed January 09, 2019.
        </p>,
        <p>
          Rangan GK, et al. Autosomal dominant polycystic kidney disease: a path
          forward. <i>Semin Nephrol</i>. 2015;35(6):524-537.
        </p>
      ],
      summary:
        "Bill is 35 years old and has been diagnosed with ADPKD by a kidney ultrasound. Bill is at risk for rapid disease progression, as indicated by the following risk factors: being male, hypertension before age 35, urologic events before age 35, truncating PKD1 mutation, and TKV greater than expected for age. Let's take a closer look with some prognostic tools to confirm the risk of rapid disease progression and assess how rapidly his disease may progress.",
      summaryRefs: "1,2",
      acronymDefinitions:
        "ADPKD=autosomal dominant polycystic kidney disease; PKD=polycystic kidney disease; TKV=total kidney volume; AA=African American; O=other; eGFR=estimated glomerular filtration rate; ESKD=end stage kidney disease; htTKV=height-adjusted TKV; MRI=magnetic resonance imaging; CT=computed tomography; CKD=chronic kidney disease.",
      hasReveal: false,
      hasPartialData: false,

      age: {
        value: 35,
        available: true
      },
      sex: {
        value: "M",
        available: true
      },
      height: {
        value: "5'11\"",
        available: true
      },
      weight: {
        value: "171",
        available: true
      },
      race: {
        value: "AA",
        available: true
      },
      mutations: {
        value: "Truncating PKD1",
        available: true
      },
      serumCreatine: {
        value: "1.15",
        available: true
      },
      kidneyLength: {
        value: "Not obtained",
        available: false
      },
      eGFR: {
        value: "82",
        available: true
      },
      tKV: {
        value: "1,343",
        available: true
      },
      proPKDScore: {
        value: "9 -  HIGH risk of progression to ESKD",
        available: true
      },
      mayoImagingClassification: {
        available: true,
        value: {
          mayoImagingClassificationTitle: {
            value: "Class 1E: High Risk for eGFR decline",
            available: true
          },
          mayoImagingClassificationDescription: {
            value: ">6% estimated yearly percentage increase in kidney growth",
            available: true
          }
        }
      },
      estimatedAgeAtESRD: {
        available: true,
        value: "47"
      },
      history: {
        hyperBefore35: {
          value: "Yes",
          available: true
        },
        urologicalBefore35: {
          value: "Yes",
          available: true
        },
        familyESRD: {
          value: "No",
          available: true
        }
      },
      kidneyDimensions: {
        left: {
          sagittal: "TBD",
          coronal: "188 mm",
          transverse: { width: "105 mm", depth: "110 mm" }
        },
        right: {
          sagittal: "TBD",
          coronal: "203 mm",
          transverse: { width: "107 mm", depth: "113 mm" }
        }
      },
      charts: {
        tkv: {
          yAxis: {
            ticks: [0, 500, 1000, 1500, 2000, 2500, 3000, 3500],
            min: 0,
            max: 3500
          },
          data: [
            {
              year: 21,
              level: 451,
              ignorePoint: true,
              history: true
            },
            {
              year: 22,
              level: 488,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 23,
              level: 527,
              ignorePoint: true,
              history: true
            },
            {
              year: 24,
              level: 570,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 25,
              level: 616,
              ignorePoint: true,
              history: true
            },
            {
              year: 26,
              level: 666,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 27,
              level: 720,
              ignorePoint: true,
              history: true
            },
            {
              year: 28,
              level: 778,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 29,
              level: 842,
              ignorePoint: true,
              history: true
            },
            {
              year: 30,
              level: 910,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 31,
              level: 983,
              ignorePoint: true,
              history: true
            },
            {
              year: 32,
              level: 1063,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 33,
              level: 1149,
              ignorePoint: true,
              history: true
            },
            {
              year: 34,
              level: 1243,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 35,
              level: 1343,
              baseline: true
            },
            {
              year: 37,
              level: 1552
            },
            {
              year: 39,
              level: 1794
            },
            {
              year: 41,
              level: 2073
            },
            {
              year: 43,
              level: 2396
            },
            {
              year: 45,
              level: 2769
            },
            {
              year: 47,
              level: 3200
            }
          ]
        },
        egfr: {
          yAxis: {
            ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            min: 0,
            max: 105
          },
          data: [
            {
              year: 21,
              level: 98.3,
              ignorePoint: true,
              history: true
            },
            {
              year: 22,
              level: 98.2,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 23,
              level: 98.1,
              ignorePoint: true,
              history: true
            },
            {
              year: 24,
              level: 98,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 25,
              level: 97.8,
              ignorePoint: true,
              history: true
            },
            {
              year: 26,
              level: 97.2,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 27,
              level: 96.7,
              ignorePoint: true,
              history: true
            },
            {
              year: 28,
              level: 96.3,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 29,
              level: 95.8,
              ignorePoint: true,
              history: true
            },
            {
              year: 30,
              level: 95.3,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 31,
              level: 93.7,
              ignorePoint: true,
              history: true
            },
            {
              year: 32,
              level: 91.5,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 33,
              level: 89,
              ignorePoint: true,
              history: true
            },
            {
              year: 34,
              level: 86.1,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 35,
              level: 82,
              baseline: true
            },
            {
              year: 37,
              level: 68.4
            },
            {
              year: 39,
              level: 57.1
            },
            {
              year: 41,
              level: 45.9
            },
            {
              year: 43,
              level: 34.6
            },
            {
              year: 45,
              level: 23.3
            },
            {
              year: 47,
              level: 12.1
            }
          ]
        }
      }
    },
    {
      audiences: ["expert"],
      name: "Angela T.",
      tag: "Angela",
      profileIntro:
        "Click on the flags below to walk through an assessment of Angela’s likely rate of disease progression",
      profilePic: AngelaProfilePic,
      tourData: [
        <div>
          <h4>eGFR</h4>
          <p>
            Baseline eGFR of 75.2 (mL/min/1.73 m<sup>2</sup>) places Angela at
            CKD Stage 2.
          </p>
        </div>,
        <div>
          <h4>eGFR</h4>
          <p>Angela’s eGFR decline has been relatively steady so far.</p>
        </div>,
        <div>
          <h4>Ultrasound Kidney Length</h4>
          <p>
            Ultrasound kidney length is 12.8 cm, which is below the 16.5 cm
            threshold for rapid progression in patients younger than 45,
            indicating Angela is likely at low risk for rapid progression.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            Without an MRI or CT scan, we can only estimate historical eGFR
            values for Angela.
          </p>
          <p>
            Drag the slider backward to see a simulation of estimated historical
            disease progression for Angela.
          </p>
        </div>
      ],
      riskDescription: (
        <>
          <p>Urologic events before age 35</p>
        </>
      ),
      summary:
        "Angela is 45 years old and has been diagnosed with ADPKD by a kidney ultrasound. Angela has one risk factor that is associated with rapid disease progression: urologic events before age 35. Let's take a closer look with some prognostic tools to assess her likely rate of disease progression.",
      summaryRefs: "1,2",
      references: [
        <p>
          Schrier RW, et al. Predictors of autosomal dominant polycystic kidney
          disease progression. <i>J Am Soc Nephrol</i>. 2014;25:2399-2418.
        </p>,
        <p>
          Gansevoort RT, et al. Recommendations for the use of tolvaptan in
          autosomal dominant polycystic kidney disease: a position statement on
          behalf of the ERA-EDTA Working Groups on Inherited Kidney Disorders
          and European Renal Best Practice. <i>Nephrol Dial Transplant</i>.
          2016;31(3):337-48.
        </p>,
        <p>
          Wetzels JFM, et al. Age- and gender-specific reference values of
          estimated GFR in caucasians: the Nijmegen biomedical study.{" "}
          <i>Kidney Int</i>. 2007;72:632-637.
        </p>,
        <p>
          Cornec-Le Gall E, et al. The PROPKD score: a new algorithm to predict
          renal survival in autosomal dominant polycystic kidney disease.{" "}
          <i>J Am Soc Nephrol</i>. 2016;27(3):942-951.
        </p>,
        <p>
          Bhutani H, et al. A comparison of ultrasound and magnetic resonance
          imaging shows that kidney length predicts chronic kidney disease in
          autosomal dominant polycystic kidney disease. <i>Kidney Int</i>.
          2015;88:146-151.
        </p>,
        <p>
          Imaging classification of ADPKD: a simple model for selecting patients
          for clinical trials.
          http://www.mayo.edu/research/documents/pkd-center-adpkd-classification/doc-20094754.
          Accessed January 09, 2019.
        </p>,
        <p>
          Cheong B, et al. Normal values for renal length and volume as measured
          by magnetic resonance imaging. <i>Clin J Am Soc Nephrol</i>.
          2007;2:38-45.
        </p>,
        <p>
          Levey AS, et al. Definition and classification of chronic kidney
          disease: a position statement from kidney disease: improving global
          outcomes (KDIGO). <i>Kidney Int</i>. 2005;67:2089-2100.
        </p>,
        <p>
          PKD Charity. Fast Facts about ADPKD. The Polycystic Kidney Disease
          Charity. 2017.
          https://pkdcharity.org.uk/about-adpkd/just-diagnosed/fast-facts-about-adpkd
          Accessed January 09, 2019.
        </p>,
        <p>
          Rangan GK, et al. Autosomal dominant polycystic kidney disease: a path
          forward. <i>Semin Nephrol</i>. 2015;35(6):524-537.
        </p>
      ],
      acronymDefinitions:
        "ADPKD=autosomal dominant polycystic kidney disease; AA=African American; O=other; eGFR=estimated glomerular filtration rate; ESKD=end stage kidney disease; PKD=polycystic kidney disease; htTKV=height-adjusted total kidney volume; MRI=magnetic resonance imaging; CT=computed tomography; CKD=chronic kidney disease.",
      hasReveal: false,
      hasPartialData: true,
      age: {
        value: 45,
        available: true
      },
      sex: {
        value: "F",
        available: true
      },
      height: {
        value: "5'7\"",
        available: true
      },
      weight: {
        value: "150",
        available: true
      },
      race: {
        value: "O",
        available: true
      },
      mutations: {
        value: "Not available",
        available: false
      },
      serumCreatine: {
        value: "0.92",
        available: true
      },
      kidneyLength: {
        value: "12.8",
        available: true
      },
      eGFR: {
        value: "75.2",
        available: true
      },
      tKV: {
        value: "Not needed",
        available: false
      },
      proPKDScore: {
        value: "Not available",
        available: false
      },
      mayoImagingClassification: {
        value: "Not available",
        available: false
      },
      estimatedAgeAtESRD: {
        available: false,
        value: "Not available"
      },
      history: {
        hyperBefore35: {
          value: "No",
          available: true
        },
        urologicalBefore35: {
          value: "Yes",
          available: true
        },
        familyESRD: {
          value: "Yes (70 yrs)",
          available: true
        }
      },
      kidneyDimensions: {
        left: {
          sagittal: "TBD",
          coronal: "TBD",
          transverse: { width: "TBD", depth: "TBD" }
        },
        right: {
          sagittal: "TBD",
          coronal: "TBD",
          transverse: { width: "TBD", depth: "TBD" }
        }
      },
      charts: {
        tkv: {
          available: false,
          yAxis: {
            ticks: [0, 100, 200, 300, 400, 500],
            min: 0,
            max: 500
          },
          data: [
            {
              year: 30,
              level: 258,
              ignorePoint: true,
              history: true
            },
            {
              year: 31,
              level: 262,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 32,
              level: 266,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 33,
              level: 270,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 34,
              level: 274,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 35,
              level: 278,
              ignorePoint: true,
              history: true
            },
            {
              year: 36,
              level: 283,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 37,
              level: 287,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 38,
              level: 291,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 39,
              level: 295,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 40,
              level: 300,
              ignorePoint: true,
              history: true
            },
            {
              year: 41,
              level: 304,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 42,
              level: 309,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 43,
              level: 314,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 44,
              level: 318,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 45,
              level: 323,
              baseline: true
            },
            {
              year: 50,
              level: 348
            },
            {
              year: 55,
              level: 375
            },
            {
              year: 60,
              level: 404
            },
            {
              year: 65,
              level: 435
            },
            {
              year: 70,
              level: 469
            }
          ]
        },
        egfr: {
          yAxis: {
            ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            min: 0,
            max: 105
          },
          data: [
            {
              year: 30,
              level: 80.6,
              ignorePoint: true,
              history: true
            },
            {
              year: 31,
              level: 80.4,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 32,
              level: 80.3,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 33,
              level: 80.1,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 34,
              level: 79.9,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 35,
              level: 79.5,
              ignorePoint: true,
              history: true
            },
            {
              year: 36,
              level: 79.3,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 37,
              level: 79.1,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 38,
              level: 78.8,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 39,
              level: 78.4,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 40,
              level: 77.9,
              ignorePoint: true,
              history: true
            },
            {
              year: 41,
              level: 77.6,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 42,
              level: 77.2,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 43,
              level: 76.7,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 44,
              level: 76.1,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 45,
              level: 75.2,
              baseline: true
            },
            {
              year: 50,
              level: 65.5
            },
            {
              year: 55,
              level: 54.4
            },
            {
              year: 60,
              level: 43.6
            },
            {
              year: 65,
              level: 32.6
            },
            {
              year: 70,
              level: 21.6
            }
          ]
        }
      }
    },
    {
      audiences: ["patient", "expert"],
      name: "Denise P.",
      tag: "Denise",
      profilePic: DeniseProfilePic,
      tourData: [
        <div>
          <h4>eGFR</h4>
          <p>
            Baseline eGFR of 88.8 (mL/min/1.73 m<sup>2</sup>) places Denise at
            CKD Stage 2.
          </p>
        </div>,
        <div>
          <h4>eGFR</h4>
          <p>
            Denise’s estimated historical eGFR shows an inflection point at
            about age 30, while she was still at CKD Stage 1. At this point,
            Denise’s rate of eGFR decline increased.
          </p>
        </div>,
        <div>
          <h4>Ultrasound Kidney Length</h4>
          <p>
            Ultrasound kidney length is 17.2 cm, which is above the 16.5 cm
            threshold for rapid progression in patients younger than 45.
          </p>
          <p>
            In addition to her other risk factors, ultrasound kidney length
            confirms that Denise is at risk for rapid progression.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            An MRI or CT is not necessary to confirm Denise is at risk for rapid
            disease progression because of her ultrasound kidney length and
            other risk factors.
          </p>
          <p>
            However, an MRI or CT would enable calculation of her htTKV, which
            would allow for more detailed assessment of how rapidly her disease
            may progress.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            With ADPKD Imaging Classification based on MRI/CT-calculated htTKV,
            we can assess Denise’s risk for eGFR decline.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            Now we can estimate historical values for htTKV growth. In early
            disease progression, htTKV steadily increased even when eGFR levels
            remained within a normal range.
          </p>
          <p>
            We can also estimate projected values for htTKV growth and future
            eGFR decline.
          </p>
          <p>
            Drag the slider to see a simulation of projected disease progression
            for Denise.
          </p>
        </div>
      ],
      profileIntro:
        "Click on the flags below to walk through an assessment that confirms risk of rapid disease progression and assesses how rapidly Denise’s disease may progress",
      riskDescription: (
        <>
          <p>US-KL greater than expected for age*</p>
          <p>Hypertension before age 35</p>
          <p>Urologic events before age 35</p>
          <p>Family history of ESKD by age 58</p>
        </>
      ),
      references: [
        <p>
          Schrier RW, et al. Predictors of autosomal dominant polycystic kidney
          disease progression. <i>J Am Soc Nephrol</i>. 2014;25:2399-2418.
        </p>,
        <p>
          Gansevoort RT, et al. Recommendations for the use of tolvaptan in
          autosomal dominant polycystic kidney disease: a position statement on
          behalf of the ERA-EDTA Working Groups on Inherited Kidney Disorders
          and European Renal Best Practice. <i>Nephrol Dial Transplant</i>.
          2016;31(3):337-48.
        </p>,
        <p>
          Wetzels JFM, et al. Age- and gender-specific reference values of
          estimated GFR in caucasians: the Nijmegen biomedical study.{" "}
          <i>Kidney Int</i>. 2007;72:632-637.
        </p>,
        <p>
          Cornec-Le Gall E, et al. The PROPKD score: a new algorithm to predict
          renal survival in autosomal dominant polycystic kidney disease.{" "}
          <i>J Am Soc Nephrol</i>. 2016;27(3):942-951.
        </p>,
        <p>
          Bhutani H, et al. A comparison of ultrasound and magnetic resonance
          imaging shows that kidney length predicts chronic kidney disease in
          autosomal dominant polycystic kidney disease. <i>Kidney Int</i>.
          2015;88:146-151.
        </p>,
        <p>
          Imaging classification of ADPKD: a simple model for selecting patients
          for clinical trials.
          http://www.mayo.edu/research/documents/pkd-center-adpkd-classification/doc-20094754.
          Accessed January 09, 2019.
        </p>,
        <p>
          Irazabal MV, et al. Imaging classification of autosomal dominant
          polycystic kidney disease: a simple model for selecting patients for
          clinical trials. <i>J Am Soc Nephrol</i>. 2015;26:160-172.
        </p>,
        <p>
          Cheong B, et al. Normal values for renal length and volume as measured
          by magnetic resonance imaging. <i>Clin J Am Soc Nephrol</i>.
          2007;2:38-45.
        </p>,
        <p>
          Levey AS, et al. Definition and classification of chronic kidney
          disease: a position statement from kidney disease: improving global
          outcomes (KDIGO). <i>Kidney Int</i>. 2005;67:2089-2100.
        </p>,
        <p>
          PKD Charity. Fast Facts about ADPKD. The Polycystic Kidney Disease
          Charity. 2017.
          https://pkdcharity.org.uk/about-adpkd/just-diagnosed/fast-facts-about-adpkd
          Accessed January 09, 2019.
        </p>,
        <p>
          Rangan GK, et al. Autosomal dominant polycystic kidney disease: a path
          forward. <i>Semin Nephrol</i>. 2015;35(6):524-537.
        </p>
      ],
      summary:
        "Denise is 35 years old and has been diagnosed with ADPKD by a kidney ultrasound. Denise is at risk for rapid disease progression, as indicated by the following risk factors: US-KL >16.5 cm in patients less than 45 years old*, hypertension before age 35, urologic events before age 35, overweight/obesity and family history of ESKD by age 58. Let's take a closer look with some prognostic tools to confirm the risk of rapid disease progression and assess how rapidly her disease may progress.",
      summaryRefs: "1,2,5",
      footnote: (
        <span>
          * When rapid progression is defined as CKD stage 3 development within
          8 years.<sup>5</sup>
        </span>
      ),
      acronymDefinitions:
        "ADPKD=autosomal dominant polycystic kidney disease; US-KL=ultrasound kidney length; ESKD=end stage kidney disease; AA=African American; O=other; eGFR=estimated glomerular filtration rate; PKD=polycystic kidney disease; htTKV=height-adjusted total kidney volume; MRI=magnetic resonance imaging; CT=computed tomography; CKD=chronic kidney disease.",
      hasReveal: true,
      hasPartialData: false,
      age: {
        value: 35,
        available: true
      },
      sex: {
        value: "F",
        available: true
      },
      height: {
        value: "5'4\"",
        available: true
      },
      weight: {
        value: "210",
        available: true
      },
      race: {
        value: "O",
        available: true
      },
      mutations: {
        value: "Not available",
        available: false
      },
      serumCreatine: {
        value: "0.85",
        available: true
      },
      kidneyLength: {
        value: "17.2",
        available: true
      },
      eGFR: {
        value: "88.8",
        available: true
      },
      tKV: {
        value: "Perform MRI/CT",
        reveal: "502",
        available: false
      },
      proPKDScore: {
        value: "Not available",
        available: false
      },
      otherRiskFactors: {
        value: "Obesity",
        available: true
      },
      mayoImagingClassification: {
        value: "",
        available: false,
        reveal: {
          mayoImagingClassificationTitle: {
            value: "Class 1C: High Risk for eGFR decline",
            available: true
          },
          mayoImagingClassificationDescription: {
            value:
              "3-4.5% estimated yearly percentage increase in kidney growth",
            available: true
          }
        }
      },
      estimatedAgeAtESRD: {
        available: true,
        value: "58"
      },
      history: {
        hyperBefore35: {
          value: "Yes",
          available: true
        },
        urologicalBefore35: {
          value: "Yes",
          available: true
        },
        familyESRD: {
          value: "Yes (58 yrs)",
          available: true
        }
      },
      kidneyDimensions: {
        left: {
          sagittal: "TBD",
          coronal: "166 mm",
          transverse: { width: "71 mm", depth: "72 mm" }
        },
        right: {
          sagittal: "TBD",
          coronal: "172 mm",
          transverse: { width: "62 mm", depth: "66 mm" }
        }
      },
      charts: {
        tkv: {
          yAxis: {
            ticks: [0, 200, 400, 600, 800, 1000],
            min: 0,
            max: 1050
          },
          data: [
            {
              year: 20,
              level: 318,
              ignorePoint: true,
              history: true
            },
            {
              year: 21,
              level: 327,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 22,
              level: 338,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 23,
              level: 348,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 24,
              level: 359,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 25,
              level: 370,
              ignorePoint: true,
              history: true
            },
            {
              year: 26,
              level: 381,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 27,
              level: 393,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 28,
              level: 405,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 29,
              level: 418,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 30,
              level: 431,
              ignorePoint: true,
              history: true
            },
            {
              year: 31,
              level: 444,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 32,
              level: 458,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 33,
              level: 472,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 34,
              level: 487,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 35,
              level: 502,
              baseline: true
            },
            {
              year: 40,
              level: 581
            },
            {
              year: 45,
              level: 674
            },
            {
              year: 50,
              level: 781
            },
            {
              year: 55,
              level: 906
            },
            {
              year: 60,
              level: 1050
            }
          ]
        },
        egfr: {
          yAxis: {
            ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100, 110],
            min: 0,
            max: 110
          },
          data: [
            {
              year: 20,
              level: 107,
              ignorePoint: true,
              history: true
            },
            {
              year: 21,
              level: 106.9,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 22,
              level: 106.8,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 23,
              level: 106.6,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 24,
              level: 106.5,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 25,
              level: 106.4,
              ignorePoint: true,
              history: true
            },
            {
              year: 26,
              level: 106,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 27,
              level: 105.4,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 28,
              level: 104.6,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 29,
              level: 103.9,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 30,
              level: 102.9,
              ignorePoint: true,
              history: true
            },
            {
              year: 31,
              level: 100.5,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 32,
              level: 98,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 33,
              level: 95.1,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 34,
              level: 92,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 35,
              level: 88.8,
              baseline: true
            },
            {
              year: 40,
              level: 73.2
            },
            {
              year: 45,
              level: 56.8
            },
            {
              year: 50,
              level: 40.4
            },
            {
              year: 55,
              level: 24
            },
            {
              year: 60,
              level: 7.6
            }
          ]
        }
      }
    },
    {
      audiences: ["expert"],
      name: "Frank L.",
      tag: "Frank",
      profilePic: FrankProfilePic,
      tourData: [
        <div>
          <h4>eGFR</h4>
          <p>
            Baseline eGFR of 52.8 (mL/min/1.73 m<sup>2</sup>) places Frank at
            CKD Stage 3.
          </p>
        </div>,
        <div>
          <h4>eGFR</h4>
          <p>
            Frank’s historical eGFR values show rapid decline of more than 5
            mL/min/1.73m<sup>2</sup> over 1 year.
          </p>
          <p>
            An inflection point had occurred even earlier at about age 45, while
            he was at CKD Stage 2. At this point, Frank’s rate of eGFR decline
            increased.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            Baseline htTKV is 1,669 mL/m, which is greater than expected for age
            (normal adult htTKV range at this age is ~150-250).
          </p>
          <p>
            We can plot Frank’s htTKV and age on the ADPKD Imaging
            Classification graph. This confirms that Frank is at high risk for
            rapid progression and helps assess how rapidly his disease may
            progress.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            With ADPKD Imaging Classification based on MRI/CT-calculated htTKV,
            we can assess Frank’s risk for eGFR decline.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            With MRI-calculated htTKV, we can estimate historical values for
            htTKV growth. In early disease progression, htTKV steadily increased
            even when eGFR levels remained within a normal range.
          </p>
          <p>
            We can also estimate projected values for htTKV growth and future
            eGFR decline.
          </p>
          <p>
            Drag the slider to see a simulation of projected disease progression
            for Frank.
          </p>
        </div>
      ],
      profileIntro:
        "Click on the flags below to walk through an assessment that confirms rapid disease progression risk and assesses how rapidly Frank’s disease may progress",
      riskDescription: (
        <>
          <p>Being male</p>
          <p>
            eGFR decline <span className="symbol">&ge;</span> 5 mL/min/1.73 m
            <sup>2</sup> within a year
          </p>
          <p>Hypertension before age 35</p>
          <p>Urologic events before age 35</p>
          <p>Family history of ESKD by age 58</p>
          <p>TKV greater than expected for age</p>
        </>
      ),
      references: [
        <p>
          Schrier RW, et al. Predictors of autosomal dominant polycystic kidney
          disease progression. <i>J Am Soc Nephrol</i>. 2014;25:2399-2418.
        </p>,
        <p>
          Gansevoort RT, et al. Recommendations for the use of tolvaptan in
          autosomal dominant polycystic kidney disease: a position statement on
          behalf of the ERA-EDTA Working Groups on Inherited Kidney Disorders
          and European Renal Best Practice. <i>Nephrol Dial Transplant</i>.
          2016;31(3):337-48.
        </p>,
        <p>
          Wetzels JFM, et al. Age- and gender-specific reference values of
          estimated GFR in caucasians: the Nijmegen biomedical study.{" "}
          <i>Kidney Int</i>. 2007;72:632-637.
        </p>,
        <p>
          Irazabal MV, et al. Imaging classification of autosomal dominant
          polycystic kidney disease: a simple model for selecting patients for
          clinical trials. <i>J Am Soc Nephrol</i>. 2015;26:160-172.
        </p>,
        <p>
          Cheong B, et al. Normal values for renal length and volume as measured
          by magnetic resonance imaging. <i>Clin J Am Soc Nephrol</i>.
          2007;2:38-45.
        </p>,
        <p>
          Imaging classification of ADPKD: a simple model for selecting patients
          for clinical trials.
          http://www.mayo.edu/research/documents/pkd-center-adpkd-classification/doc-20094754.
          Accessed January 09, 2019.
        </p>,
        <p>
          Cornec-Le Gall E, et al. The PROPKD score: a new algorithm to predict
          renal survival in autosomal dominant polycystic kidney disease.{" "}
          <i>J Am Soc Nephrol</i>. 2016;27(3):942-951.
        </p>,
        <p>
          Levey AS, et al. Definition and classification of chronic kidney
          disease: a position statement from kidney disease: improving global
          outcomes (KDIGO). <i>Kidney Int</i>. 2005;67:2089-2100.
        </p>,
        <p>
          PKD Charity. Fast Facts about ADPKD. The Polycystic Kidney Disease
          Charity. 2017.
          https://pkdcharity.org.uk/about-adpkd/just-diagnosed/fast-facts-about-adpkd
          Accessed January 09, 2019.
        </p>,
        <p>
          Rangan GK, et al. Autosomal dominant polycystic kidney disease: a path
          forward. <i>Semin Nephrol</i>. 2015;35(6):524-537.
        </p>
      ],
      summary:
        "Frank is 52 years old and has been diagnosed with ADPKD by a kidney ultrasound. Frank is at risk for rapid disease progression, as indicated by the following risk factors: being male, rapid historical eGFR decline*, hypertension before age 35, urologic events before age 35, proteinuria/albuminuria, family history of ESKD by age 58, and TKV greater than expected for age. Let's take a closer look with some prognostic tools to confirm the risk of rapid disease progression and assess how rapidly his disease may progress.",
      summaryRefs: "1,2",
      footnote: (
        <span>
          *Confirmed eGFR decline <span className="symbol">≥</span> 5
          mL/min/1.73 m<sup>2</sup> within a year and/or{" "}
          <span className="symbol">≥</span> 2.5 mL/min/1.73 m<sup>2</sup> per
          year over a period of five years.<sup>2</sup>
        </span>
      ),
      acronymDefinitions:
        "ADPKD=autosomal dominant polycystic kidney disease; eGFR=estimated glomerular filtration rate; ESKD=end stage kidney disease; TKV=total kidney volume; AA=African American; O=other; PKD=polycystic kidney disease; htTKV=height-adjusted total kidney volume; MRI=magnetic resonance imaging; CT=computed tomography; CKD=chronic kidney disease.",
      hasReveal: false,
      hasPartialData: false,
      age: {
        value: 52,
        available: true
      },
      sex: {
        value: "M",
        available: true
      },
      height: {
        value: "5'9\"",
        available: true
      },
      weight: {
        value: "181",
        available: true
      },
      race: {
        value: "O",
        available: true
      },
      mutations: {
        value: "Not available",
        available: false
      },
      serumCreatine: {
        value: "1.5",
        available: true
      },
      kidneyLength: {
        value: "Not obtained",
        available: false
      },
      eGFR: {
        value: "52.8",
        available: true
      },
      tKV: {
        value: "1,669",
        available: true
      },
      proPKDScore: {
        value: "Not available",
        available: false
      },
      otherRiskFactors: {
        value: "Proteinuria / albuminuria",
        available: true
      },
      mayoImagingClassification: {
        available: true,
        value: {
          mayoImagingClassificationTitle: {
            value: "Class 1D: High Risk for eGFR decline",
            available: true
          },
          mayoImagingClassificationDescription: {
            value:
              "4.5-6% estimated yearly percentage increase in kidney growth",
            available: true
          }
        }
      },
      estimatedAgeAtESRD: {
        available: true,
        value: "61"
      },
      history: {
        hyperBefore35: {
          value: "Yes",
          available: true
        },
        urologicalBefore35: {
          value: "Yes",
          available: true
        },
        familyESRD: {
          value: "Yes (58 yrs)",
          available: true
        }
      },
      kidneyDimensions: {
        left: {
          sagittal: "TBD",
          coronal: "173 mm",
          transverse: { width: "122 mm", depth: "130 mm" }
        },
        right: {
          sagittal: "TBD",
          coronal: "180 mm",
          transverse: { width: "124 mm", depth: "127 mm" }
        }
      },
      charts: {
        tkv: {
          yAxis: {
            ticks: [0, 500, 1000, 1500, 2000, 2500, 3000],
            min: 0,
            max: 3000
          },
          data: [
            {
              year: 40,
              level: 960,
              ignorePoint: true,
              history: true
            },
            {
              year: 41,
              level: 1006,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 42,
              level: 1053,
              ignorePoint: true,
              history: true
            },
            {
              year: 43,
              level: 1103,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 44,
              level: 1154,
              ignorePoint: true,
              history: true
            },
            {
              year: 45,
              level: 1209,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 46,
              level: 1266,
              ignorePoint: true,
              history: true
            },
            {
              year: 47,
              level: 1325,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 48,
              level: 1388,
              ignorePoint: true,
              history: true
            },
            {
              year: 49,
              level: 1453,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 50,
              level: 1522,
              ignorePoint: true,
              history: true
            },
            {
              year: 51,
              level: 1594,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 52,
              level: 1669,
              baseline: true
            },
            {
              year: 54,
              level: 1822
            },
            {
              year: 56,
              level: 1990
            },
            {
              year: 58,
              level: 2173
            },
            {
              year: 60,
              level: 2373
            },
            {
              year: 62,
              level: 2591
            }
          ]
        },
        egfr: {
          yAxis: {
            ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            min: 0,
            max: 105
          },
          data: [
            {
              year: 40,
              level: 85.6,
              history: true
            },
            {
              year: 41,
              level: 85.3,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 42,
              level: 84.9,
              ignorePoint: true,
              history: true
            },
            {
              year: 43,
              level: 84.1,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 44,
              level: 83.1,
              ignorePoint: true,
              history: true
            },
            {
              year: 45,
              level: 81.3,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 46,
              level: 78.2,
              history: true
            },
            {
              year: 47,
              level: 75.8,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 48,
              level: 72.3,
              ignorePoint: true,
              history: true
            },
            {
              year: 49,
              level: 68.1,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 50,
              level: 63.3,
              ignorePoint: true,
              history: true
            },
            {
              year: 51,
              level: 58.2,
              ignoreTick: true,
              history: true
            },
            {
              year: 52,
              level: 52.8,
              baseline: true,
              callout: "eGFR decline greater \nthan 5 over 1 year"
            },
            {
              year: 54,
              level: 43.9
            },
            {
              year: 56,
              level: 34.5
            },
            {
              year: 58,
              level: 25.1
            },
            {
              year: 60,
              level: 15.7
            },
            {
              year: 62,
              level: 6.3
            }
          ]
        }
      }
    },
    {
      audiences: ["expert"],
      name: "Jeremy B.",
      tag: "Jeremy",
      profilePic: JeremyProfilePic,
      tourData: [
        <div>
          <h4>eGFR</h4>
          <p>
            Baseline eGFR of 80.4 (mL/min/1.73 m<sup>2</sup>) places Jeremy at
            CKD Stage 2.
          </p>
        </div>,
        <div>
          <h4>eGFR</h4>
          <p>
            Jeremy’s estimated historical eGFR shows an inflection point at
            about age 24, while he was still at CKD Stage 1. At this point,
            Jeremy’s rate of eGFR decline increased.
          </p>
        </div>,
        <div>
          <h4>Urological Events and Family Members with ESKD</h4>
          <p>
            The presence of urological events before age 35 and having a family
            member reaching ESKD at 50 yrs old indicate that Jeremy is possibly
            at risk of rapid progression.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            Jeremy needs an MRI or CT so that we can calculate his htTKV. This
            would confirm that Jeremy is at high risk for rapid progression and
            help assess how rapidly his disease may progress.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            With ADPKD Imaging Classification based on MRI/CT-calculated htTKV,
            we can assess Jeremy’s risk for eGFR decline.
          </p>
        </div>,
        <div>
          <h4>MRI/CT-calculated TKV</h4>
          <p>
            Now we can estimate historical values for htTKV growth. In early
            disease progression, htTKV steadily increased even when eGFR levels
            remained within a normal range.
          </p>
          <p>
            We can also estimate projected values for htTKV growth and future
            eGFR decline.
          </p>
          <p>
            Drag the slider to see a simulation of projected disease progression
            for Jeremy.
          </p>
        </div>
      ],
      profileIntro:
        "Click on the flags below to walk through an assessment that confirms risk of rapid disease progression and assesses how rapidly Jeremy’s disease may progress",
      riskDescription: (
        <>
          <p>Being male</p>
          <p>Urologic events before age 35</p>
          <p>Family history of ESKD by age 58</p>
        </>
      ),
      references: [
        <p>
          Schrier RW, et al. Predictors of autosomal dominant polycystic kidney
          disease progression. <i>J Am Soc Nephrol</i>. 2014;25:2399-2418.
        </p>,
        <p>
          Gansevoort RT, et al. Recommendations for the use of tolvaptan in
          autosomal dominant polycystic kidney disease: a position statement on
          behalf of the ERA-EDTA Working Groups on Inherited Kidney Disorders
          and European Renal Best Practice. <i>Nephrol Dial Transplant</i>.
          2016;31(3):337-48.
        </p>,
        <p>
          Wetzels JFM, et al. Age- and gender-specific reference values of
          estimated GFR in caucasians: the Nijmegen biomedical study.{" "}
          <i>Kidney Int</i>. 2007;72:632-637.
        </p>,
        <p>
          Cornec-Le Gall E, et al. The PROPKD score: a new algorithm to predict
          renal survival in autosomal dominant polycystic kidney disease.{" "}
          <i>J Am Soc Nephrol</i>. 2016;27(3):942-951.
        </p>,
        <p>
          5. Irazabal MV, et al. Imaging classification of autosomal dominant
          polycystic kidney disease: a simple model for selecting patients for
          clinical trials. <i>J Am Soc Nephrol</i>. 2015;26:160-172.
        </p>,
        <p>
          Imaging classification of ADPKD: a simple model for selecting patients
          for clinical trials.
          http://www.mayo.edu/research/documents/pkd-center-adpkd-classification/doc-20094754.
          Accessed January 09, 2019.
        </p>,
        <p>
          7. Cheong B, et al. Normal values for renal length and volume as
          measured by magnetic resonance imaging. <i>Clin J Am Soc Nephrol</i>.
          2007;2:38-45.
        </p>,
        <p>
          8. Levey AS, et al. Definition and classification of chronic kidney
          disease: a position statement from kidney disease: improving global
          outcomes (KDIGO). <i>Kidney Int</i>. 2005;67:2089-2100.
        </p>,
        <p>
          9. PKD Charity. Fast Facts about ADPKD. The Polycystic Kidney Disease
          Charity. 2017.
          https://pkdcharity.org.uk/about-adpkd/just-diagnosed/fast-facts-about-adpkd
          Accessed January 09, 2019.
        </p>,
        <p>
          10. Rangan GK, et al. Autosomal dominant polycystic kidney disease: a
          path forward. <i>Semin Nephrol</i>. 2015;35(6):524-537.
        </p>
      ],
      summary:
        "Jeremy is 29 years old and has been diagnosed with ADPKD by a kidney ultrasound. Jeremy is at risk for rapid disease progression, as indicated by the following risk factors: being male, urologic events before age 35, and family history of ESKD by age 58. Let's take a closer look with some prognostic tools to confirm the risk of rapid disease progression and assess how rapidly his disease may progress.",
      summaryRefs: "1,2",
      acronymDefinitions:
        "ADPKD=autosomal dominant polycystic kidney disease; ESKD=end stage kidney disease; AA=African American; O=other; eGFR=estimated glomerular filtration rate; PKD=polycystic kidney disease; htTKV=height-adjusted total kidney volume; MRI=magnetic resonance imaging; CT=computed tomography; CKD=chronic kidney disease.",
      hasReveal: true,
      hasPartialData: false,
      age: {
        value: 29,
        available: true
      },
      sex: {
        value: "M",
        available: true
      },
      height: {
        value: "6'1\"",
        available: true
      },
      weight: {
        value: "179",
        available: true
      },
      race: {
        value: "AA",
        available: true
      },
      mutations: {
        value: "Not available",
        available: false
      },
      serumCreatine: {
        value: "1.21",
        available: true
      },
      kidneyLength: {
        value: "Not obtained",
        available: false
      },
      eGFR: {
        value: "80.4",
        available: true
      },
      tKV: {
        value: "Perform MRI/CT",
        reveal: "810",
        available: false
      },
      proPKDScore: {
        value: "Not available",
        available: false
      },
      mayoImagingClassification: {
        value: "MRI or CT needed",
        reveal: {
          mayoImagingClassificationTitle: {
            value: "Class 1D: High Risk for eGFR decline",
            available: true
          },
          mayoImagingClassificationDescription: {
            value:
              "4.5-6% estimated yearly percentage increase in kidney growth",
            available: true
          }
        },
        available: false
      },
      estimatedAgeAtESRD: {
        available: true,
        value: "46"
      },
      history: {
        hyperBefore35: {
          value: "Not yet observed",
          available: true
        },
        urologicalBefore35: {
          value: "Yes",
          available: true
        },
        familyESRD: {
          value: "Yes (50 yrs)",
          available: true
        }
      },
      kidneyDimensions: {
        left: {
          sagittal: "TBD",
          coronal: "160 mm",
          transverse: { width: "103 mm", depth: "84 mm" }
        },
        right: {
          sagittal: "TBD",
          coronal: "170 mm",
          transverse: { width: "110 mm", depth: "79 mm" }
        }
      },
      charts: {
        tkv: {
          yAxis: {
            ticks: [0, 500, 1000, 1500, 2000],
            min: 0,
            max: 2000
          },
          data: [
            {
              year: 17,
              level: 466,
              ignorePoint: true,
              history: true
            },
            {
              year: 18,
              level: 488,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 19,
              level: 511,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 20,
              level: 535,
              ignorePoint: true,
              history: true
            },
            {
              year: 21,
              level: 560,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 22,
              level: 587,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 23,
              level: 614,
              ignorePoint: true,
              history: true
            },
            {
              year: 24,
              level: 643,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 25,
              level: 674,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 26,
              level: 705,
              ignorePoint: true,
              history: true
            },
            {
              year: 27,
              level: 739,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 28,
              level: 773,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 29,
              level: 810,
              baseline: true
            },
            {
              year: 32,
              level: 924
            },
            {
              year: 35,
              level: 1055
            },
            {
              year: 41,
              level: 1373
            },
            {
              year: 44,
              level: 1567
            },
            {
              year: 47,
              level: 1789
            }
          ]
        },
        egfr: {
          yAxis: {
            ticks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
            min: 0,
            max: 105
          },
          data: [
            {
              year: 17,
              level: 96.1,
              ignorePoint: true,
              history: true
            },
            {
              year: 18,
              level: 96,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 19,
              level: 95.9,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 20,
              level: 95.8,
              ignorePoint: true,
              history: true
            },
            {
              year: 21,
              level: 95.3,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 22,
              level: 95,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 23,
              level: 94.5,
              ignorePoint: true,
              history: true
            },
            {
              year: 24,
              level: 94,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 25,
              level: 92.5,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 26,
              level: 90.3,
              ignorePoint: true,
              history: true
            },
            {
              year: 27,
              level: 87.6,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 28,
              level: 84.1,
              ignoreTick: true,
              ignorePoint: true,
              history: true
            },
            {
              year: 29,
              level: 80.4,
              baseline: true
            },
            {
              year: 32,
              level: 71.4
            },
            {
              year: 35,
              level: 58.8
            },
            {
              year: 38,
              level: 46.2
            },
            {
              year: 41,
              level: 33.6
            },
            {
              year: 44,
              level: 20.9
            },
            {
              year: 47,
              level: 8.3
            }
          ]
        }
      }
    }
  ]
};
