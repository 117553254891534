export const isMobileApp = () => {
  return (
    window.location.href.startsWith("file://") &&
    navigator.userAgent !== "ReactSnap"
  );
};

export const getVariant = () => {
  if (!isMobileApp()) {
    return window.location.pathname
      .replace(process.env.REACT_APP_PUBLIC_URL, "")
      .split("/")
      .filter((t) => !!t)[0];
  } else {
    return window.location.hash
      .replace("#", "")
      .split("/")
      .filter((t) => !!t && t !== ".")[0];
  }
};
