import React from "react";
import PropTypes from "prop-types";
import "./Labels.css";

const labelWidth = 20;

const Label = ({ text, isDisabled }) => (
  <div
    style={{
      width: labelWidth,
      textAlign: "center",
      opacity: isDisabled ? 0.3 : 1.0
    }}
  >
    {text}
  </div>
);

class Labels extends React.PureComponent {
  render() {
    const {
      segments,
      isRevealed,
      hasPartialData,
      width = 0,
      offset = 0
    } = this.props;

    return (
      <div
        className="slider-labels"
        style={{
          width: width + labelWidth,
          left: offset - labelWidth / 2
        }}
      >
        {segments.map(seg => {
          const { year, baseline = false, history = false } = seg;

          const text = (year % 5) === 0 ? year : ''
          const isDisabled =
            history === false &&
            baseline === false &&
            (!isRevealed || hasPartialData);
          return <Label key={year} text={text} isDisabled={isDisabled} />;
        })}
      </div>
    );
  }
}

Labels.propTypes = {
  segments: PropTypes.array.isRequired,
  width: PropTypes.number,
  offset: PropTypes.number,
  isRevealed: PropTypes.bool.isRequired,
  hasPartialData: PropTypes.bool.isRequired
};

export default Labels;
