import React, { Component } from "react";
import { Page } from "../../components/Page";
import Profile from "../../components/Profiles";

import "../../css/ADPKDSimulator.scss";

export default class ADPKDSimulator extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const patient = this.props.match.params.patient || "Bill";
    // TODO add 404 redirect
    return (
      <Page
        title={`ADPKD Progression Simulation - Patient Profile ${patient}`}
        className="adpkd-simulator"
      >
        <Profile zoomFactor={1} {...this.props} />
      </Page>
    );
  }
}
