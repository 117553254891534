export default {
  animation: {
    title: "pulse",
    steps: "slideInLeft",
    data: "fadeIn",
    result: "fadeIn",
    chart: "fadeOut"
  },
  TYPING_SPEED: 300,
  month: "March",
  year: "2023",
  PRB: "US.CORP.X.23.00094",
  breakpoint: 950,
  privacy_link: "https://www.otsuka-us.com/oapi-and-opdc-privacy-policy",
  terms_link: "https://www.otsuka-us.com/terms-and-conditions",
};
