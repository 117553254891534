import React from "react";
import PropTypes from "prop-types";
import { VictoryLabel } from "victory";

const eGFRCallout = ({ text, opacity, scale }) => {
  const translate = `translate(${-18 * scale} ${-63 * scale})`;
  const tScale = `scale(${scale} ${scale})`;
  return (
    <g fill="none" transform={`${translate} ${tScale}`}>
      <polygon
        fill="#7F8184"
        points=".814 .756 97.112 .756 108.726 12.37 108.726 37.625 31.794 37.625 16.034 55.828 17.86 37.625 .814 37.625"
      />
      <polygon fill="#4E4E50" points="97.1 .757 97.1 12.37 108.664 12.37" />
      <VictoryLabel
        x={8}
        y={18}
        style={{ fontSize: 10, fill: "#FFFFFF", opacity }}
        text={text}
      />
    </g>
  );
};

eGFRCallout.propTypes = {
  text: PropTypes.string.isRequired,
  opacity: PropTypes.number.isRequired,
  scale: PropTypes.number.isRequired
};

export default eGFRCallout;
