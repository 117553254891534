import React from "react";

export default () => (
  <svg width="98px" height="98px" viewBox="0 0 98 98">
    <defs>
      <radialGradient
        cx="50%"
        cy="50%"
        fx="50%"
        fy="50%"
        r="54.0683333%"
        id="radialGradient-1"
      >
        <stop stopColor="#EEEEEE" offset="0%" />
        <stop stopColor="#B5B1B4" offset="100%" />
      </radialGradient>
    </defs>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Prog-Tools:-TKV-5" transform="translate(-905.000000, -655.000000)">
        <g id="Group" transform="translate(905.000000, 655.000000)">
          <circle
            id="Oval-Copy-9"
            fill="url(#radialGradient-1)"
            opacity="0.661504836"
            cx="49"
            cy="49"
            r="49"
          />
          <text
            id="?"
            opacity="0.4"
            fontFamily="AvenirNext-Bold, Avenir Next"
            fontSize="70"
            fontWeight="bold"
            fill="#000000"
          >
            <tspan x="30.39" y="75">
              ?
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </svg>
);
