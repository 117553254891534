import React, { Component } from "react";
import Image from "../images/accurate-diagnosis.jpg";

import "../css/AccurateDiagnosisModal.scss";

export default class AccurateDiagnosisModal extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    if (e.target.className === "diagnosis-modal") {
      this.props.handleClose();
    }
  }
  render() {
    const showModal = this.props.showModal;
    const modalContent = (
      <div className="modal-content">
        <span className="close" onClick={this.props.handleClose} />
        <img
          src={Image}
          alt="Establishing Accurate Diagnosis and Prognosis of ADPKD cases"
        />
        <div className="references">
          <strong>Reference:</strong>
          <p>
            Chebib FT, Torres VE, Assessing Risk of Rapid Progression in
            Autosomal Dominant Polycystic Kidney Disease and Special
            Considerations for Disease-Modifying Therapy, American Journal of
            Kidney Diseases (2021), doi:
            https://doi.org/10.1053/j.ajkd.2020.12.020.
          </p>
        </div>
      </div>
    );
    return (
      showModal && (
        <div className="diagnosis-modal" onClick={this.handleClick}>
          {modalContent}
        </div>
      )
    );
  }
}
