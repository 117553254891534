import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Page } from "../components/Page";
import HomePageKidney from "../images/left-kidney.png";

import ADPKDSimToolIcon from "../images/icon-adpkd-sim";
import PrognosticToolIcon from "../images/icon-prognostic-tools";

import "./Patient.scss";
// import "../css/HomePage.scss";


export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Page
        title="Autosomal Dominant Polycystic Kidney Disease (ADPKD) Simulator"
        className="patient-adpkd-homepage"
      >

        <div className="flex">
          <img className="main-image" src={HomePageKidney} alt="Kidney" />
          <div>
            <h1 className="title">
              <strong>ADPKDsim</strong> is an educational resource about autosomal dominant polycystic kidney disease (ADPKD)
            </h1>
            <p className="sub-header">
              It can help <strong>patients</strong> with ADPKD understand how their disease may <strong>change over time</strong>.
            </p>
            <div className="cards-container">
              <Link className="link-reset" to="/patient/information">
                <div className="patient-card">
                  <div className='icon'>
                    <PrognosticToolIcon />
                  </div>
                  <div className="information">
                    <div class='body'>
                      <h2>Information</h2>
                      <p> Information collected to help understand <strong>disease progression</strong> in <strong>ADPKD</strong></p>
                    </div>
                    <div className='link-to'>
                      <span className="link-text">
                        View Information
                        <span className="arrow" />
                      </span>
                    </div>

                  </div>
                </div>
              </Link>
              <Link className="link-reset" to="/patient/adpkd-simulator">
                <div className="patient-card">
                  <div class='icon'>
                    <ADPKDSimToolIcon />
                  </div>
                  <div className="information">
                    <div class='body'>
                      <h2>Patient Profiles</h2>
                      <p> Take a look at two patients who are living with <strong>ADPKD</strong></p>
                    </div>

                    <div class='link-to'>
                      <span className="link-text">
                        View profiles of patients
                        <span className="arrow" />
                      </span>
                    </div>
                  </div>

                </div>
              </Link>
            </div>
          </div>
        </div>

      </Page>
    );
  }
}
