import React, { Component } from "react";

export default class NavigatorModal extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    if (e.target.className === "navigation-modal") {
      this.props.handleClose();
    }
  }
  render() {
    const { showNavigationModal, link, handleClose } = this.props;
    return (
      showNavigationModal && (
        <div className="navigation-modal" onClick={this.handleClick}>
          <div className="modal-content">
            <span
              className="close sign-up-button"
              onClick={handleClose}
              title="Close"
            />
            <h1 className="title">You are now leaving ADPKDsim.org</h1>
            <div className="button-container">
              <button className="button cancel" onClick={handleClose}>
                Cancel
              </button>
              <a className="button confirm" href={link}>
                Confirm
              </a>
            </div>
          </div>
        </div>
      )
    );
  }
}
