import React from "react";
import { Helmet } from "react-helmet";

/**
 * Component for building a "page" of content.
 * @param {object} props
 * @param {string} props.title - Page title.
 * @param {string} props.description - Page meta description
 * @param {string} props.className - class for page-specific styles.
 * @param {any} props.children - Page content.
 */
export function Page({ description, children, title, className }) {

  const metaTags = [
    description && <meta name="description" content={description} />
  ];

  const ammendedTitle = title ? `${title} | ADPKDSim` : "ADPKDSim";

  return (
    <div className={className}>
      <Helmet>
        <title>{ammendedTitle}</title>
        {metaTags}
      </Helmet>
      {children}



    </div>
  );
}
