import React, { Component } from "react";

export default class CalculateAgeForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };

    this.handleAgeChange = this.handleAgeChange.bind(this);
    this.handleCalculateAge = this.handleCalculateAge.bind(this);
    this.validateAge = this.validateAge.bind(this);
  }
  handleAgeChange(e) {
    this.props.getAge(e);
  }
  handleCalculateAge() {
    if (!this.validateAge()) {
      this.setState({ hasError: false });
      this.props.plotGraph();
    } else {
      this.setState({ hasError: true });
    }
  }
  validateAge() {
    let ageError = false;
    if (this.props.age < 15) {
      ageError = true;
    }
    if (this.props.age > 80) {
      ageError = true;
    }
    return ageError;
  }
  render() {
    return (
      <div id="calculate-age-form">
        <div className="flex">
          <span className="steps">Enter age:</span>
          <div className="age-calculation-container">
            {this.state.hasError ? (
              <div className="error-container">
                <p className="error">Please enter a valid age.</p>
              </div>
            ) : null}
            <div className="formula-container">
              <span className="age-value">
                <input
                  placeholder="age"
                  value={this.props.age}
                  maxLength="2"
                  onChange={this.handleAgeChange}
                  onFocus={e => (e.target.placeholder = "")}
                  tabIndex={13}
                />
                &nbsp;years
              </span>
            </div>
          </div>
          <button
            className="plot-age-button"
            onClick={this.handleCalculateAge}
            tabIndex={14}
          >
            Plot on graph
          </button>
        </div>
      </div>
    );
  }
}
