import React from "react";

export default () => (
  <svg
    id="tkv-patient"
    data-name="Layer 1"
    width="98px"
    height="98px"
    viewBox="0 0 98 98"
  >
    <defs>
      <radialGradient
        id="radial-gradient"
        cx="52.79"
        cy="49.74"
        r="50.2"
        gradientTransform="translate(-2.52 0.46) scale(0.98)"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#fff" />
        <stop offset="1" />
      </radialGradient>
      <clipPath id="clip-path">
        <circle className="cls-1" cx="49" cy="49" r="49" />
      </clipPath>
    </defs>
    <title>apkd-avatar-blank</title>
    <rect
      className="cls-2"
      x="-442.48"
      y="-25.67"
      width="1220.97"
      height="149.34"
    />
    <circle className="cls-3" cx="49" cy="49" r="49" />
    <g className="cls-4">
      <path
        className="cls-5"
        d="M8.24,106.52c.07-13.28,1.28-19,3.56-24.38,2.5-5,12.94-8.47,26.05-13.32,3.54-1.31,3-10.57,1.39-12.3a26.13,26.13,0,0,1-6.81-20.27A17.09,17.09,0,0,1,47.81,17.6c.4,0,.8-.06,1.2-.07A17.09,17.09,0,0,1,65.67,35c0,.4,0,.81-.07,1.21a26.09,26.09,0,0,1-6.81,20.27c-1.57,1.73-2.15,11,1.39,12.3,13.11,4.85,23.55,8.32,26.05,13.32,2.28,5.35,3.5,11.1,3.56,24.38Z"
      />
    </g>
  </svg>
);
