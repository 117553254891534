import { round } from "lodash";
import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  VictoryArea,
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryClipContainer,
  VictoryContainer,
  VictoryLabel,
  VictoryLine,
  VictoryScatter
} from "victory";
import ExpandMag from "../../expand-icon";
import AnimatedPoint from "./AnimatedPoint";
import "./Chart.scss";
import CkdLabel from "./CkdLabel";

const yearDomainPadding = 0;

const makeAxisStyle = (scaleFactor) => ({
  ticks: {
    stroke: "grey",
    size: 5 * scaleFactor
  },
  axisLabel: {
    fontSize: 10 * scaleFactor,
    padding: 17 * scaleFactor,
    fontFamily: "Apex Sans Book"
  },
  tickLabels: {
    fontSize: 6 * scaleFactor,
    padding: 2 * scaleFactor,
    fontFamily: "Apex Sans Bold"
  }
});

const makeChartPadding = (scaleFactor) => ({
  left: 27 * scaleFactor,
  top: 10 * scaleFactor,
  right: 50 * scaleFactor,
  bottom: 50 // * scaleFactor
});

class Chart extends Component {
  roundToTenths = (val, isTkv) => {
    const rounded = `${round(val, 1)}`;
    return rounded.includes(".") ? rounded : `${rounded}.0`;
  };
  render() {
    const {
      seek,
      scaleFactor,
      isTkv,
      isRevealed,
      reverseScaleProgress = 1,
      patientData,
      currentValue,
      onClick,
      expanderVisible,
      subtitle
    } = this.props;
    const graphWidth = this.props.graphWidth * scaleFactor;
    const graphHeight = this.props.graphHeight * scaleFactor;
    const lineWidth = graphWidth - 80 * scaleFactor;
    const lineClipWidth = seek * lineWidth;
    const lineTranslateX = 29 * scaleFactor;
    const axisStyle = makeAxisStyle(scaleFactor);
    const chartPadding = makeChartPadding(scaleFactor);
    const chart = isTkv ? patientData.charts.tkv : patientData.charts.egfr;
    const data = chart.data;
    const dashes = patientData.tag === "Frank" && !isTkv ? "6,0" : "6,3";

    var isAvailable = chart.available !== false;
    isAvailable = isTkv && !isRevealed ? false : isAvailable;

    const minAge = data[0].year;
    const maxAge = data[data.length - 1].year;
    const ageRange = maxAge - minAge;
    const seekYear = seek * ageRange + minAge;
    const yAxisObject = isTkv
      ? patientData.charts.tkv.yAxis
      : patientData.charts.egfr.yAxis;
    const yAxisTicks = yAxisObject.ticks;
    const yMax = yAxisObject.max;
    const yMin = yAxisObject.min;
    const yDiff = yMax - yMin;
    const normalPercent = (yMax - 250) / yDiff;
    const yOffset = yDiff > 1500 ? -4 : 10;
    const mod = scaleFactor === 1 ? scaleFactor : scaleFactor * 1.1;
    const normalY = 195 * mod * normalPercent + yOffset * mod;

    const xAxisTicks = data
      .filter((obj) => obj.ignoreTick !== true)
      .filter((x, i) => x.year % 5 === 0);

    const points = data.filter(
      (seg) => seg.level > 0 && seg.ignorePoint !== true
    );
    var history = data.filter((seg) => seg.history === true);
    history.push(data[history.length]);
    history = history.filter((seg) => seg.level > 0);
    const progression = data.filter((seg) => seg.history !== true);

    return (
      <div
        className="chart-container"
        style={{
          opacity: isAvailable ? 1.0 : 0.5
        }}
      >
        <div className="chart-headers">
          {/* <p>
            <strong>{isTkv ? "Kidney Size" : "Kidney Function"}</strong>
          </p> */}
          <div className={`chart-main-header ${isTkv ? "tkv" : "egfr"}`}>
            <div className="chart-title">
              <strong>
                {isTkv ? (
                  <span>
                    <strong>Kidney Size</strong> (height adjusted Total Kidney
                    Volume){" "}
                  </span>
                ) : (
                  <span>
                    Kidney function <strong>(eGFR)</strong>
                  </span>
                )}
              </strong>
              {isAvailable && (
                <span className="data">
                  <strong>: </strong>
                  <span
                    style={{
                      display: "inline-block"
                    }}
                  >
                    {this.roundToTenths(currentValue)}
                  </span>
                </span>
              )}

              {isAvailable && (
                <span className="measurement">
                  {isTkv ? (
                    <span> mL/m</span>
                  ) : (
                    <span>
                      &nbsp;mL/min/1.73m
                      <sup className="squared">2</sup>
                    </span>
                  )}
                </span>
              )}
            </div>
            {expanderVisible ? (
              <div
                className="expand-icon chart-expander-button"
                title="htTKV/eGFR Chart"
                onClick={onClick}
                style={{
                  padding: 1 * reverseScaleProgress,
                  border: `${1 * reverseScaleProgress}px solid "#1e2f35"`
                }}
              >
                <ExpandMag />
              </div>
            ) : null}
          </div>
          <div className="chart-secondary-header">
            {/* <small>{subtitle}</small> */}
          </div>
        </div>
        <div className="chart-body">
          <VictoryChart
            height={graphHeight}
            width={graphWidth}
            padding={chartPadding}
            containerComponent={
              <VictoryContainer
                responsive={true}
                style={{
                  pointerEvents: "auto",
                  userSelect: "auto",
                  touchAction: "auto",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center"
                }}
              />
            } // disables responsive features for VictoryChart
            domain={{
              x: [
                data[0].year - yearDomainPadding,
                data[data.length - 1].year + yearDomainPadding
              ],
              y: [yMin, yMax]
            }}
          >
            {/* eGFR BACKGROUND GRADIENTS */}
            {/* USING GRADIENT DEFS DEFINED IN PROFILES/INDEX */}
            {!isTkv && (
              <VictoryArea
                data={this.gradientData(minAge, maxAge, 90, 120)}
                style={{ data: { fill: "url(#gradient1)" } }}
              />
            )}

            {!isTkv && (
              <VictoryArea
                data={this.gradientData(minAge, maxAge, 60, 89)}
                style={{ data: { fill: "url(#gradient2)" } }}
              />
            )}

            {!isTkv && (
              <VictoryArea
                data={this.gradientData(minAge, maxAge, 30, 59)}
                style={{ data: { fill: "url(#gradient3)" } }}
              />
            )}

            {!isTkv && (
              <VictoryArea
                data={this.gradientData(minAge, maxAge, 15, 29)}
                style={{ data: { fill: "url(#gradient4)" } }}
              />
            )}

            {!isTkv && (
              <VictoryArea
                data={this.gradientData(minAge, maxAge, 0, 14)}
                style={{ data: { fill: "url(#gradient5)" } }}
              />
            )}

            {/* CKD LABELS */}
            <CkdLabel {...this.props} text={"CKD 1"} y={30} />
            <CkdLabel {...this.props} text={"CKD 2"} y={68} />
            <CkdLabel {...this.props} text={"CKD 3"} y={119} />
            <CkdLabel {...this.props} text={"CKD 4"} y={158} />
            <CkdLabel {...this.props} text={"CKD 5/ESRD"} y={183} />

            {isTkv && (
              <VictoryArea
                data={this.gradientData(minAge, maxAge, 150, 250)}
                style={{ data: { fill: "url(#gradient6)" } }}
              />
            )}

            {isTkv && (
              <VictoryLabel
                x={100 * scaleFactor}
                y={normalY}
                style={{
                  fontSize: 7 * scaleFactor,
                  fontFamily: "Apex Sans Book"
                }}
                text="Normal htTKV range: 150-250"
              />
            )}

            {/* X AXIS */}
            <VictoryAxis
              label="Years"
              style={axisStyle}
              tickValues={xAxisTicks.map((val, index) => val.year)}
              tickFormat={(t, index) => {
                const seg = xAxisTicks[index];
                return `${seg.year}`;
              }}
            />

            {/* Y AXIS */}
            <VictoryAxis
              dependentAxis
              tickLabelComponent={
                <VictoryLabel
                  angle={-90}
                  dx={5 * scaleFactor}
                  dy={-2 * scaleFactor}
                />
              }
              tickValues={yAxisTicks}
              label={isTkv ? "mL/m" : "mL/min/1.73m²"}
              style={{
                ...axisStyle,
                axisLabel: {
                  ...axisStyle.axisLabel,
                  padding: 18 * scaleFactor
                }
              }}
            />

            {/* GRAY VERTICAL LINE */}
            {isAvailable && (
              <VictoryBar
                style={{ data: { fill: "#888888" } }}
                data={[
                  {
                    x: seekYear,
                    y0: yMin,
                    y: yMax,
                    width: 1
                  }
                ]}
                cornerRadius={1}
                alignment="middle"
              />
            )}

            {/* HISTORY LINES */}
            {isAvailable && (
              <VictoryLine
                x="year"
                y="level"
                data={history}
                groupComponent={
                  <VictoryClipContainer
                    clipWidth={lineClipWidth}
                    translateX={lineTranslateX}
                  />
                }
                style={{
                  data: {
                    stroke: "#1e2f35",
                    strokeWidth: 2 * scaleFactor,
                    strokeDasharray: dashes
                  }
                }}
              />
            )}

            {/* PROGRESSION LINES */}
            {isAvailable && (
              <VictoryLine
                x="year"
                y="level"
                data={progression}
                groupComponent={
                  <VictoryClipContainer
                    clipWidth={lineClipWidth}
                    translateX={lineTranslateX}
                  />
                }
                style={{
                  data: {
                    stroke: "#66B6CA",
                    strokeWidth: 2 * scaleFactor
                  }
                }}
              />
            )}

            {/* PRIMARY CIRCLES */}
            {isAvailable && (
              <VictoryScatter
                x="year"
                y="level"
                data={points}
                dataComponent={
                  <AnimatedPoint seekYear={seekYear} scale={scaleFactor} />
                }
              />
            )}

            {/* PRIMARY CIRCLES */}
            {isAvailable && (
              <VictoryScatter
                x="year"
                y="level"
                data={points}
                dataComponent={
                  <AnimatedPoint seekYear={seekYear} scale={scaleFactor} />
                }
              />
            )}
          </VictoryChart>
        </div>
      </div>
    );
  }

  gradientData = (minAge, maxAge, y, y0) => {
    return [
      { x: minAge - yearDomainPadding, y, y0 },
      { x: maxAge + yearDomainPadding, y, y0 }
    ];
  };

  eGFRLineStyle = () => {
    return {
      data: {
        stroke: "#AAAAAA",
        strokeWidth: 1 * this.props.scaleFactor
      }
    };
  };
}

Chart.propTypes = {
  seek: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  isTkv: PropTypes.bool.isRequired,
  isRevealed: PropTypes.bool.isRequired,
  currentValue: PropTypes.number.isRequired,
  graphWidth: PropTypes.number.isRequired,
  graphHeight: PropTypes.number.isRequired,
  scaleFactor: PropTypes.number.isRequired,
  reverseScaleProgress: PropTypes.number,
  patientData: PropTypes.object.isRequired,
  normalEgfr: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  expanderVisible: PropTypes.bool.isRequired
};

export default Chart;
