import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../../css/ToolsMenu.scss";

class HexagonMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { tools } = this.props;
    return (
      <div className="hex-container">
        {tools.map((tool, index) => (
          <Link
            to={tool.url + "/" + tool.param}
            className={`hex-cell`}
            key={index}
          >
            <img className="icon" src={tool.icon} alt={tool.name + " Icon"} />
            <p className="title">{tool.name}</p>
          </Link>
        ))}
      </div>
    );
  }
}

export default HexagonMenu;
