import React from "react";

export default () => (
  <svg width="17px" height="17px" viewBox="0 0 17 17" style={{pointerEvents: "none"}}>
    <g
      id="Symbols"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g transform="translate(-605.000000, -344.000000)" stroke="#5EC0CD">
        <g transform="translate(606.000000, 345.000000)">
          <path
            d="M8.5,8.5 L14.788245,15"
            id="Line-4"
            strokeWidth="3"
            strokeLinecap="round"
          />
          <circle
            id="Oval"
            strokeWidth="2"
            fill="transparent"
            cx="5"
            cy="5"
            r="5"
          />
        </g>
      </g>
    </g>
  </svg>
);
