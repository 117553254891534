import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Page } from "../../../components/Page";
import "./PatientInformationHome.scss";

// import "../../css/ToolsHomepage.scss";
// import ToolsMenu from "./ToolsMenu"

import tools from "../Tools";

export default class PatientInformationHomepage extends Component {
  render() {
    return (
      <Page title="Patient Information" className="prognostic-tools">
        <p className="page-crumb">Patient Information</p>
        <h1 className="title">
          Information collected to help understand Disease Progression in ADPKD
        </h1>

        <div className="information-cards">
          {tools.patient.map((tool, index) => (
            <Link
              to={tool.url + "/" + tool.param}
              key={index}
              className="information-card"
            >
              <div className="main-title-area">
                <p>{tool.mainHeading}</p>
              </div>
              <div className="icon-area">
                <img className="icon" src={tool.icon} alt={tool.name} />
              </div>
              <div className="sub-title-area">{tool.name}</div>
            </Link>
          ))}
        </div>

        <p className="dfn-block">
          <span>
            <dfn id="def-ADPKD">ADPKD</dfn> autosomal dominant polycystic kidney
            disease
          </span>
          <span style={{ padding: "0 0.5rem" }}>•</span>
          <span>
            <dfn id="def-eGFR">eGFR</dfn> estimated glomerular filtration rate
          </span>{" "}
          <span style={{ padding: "0 0.5rem" }}>•</span>
          <span>
            <dfn id="def-PKD">MRI</dfn> magnetic resonance imaging
          </span>
          <span style={{ padding: "0 0.5rem" }}>•</span>
          <span>
            <dfn id="def-PKD">CT</dfn> computed tomography
          </span>
          <span style={{ padding: "0 0.5rem" }}>•</span>
        </p>
      </Page>
    );
  }
}
