import PropTypes from "prop-types";
import React from "react";
import "./index.scss";

const totalFrameCount = 120;
const frameCut = 1; // use every nth frame (a cut of 2 will use 180 frames, every other one)
const frameCount = totalFrameCount / frameCut;

class Kidney extends React.Component {
  currentFrame_ = 65;
  prevPatient = "";
  images = [];

  state = {
    frame: 65
  };

  render() {
    /* Array of Images (preload) */
    const { patient } = this.props;
    if (patient !== this.prevPatient) {
      this.images = this.preloadImages();
      this.prevPatient = patient;
    }
    // console.debug(images.length);

    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <div className="kidney-image-container">{this.images}</div>
      </div>
    );
  }

  componentDidMount() {
    this["image65"].className = "kidney-image";
  }

  componentWillReceiveProps(nextProps) {
    const frameFloat = nextProps.seek * frameCount;
    var frame = Math.round(frameFloat);
    if (frame >= frameCount) {
      frame = frameCount - 1;
    }

    const currentImg = this[`image${this.currentFrame_}`];
    const img = this[`image${frame}`];

    if (currentImg !== undefined) {
      currentImg.className = "kidney-image hidden";
    }

    if (img !== undefined) {
      img.className = "kidney-image";
    }
    this.currentFrame_ = frame;
  }

  preloadImages = () => {
    var images = [];
    var cut = frameCut;
    const { patient } = this.props;
    for (let index = 0; index <= totalFrameCount; index++) {
      if (cut === frameCut && images.length < frameCount) {
        const frame = String(index);
        // const frame = sFrame.padStart(3, "0");
        const file = `${process.env.REACT_APP_PUBLIC_URL}/kidney-images/${patient}/${patient}-measurements-${frame}.png`;
        images.push(
          <img
            key={index}
            src={file}
            width="524"
            height="266"
            ref={(r) => (this[`image${index / frameCut}`] = r)}
            className="kidney-image hidden"
            alt=""
          />
        );
        cut = 1;
      } else {
        cut++;
      }
    }

    return images;
  };
}

Kidney.propTypes = {
  seek: PropTypes.number.isRequired,
  patient: PropTypes.string.isRequired
};

export default Kidney;
