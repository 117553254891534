import React from "react";

export default {
  left: step => (
    <svg className="left" width="53px" height="42px" viewBox="0 0 53 42">
      <defs>
        <filter
          x="-30.7%"
          y="-42.2%"
          width="161.4%"
          height="184.4%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="2.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.238054801 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-474.000000, -634.000000)">
          <g transform="translate(478.000000, 637.000000)">
            <g filter="url(#filter-1)" fill="#F5A623">
              <circle id="Oval" cx="28" cy="16" r="16" />
              <polygon
                id="Triangle"
                transform="translate(11.000000, 16.000000) rotate(-90.000000) translate(-11.000000, -16.000000) "
                points="11 5 21 27 1 27"
              />
            </g>
            <text
              fontFamily="AvenirNext-Bold, Avenir Next"
              fontSize="20"
              fontWeight="bold"
              fill="#FFFFFF"
            >
              <tspan x="21.54" y="23">
                {step}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  ),
  vert: step => (
    <svg className="vert" width="42px" height="53px" viewBox="0 0 42 53">
      <defs>
        <filter
          x="-42.2%"
          y="-28.7%"
          width="184.4%"
          height="157.4%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="2.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.238054801 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-927.000000, -1256.000000)">
          <g transform="translate(932.000000, 1255.000000)">
            <g filter="url(#filter-1)" fill="#F5A623">
              <circle id="Oval" cx="16" cy="31" r="16" />
              <polygon
                id="Triangle"
                points="15.9272461 3.70117188 25.9272461 25.7011719 5.92724609 25.7011719"
              />
            </g>
            <text
              fontFamily="AvenirNext-Bold, Avenir Next"
              fontSize="20"
              fontWeight="bold"
              fill="#FFFFFF"
            >
              <tspan x="9.54" y="38">
                {step}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  ),
  right: step => (
    <svg className="right" width="53px" height="42px" viewBox="0 0 53 42">
      <defs>
        <filter
          x="-30.7%"
          y="-42.2%"
          width="161.4%"
          height="184.4%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset
            dx="0"
            dy="2"
            in="SourceAlpha"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="2.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.238054801 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          />
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1" />
            <feMergeNode in="SourceGraphic" />
          </feMerge>
        </filter>
      </defs>
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-535.000000, -1042.000000)">
          <g transform="translate(539.000000, 1045.000000)">
            <g filter="url(#filter-1)" fill="#F5A623">
              <circle id="Oval_1_" className="st0" cx="16" cy="16" r="16" />
              <polygon
                id="Triangle_1_"
                className="st0"
                points="44,16 22,26 22,6"
              />
            </g>
            <text
              fontFamily="AvenirNext-Bold, Avenir Next"
              fontSize="20"
              fontWeight="bold"
              fill="#FFFFFF"
            >
              <tspan x="9.54" y="23">
                {step}
              </tspan>
            </text>
          </g>
        </g>
      </g>
    </svg>
  )
};
