import React from "react";
import PropTypes from "prop-types";
import "./index.scss";

const UrologicalEvents = ({ patient, onClose }) => (
  <div className="ue-modal">
    <div className="modal-content">
      <span className="close" onClick={onClose} onTouchEnd={onClose} />
      <h1 className="title-bar">Urological Events:&nbsp;{patient.tag}</h1>
      <img src={`${process.env.REACT_APP_PUBLIC_URL}/images/ue-${patient.tag}.png`} alt="UE" />
    </div>
  </div>
);

UrologicalEvents.propTypes = {
  patient: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default UrologicalEvents;
