// upper bounds for classification
// class 1E is non-inclusive lower bounds
export default {
  Class1A: {
    15: "188",
    16: "190",
    17: "193",
    18: "196",
    19: "199",
    20: "202",
    21: "205",
    22: "208",
    23: "211",
    24: "214",
    25: "218",
    26: "221",
    27: "224",
    28: "228",
    29: "231",
    30: "234",
    31: "238",
    32: "242",
    33: "245",
    34: "249",
    35: "253",
    36: "256",
    37: "260",
    38: "264",
    39: "268",
    40: "272",
    41: "276",
    42: "280",
    43: "285",
    44: "289",
    45: "293",
    46: "298",
    47: "302",
    48: "307",
    49: "311",
    50: "316",
    51: "321",
    52: "325",
    53: "330",
    54: "335",
    55: "340",
    56: "345",
    57: "350",
    58: "356",
    59: "361",
    60: "366",
    61: "372",
    62: "378",
    63: "383",
    64: "389",
    65: "395",
    66: "401",
    67: "407",
    68: "413",
    69: "419",
    70: "425",
    71: "432",
    72: "438",
    73: "445",
    74: "451",
    75: "458",
    76: "465",
    77: "472",
    78: "479",
    79: "486",
    80: "494"
  },
  Class1B: {
    15: "234",
    16: "241",
    17: "248",
    18: "255",
    19: "263",
    20: "271",
    21: "279",
    22: "287",
    23: "296",
    24: "305",
    25: "314",
    26: "323",
    27: "333",
    28: "343",
    29: "353",
    30: "364",
    31: "375",
    32: "386",
    33: "398",
    34: "410",
    35: "422",
    36: "435",
    37: "448",
    38: "461",
    39: "475",
    40: "489",
    41: "504",
    42: "519",
    43: "535",
    44: "551",
    45: "567",
    46: "584",
    47: "602",
    48: "620",
    49: "638",
    50: "658",
    51: "677",
    52: "698",
    53: "719",
    54: "740",
    55: "762",
    56: "785",
    57: "809",
    58: "833",
    59: "858",
    60: "884",
    61: "910",
    62: "938",
    63: "966",
    64: "995",
    65: "1024",
    66: "1055",
    67: "1087",
    68: "1119",
    69: "1153",
    70: "1188",
    71: "1223",
    72: "1260",
    73: "1298",
    74: "1337",
    75: "1377",
    76: "1418",
    77: "1461",
    78: "1505",
    79: "1550",
    80: "1596"
  },
  Class1C: {
    15: "290",
    16: "303",
    17: "317",
    18: "331",
    19: "346",
    20: "362",
    21: "378",
    22: "395",
    23: "413",
    24: "431",
    25: "451",
    26: "471",
    27: "492",
    28: "514",
    29: "538",
    30: "562",
    31: "587",
    32: "613",
    33: "641",
    34: "670",
    35: "700",
    36: "732",
    37: "765",
    38: "799",
    39: "835",
    40: "872",
    41: "912",
    42: "953",
    43: "996",
    44: "1040",
    45: "1087",
    46: "1136",
    47: "1187",
    48: "1241",
    49: "1297",
    50: "1355",
    51: "1416",
    52: "1480",
    53: "1546",
    54: "1616",
    55: "1688",
    56: "1764",
    57: "1844",
    58: "1927",
    59: "2014",
    60: "2104",
    61: "2199",
    62: "2298",
    63: "2401",
    64: "2509",
    65: "2622",
    66: "2740",
    67: "2863",
    68: "2992",
    69: "3127",
    70: "3268",
    71: "3415",
    72: "3568",
    73: "3729",
    74: "3897",
    75: "4072",
    76: "4255",
    77: "4447",
    78: "4647",
    79: "4856",
    80: "5075"
  },
  Class1D: {
    15: "359",
    16: "381",
    17: "404",
    18: "428",
    19: "454",
    20: "481",
    21: "510",
    22: "541",
    23: "573",
    24: "607",
    25: "644",
    26: "682",
    27: "723",
    28: "767",
    29: "813",
    30: "862",
    31: "913",
    32: "968",
    33: "1026",
    34: "1088",
    35: "1153",
    36: "1222",
    37: "1295",
    38: "1373",
    39: "1456",
    40: "1543",
    41: "1635",
    42: "1734",
    43: "1838",
    44: "1948",
    45: "2065",
    46: "2189",
    47: "2320",
    48: "2459",
    49: "2607",
    50: "2763",
    51: "2929",
    52: "3105",
    53: "3291",
    54: "3488",
    55: "3698",
    56: "3919",
    57: "4155",
    58: "4404",
    59: "4668",
    60: "4948",
    61: "5245",
    62: "5560",
    63: "5893",
    64: "6247",
    65: "6622",
    66: "7019",
    67: "7440",
    68: "7887",
    69: "8360",
    70: "8861",
    71: "9393",
    72: "9957",
    73: "10554",
    74: "11187",
    75: "11859",
    76: "12570",
    77: "13324",
    78: "14124",
    79: "14971",
    80: "15869"
  },
  Class1E: {
    15: "359",
    16: "381",
    17: "404",
    18: "428",
    19: "454",
    20: "481",
    21: "510",
    22: "541",
    23: "573",
    24: "607",
    25: "644",
    26: "682",
    27: "723",
    28: "767",
    29: "813",
    30: "862",
    31: "913",
    32: "968",
    33: "1026",
    34: "1088",
    35: "1153",
    36: "1222",
    37: "1295",
    38: "1373",
    39: "1456",
    40: "1543",
    41: "1635",
    42: "1734",
    43: "1838",
    44: "1948",
    45: "2065",
    46: "2189",
    47: "2320",
    48: "2459",
    49: "2607",
    50: "2763",
    51: "2929",
    52: "3105",
    53: "3291",
    54: "3488",
    55: "3698",
    56: "3919",
    57: "4155",
    58: "4404",
    59: "4668",
    60: "4948",
    61: "5245",
    62: "5560",
    63: "5893",
    64: "6247",
    65: "6622",
    66: "7019",
    67: "7440",
    68: "7887",
    69: "8360",
    70: "8861",
    71: "9393",
    72: "9957",
    73: "10554",
    74: "11187",
    75: "11859",
    76: "12570",
    77: "13324",
    78: "14124",
    79: "14971",
    80: "15869"
  }
};
