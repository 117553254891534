import PropTypes from "prop-types";
import React, { Component } from "react";
import Modal from "../Modal";
import ImagingChart from "./ImagingChart";
import MRIScans from "./MRIScans";
import ProfileTour from "./ProfileTour";
import UrologicalEvents from "./UrologicalEvents";
import ExpandMag from "./expand-icon";

import "./Baseline.scss";

const patientReferences = {
  Bill: "3-7",
  Angela: "3-7",
  Denise: "3-8",
  Frank: "2-7",
  Jeremy: "2-6",
  Marcus: "3-7",
  Jennifer: "3-8"
};

class Baseline extends Component {
  state = {
    IsUEOpen: false,
    IsICOpen: false,
    IsMRIOpen: false
  };

  render() {
    const {
      patient,
      variant,
      isRevealed,
      currentTourStepOpen,
      onReveal,
      onUnReveal,
      onTourStep,
      onTourClose
    } = this.props;
    const isAngela = patient.tag === "Angela";
    const isDeniseJeremy = patient.tag === "Denise" || patient.tag === "Jeremy";
    return this.props.variant === "expert" ? (
      <div className="baseline">
        <Modal isShowing={this.state.IsUEOpen} onHide={this.handleHistoryClose}>
          <UrologicalEvents
            patient={patient}
            onClose={this.handleHistoryClose}
          />
        </Modal>
        {!isAngela ? (
          <Modal isShowing={this.state.IsICOpen} onHide={this.handleChartClose}>
            <ImagingChart patient={patient} onClose={this.handleChartClose} />
          </Modal>
        ) : null}
        {!isAngela ? (
          <MRIScans
            patient={patient}
            isShowing={this.state.IsMRIOpen}
            onClose={this.handleMRIClose}
          />
        ) : null}

        <h3>
          <span className="header">
            Baseline Assessment<sup>{patientReferences[patient.tag]}</sup>
          </span>
        </h3>
        <ul>
          <li>
            <span className="cKey">Serum creatinine (mg/dL)</span>
            <span className={this.makeValClass(patient.serumCreatine)}>
              {this.getVal(patient.serumCreatine)}
            </span>
          </li>
          <li>
            <span className="cKey">
              eGFR (mL/min/1.73m
              <sup className="squared">2</sup>)
            </span>
            <span
              className={`${
                patient.tag === "Bill" ? "step-bill" : ""
              } ${this.makeValClass(patient.eGFR)}`}
            >
              {this.getVal(patient.eGFR)}
              {/** Tour Step 1 */}
              <ProfileTour
                variant={this.props.variant}
                stepNumber={1}
                name={patient.tag}
                direction={"left"}
                currentTourStepOpen={currentTourStepOpen}
                onTourStep={onTourStep}
                onTourClose={onTourClose}
              />
            </span>
          </li>
          <li>
            <span className="cKey">Ultrasound kidney length (cm)</span>
            <span
              className={`step-angise ${this.makeValClass(
                patient.kidneyLength
              )}`}
            >
              {this.getVal(patient.kidneyLength)}
              {/** Tour Step 3 {Angela, Denise} */}
              {isAngela || patient.tag === "Denise" ? (
                <ProfileTour
                  variant={this.props.variant}
                  stepNumber={3}
                  name={patient.tag}
                  direction={"left"}
                  currentTourStepOpen={currentTourStepOpen}
                  onTourStep={onTourStep}
                  onTourClose={onTourClose}
                  nextScrollSelector={
                    isAngela ? ".kidney-image-container" : false
                  }
                />
              ) : null}
            </span>
          </li>
          <li>
            <span className="cKey">Hypertension before 35?</span>
            <span className={this.makeValClass(patient.history.hyperBefore35)}>
              {this.getVal(patient.history.hyperBefore35)}
            </span>
          </li>
          <li>
            <span className="cKey">Urological event before 35?</span>
            <span
              className={this.makeValClass(patient.history.urologicalBefore35)}
            >
              {this.getVal(patient.history.urologicalBefore35)}
              <span
                className="expand-icon"
                title={`Urological Events`}
                onClick={this.handleHistoryOpen}
              >
                <ExpandMag />
              </span>
            </span>
          </li>
          <li>
            <span className="cKey">Family members with ESKD?</span>
            <span
              className={`step-jeremy ${this.makeValClass(
                patient.history.familyESRD
              )}`}
            >
              {this.getVal(patient.history.familyESRD)}
              {/** Tour Step 3 {Jeremy} */}
              {patient.tag === "Jeremy" ? (
                <ProfileTour
                  variant={this.props.variant}
                  stepNumber={3}
                  name={patient.tag}
                  direction={"left"}
                  currentTourStepOpen={currentTourStepOpen}
                  onTourStep={onTourStep}
                  onTourClose={onTourClose}
                />
              ) : null}
            </span>
          </li>
          <li>
            <span className="cKey">Mutations</span>
            <span className={this.makeValClass(patient.mutations)}>
              {this.getVal(patient.mutations)}
              {/** Tour Step 3 {Bill} */}
              {patient.tag === "Bill" ? (
                <ProfileTour
                  variant={this.props.variant}
                  stepNumber={3}
                  name={patient.tag}
                  direction={"left"}
                  currentTourStepOpen={currentTourStepOpen}
                  onTourStep={onTourStep}
                  onTourClose={onTourClose}
                />
              ) : null}
            </span>
          </li>
          <li>
            <span className="cKey">PROPKD Score</span>
            <span className={this.makeValClass(patient.proPKDScore)}>
              {this.getVal(patient.proPKDScore)}
              {/** Tour Step 4 {Bill} */}
              {patient.tag === "Bill" ? (
                <ProfileTour
                  variant={this.props.variant}
                  stepNumber={4}
                  name={patient.tag}
                  direction={"left"}
                  currentTourStepOpen={currentTourStepOpen}
                  onTourStep={onTourStep}
                  onTourClose={onTourClose}
                />
              ) : null}
            </span>
          </li>
          <li>
            <span className="cKey">htTKV (mL/m)</span>
            <span className={`step-frill ${this.makeValClass(patient.tKV)}`}>
              {isDeniseJeremy && !isRevealed ? (
                <span
                  onTouchEnd={onReveal}
                  onClick={onReveal}
                  className="revealer"
                >
                  {this.getVal(patient.tKV)}
                </span>
              ) : (
                this.getVal(patient.tKV)
              )}
              {/** Tour Step 3 {Frank} */}
              {patient.tag === "Frank" ? (
                <ProfileTour
                  variant={this.props.variant}
                  stepNumber={3}
                  name={patient.tag}
                  direction={"left"}
                  currentTourStepOpen={currentTourStepOpen}
                  onTourStep={onTourStep}
                  onTourClose={onTourClose}
                  nextScrollSelector=".alternate-axis"
                />
              ) : null}
              {/** Tour Step 5 {Bill} */}
              {patient.tag === "Bill" ? (
                <ProfileTour
                  variant={this.props.variant}
                  stepNumber={5}
                  name={patient.tag}
                  direction={"left"}
                  currentTourStepOpen={currentTourStepOpen}
                  onTourStep={onTourStep}
                  onTourClose={onTourClose}
                  nextScrollSelector=".alternate-axis"
                />
              ) : null}
            </span>
          </li>
          <li>
            <span className="cKey">Other risk factors</span>
            <span
              className={`step-frill ${this.makeValClass(
                patient.otherRiskFactors
              )}`}
            >
              {patient.otherRiskFactors
                ? this.getVal(patient.otherRiskFactors)
                : "None"}
            </span>
          </li>
          <li
            className={`alternate-axis${
              isAngela || !isRevealed ? "" : " revealed"
            }`}
          >
            <span className="cKey">
              ADPKD Imaging Classification
              {isAngela || !isRevealed ? null : (
                <span
                  className="expand-icon"
                  title={`Imaging Classification`}
                  onClick={this.handleChartOpen}
                >
                  <ExpandMag />
                </span>
              )}
              {/** Tour Step 4 {!Angela} 5 {Denise, Jeremy} */}
              {isDeniseJeremy && isRevealed ? (
                <ProfileTour
                  variant={this.props.variant}
                  stepNumber={5}
                  name={patient.tag}
                  direction={"left"}
                  currentTourStepOpen={currentTourStepOpen}
                  onTourStep={onTourStep}
                  onTourClose={onTourClose}
                  scrollSelector=".alternate-axis"
                  nextScrollSelector=".kidney-image-container"
                />
              ) : patient.tag === "Bill" ? (
                <ProfileTour
                  variant={this.props.variant}
                  stepNumber={6}
                  name={patient.tag}
                  direction={"left"}
                  currentTourStepOpen={currentTourStepOpen}
                  onTourStep={onTourStep}
                  onTourClose={onTourClose}
                  scrollSelector=".alternate-axis"
                  nextScrollSelector=".kidney-image-container"
                />
              ) : patient.tag === "Frank" ? (
                <ProfileTour
                  variant={this.props.variant}
                  stepNumber={4}
                  name={patient.tag}
                  direction={"left"}
                  currentTourStepOpen={currentTourStepOpen}
                  onTourStep={onTourStep}
                  onTourClose={onTourClose}
                  scrollSelector=".alternate-axis"
                  nextScrollSelector=".kidney-image-container"
                />
              ) : null}
            </span>
            {isAngela ? (
              <span className="cVal">
                Angela does not need an MRI or CT scan since she is likely at
                low risk for rapid progression, as confirmed by her ultrasound
                kidney length and having only one risk factor.
                <br />
                <br />
                Although Angela may not be at risk for ADPKD progression, she
                needs to be checked for other kidney disease due to history of
                UTI.
              </span>
            ) : (
              <span
                className={this.makeValClass(patient.mayoImagingClassification)}
              >
                {isRevealed ? (
                  <span>
                    <h3 className="classification">
                      {
                        this.getVal(patient.mayoImagingClassification)
                          .mayoImagingClassificationTitle.value
                      }
                    </h3>
                    <p className="description">
                      {
                        this.getVal(patient.mayoImagingClassification)
                          .mayoImagingClassificationDescription.value
                      }
                    </p>
                    <p className="estimated-age">
                      <span>ESTIMATED AGE AT ESKD</span>
                      <span>{this.getVal(patient.estimatedAgeAtESRD)}</span>
                    </p>
                    <span className="cKey">
                      MRI/CT SCANS
                      <span
                        className="expand-icon"
                        onClick={this.handleMRIOpen}
                        title={`MRI Scans and Measurements`}
                      >
                        <ExpandMag />
                      </span>
                    </span>
                    <span className="mri-scan-container">
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}/images/${patient.tag}-coronal.png`}
                        alt="Coronal View"
                      />
                      <img
                        src={`${process.env.REACT_APP_PUBLIC_URL}/images/${patient.tag}-transverse.png`}
                        alt="Transverse View"
                      />
                    </span>
                  </span>
                ) : patient.tag === "Denise" ? (
                  <>
                    <p>
                      Denise does not need an MRI or CT scan to confirm her risk
                      for rapid progression, but it would provide more
                      information.
                    </p>
                    <span className="cKey">
                      {this.getVal(patient.mayoImagingClassification)}
                    </span>
                  </>
                ) : (
                  this.getVal(patient.mayoImagingClassification)
                )}
                {/* * Tour Step 4 {!Angela} 5 {Denise, Jeremy}
                {isDeniseJeremy && isRevealed ? (
                  <ProfileTour
                    variant={this.props.variant}
                    stepNumber={5}
                    name={patient.tag}
                    direction={"left"}
                    currentTourStepOpen={currentTourStepOpen}
                    onTourStep={onTourStep}
                    onTourClose={onTourClose}
                    scrollSelector=".alternate-axis"
                    nextScrollSelector=".kidney-image-container"
                  />
                ) : patient.tag === "Bill" ? (
                  <ProfileTour
                    variant={this.props.variant}
                    stepNumber={6}
                    name={patient.tag}
                    direction={"left"}
                    currentTourStepOpen={currentTourStepOpen}
                    onTourStep={onTourStep}
                    onTourClose={onTourClose}
                    scrollSelector=".alternate-axis"
                    nextScrollSelector=".kidney-image-container"
                  />
                ) : patient.tag === "Frank" ? (
                  <ProfileTour
                    variant={this.props.variant}
                    stepNumber={4}
                    name={patient.tag}
                    direction={"left"}
                    currentTourStepOpen={currentTourStepOpen}
                    onTourStep={onTourStep}
                    onTourClose={onTourClose}
                    scrollSelector=".alternate-axis"
                    nextScrollSelector=".kidney-image-container"
                  />
                ) : null} */}
              </span>
            )}
            {!isRevealed && (
              <div className="revealer-container">
                <button
                  onTouchEnd={onReveal}
                  onClick={onReveal}
                  className="revealer"
                >
                  Perform MRI/CT
                </button>
                {/** Tour Step 4 {Denise, Jeremy} */}
                <ProfileTour
                  variant={this.props.variant}
                  stepNumber={4}
                  name={patient.tag}
                  direction={"left"}
                  currentTourStepOpen={currentTourStepOpen}
                  onTourStep={onTourStep}
                  onTourClose={onTourClose}
                  revealOnNext={true}
                  nextScrollSelector=".alternate-axis"
                />
              </div>
            )}
          </li>
          {isDeniseJeremy && isRevealed ? (
            <span
              className="reset-link"
              onTouchEnd={onUnReveal}
              onClick={onUnReveal}
            >
              Reset without MRI/CT scans
            </span>
          ) : null}
        </ul>
      </div>
    ) : this.props.variant === "patient" ? (
      <div className="baseline">
        <Modal isShowing={this.state.IsUEOpen} onHide={this.handleHistoryClose}>
          <UrologicalEvents
            patient={patient}
            onClose={this.handleHistoryClose}
          />
        </Modal>

        <Modal isShowing={this.state.IsICOpen} onHide={this.handleChartClose}>
          <ImagingChart patient={patient} onClose={this.handleChartClose} />
        </Modal>

        <MRIScans
          patient={patient}
          isShowing={this.state.IsMRIOpen}
          onClose={this.handleMRIClose}
        />
        <h3>
          <span className="header">Lab Tests</span>
        </h3>
        <table className="adpkd-patient-overview">
          <thead>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col">Standard Range</th>
          </thead>
          <tbody>
            {Object.entries(patient.labTests).map(([k, v]) => {
              const Callout = () => {
                return v.callout.tag === "some" ? (
                  <ProfileTour
                    variant={this.props.variant}
                    stepNumber={v.callout.value.stepNumber}
                    name={patient.tag}
                    direction={"left"}
                    currentTourStepOpen={currentTourStepOpen}
                    onTourStep={onTourStep}
                    onTourClose={onTourClose}
                  />
                ) : null;
              };

              return (
                <tr>
                  <th scope="row">
                    {k}
                    <p>
                      <small>{!!v.unit ? `(${v.unit})` : ""}</small>
                    </p>
                  </th>
                  <td>
                    {!!v.comment ? (
                      <>
                        <p>{v.value}</p>
                        <p>
                          <small>({v.comment})</small>
                        </p>
                      </>
                    ) : (
                      `${v.value}`
                    )}
                  </td>
                  <td>{v.standardRange}</td>
                  <td>
                    <Callout />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <h3>
          <span className="header">Imaging Tests</span>
        </h3>
        <table className="adpkd-patient-overview">
          <thead>
            <th scope="col"></th>
            <th scope="col"></th>
            <th scope="col">Standard Range</th>
          </thead>
          <tbody>
            {Object.entries(patient.imagingTests).map(([k, v]) => {
              const Callout = () => {
                return v.callout.tag === "some" ? (
                  <ProfileTour
                    variant={this.props.variant}
                    stepNumber={v.callout.value.stepNumber}
                    name={patient.tag}
                    direction={"right"}
                    currentTourStepOpen={currentTourStepOpen}
                    onTourStep={onTourStep}
                    onTourClose={onTourClose}
                  />
                ) : null;
              };

              return (
                <tr>
                  <th scope="row">
                    {k}
                    <p>
                      <small>{!!v.unit ? `(${v.unit})` : ""}</small>
                    </p>
                  </th>
                  <td>
                    {!!v.comment ? (
                      <>
                        <p>{v.value}</p>
                        <p>
                          <small>({v.comment})</small>
                        </p>
                      </>
                    ) : (
                      `${v.value}`
                    )}
                  </td>
                  <td>{v.standardRange}</td>
                  <td>
                    <Callout />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <div>
          <ul>
            <li className={"alternate-axis revealed"}>
              <span className="cKey">
                ADPKD Imaging Classification
                {!isRevealed ? null : (
                  <span
                    className="expand-icon"
                    title={`Imaging Classification`}
                    onClick={this.handleChartOpen}
                  >
                    <ExpandMag />
                  </span>
                )}
              </span>

              <span
                className={this.makeValClass(patient.mayoImagingClassification)}
              >
                <span>
                  <p className="estimated-age">
                    {this.props.variant === "expert" ? (
                      <span>ESTIMATED AGE AT ESKD</span>
                    ) : (
                      <span>ESTIMATED AGE AT KIDNEY FAILURE</span>
                    )}

                    <span>{this.getVal(patient.estimatedAgeAtESRD)}</span>
                  </p>
                  <span className="cKey">
                    MRI/CT SCANS
                    <span
                      className="expand-icon"
                      onClick={this.handleMRIOpen}
                      title={`MRI Scans and Measurements`}
                    >
                      <ExpandMag />
                    </span>
                  </span>
                  <span className="mri-scan-container">
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}/images/${patient.tag}-coronal.png`}
                      alt="Coronal View"
                    />
                    <img
                      src={`${process.env.REACT_APP_PUBLIC_URL}/images/${patient.tag}-transverse.png`}
                      alt="Transverse View"
                    />
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </div>
      </div>
    ) : null;
  }

  makeValClass = (entry) => {
    if (!entry || entry.value === undefined || entry.value === null) {
      return "cVal unavailable";
    } else if (entry.available === false) {
      if (entry.reveal !== undefined && this.props.isRevealed === true) {
        return "cVal";
      } else {
        return "cVal unavailable";
      }
    } else {
      return "cVal";
    }
  };

  getVal = (entry) => {
    if (this.props.isRevealed === true && entry.reveal !== undefined) {
      return entry.reveal;
    } else {
      if (entry.value === "Truncating PKD1") {
        return (
          <span>
            Truncating <i>PKD1</i>
          </span>
        );
      } else if (typeof entry.value !== "object") {
        if (entry.value.indexOf("yrs") > 0) {
          let splitEntry = entry.value.split(" ");
          return (
            <span>
              {splitEntry.shift()}&nbsp;<span>{splitEntry.join(" ")}</span>
            </span>
          );
        } else {
          return entry.value;
        }
      } else {
        return entry.value;
      }
    }
  };

  handleHistoryOpen = () => {
    this.setState({
      IsUEOpen: true
    });
  };
  handleHistoryClose = () => {
    this.setState({
      IsUEOpen: false
    });
  };

  handleChartOpen = () => {
    this.setState({
      IsICOpen: true
    });
  };
  handleChartClose = () => {
    this.setState({
      IsICOpen: false
    });
  };

  handleMRIOpen = () => {
    this.setState({
      IsMRIOpen: true
    });
  };
  handleMRIClose = () => {
    this.setState({
      IsMRIOpen: false
    });
  };
}

Baseline.propTypes = {
  patient: PropTypes.object.isRequired,
  isRevealed: PropTypes.bool.isRequired,
  currentTourStepOpen: PropTypes.number.isRequired,
  onReveal: PropTypes.func,
  onUnReveal: PropTypes.func,
  onTourStep: PropTypes.func.isRequired,
  onTourClose: PropTypes.func.isRequired
};

export default Baseline;
