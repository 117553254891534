import React, { Component } from "react";
import leftKidney from "../images/kidney-measure-left.png";

import "animate.css/animate.min.css";

export default class KidneyDimensionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lklError: false,
      lkwError: false,
      lkdError: false,
      rklError: false,
      rkwError: false,
      rkdError: false
    };

    this.handleKidneyDimensionChange = this.handleKidneyDimensionChange.bind(
      this
    );
    this.validateKidneyValues = this.validateKidneyValues.bind(this);
    this.handleCalculateTKV = this.handleCalculateTKV.bind(this);
  }
  handleKidneyDimensionChange(e) {
    this.props.kidneyDimensionChange(e);
  }
  handleCalculateTKV() {
    if (!this.validateKidneyValues()) {
      this.props.calculateTKV();
      this.props.onGoToNextStep();
    }
  }
  validateKidneyValues() {
    let lklError = false,
      lkwError = false,
      lkdError = false,
      rklError = false,
      rkwError = false,
      rkdError = false;

    if (
      Number(this.props.leftKidneyLength) < 90 ||
      Number(this.props.leftKidneyLength) > 600
    ) {
      lklError = true;
    }
    if (
      Number(this.props.leftKidneyWidth) < 45 ||
      Number(this.props.leftKidneyWidth) > 460
    ) {
      lkwError = true;
    }
    if (
      Number(this.props.leftKidneyDepth) < 25 ||
      Number(this.props.leftKidneyDepth) > 460
    ) {
      lkdError = true;
    }

    if (
      Number(this.props.rightKidneyLength) < 90 ||
      Number(this.props.rightKidneyLength) > 600
    ) {
      rklError = true;
    }
    if (
      Number(this.props.rightKidneyWidth) < 45 ||
      Number(this.props.rightKidneyWidth) > 460
    ) {
      rkwError = true;
    }
    if (
      Number(this.props.rightKidneyDepth) < 25 ||
      Number(this.props.rightKidneyDepth) > 460
    ) {
      rkdError = true;
    }

    this.setState({
      lklError,
      lkwError,
      lkdError,
      rklError,
      rkwError,
      rkdError
    });
    return lklError || lkwError || lkdError || rklError || rkwError || rkdError;
  }
  render() {
    const hasError =
      this.state.lklError ||
      this.state.lkwError ||
      this.state.lkdError ||
      this.state.rklError ||
      this.state.rkwError ||
      this.state.rkdError;
    return (
      <div id="kidney-dimensions-form">
        <div className="flex row">
          <div className="form-container">
            <div className="prompt">Enter kidney dimensions:</div>
            {hasError ? (
              <div className="error-container">
                <p className="error description">
                  One or more of these measurements are either invalid or
                  outside the accepted range for this application. Are you sure
                  you’re using millimeters?
                </p>
              </div>
            ) : null}
            <div className="flex">
              <div className="left-kidney-dimensions">
                <label>Left Kidney</label>
                <div
                  className={`left input-container${
                    this.state.lklError ? " hasError" : ""
                  }`}
                >
                  <input
                    name="left Kidney Length"
                    value={this.props.leftKidneyLength}
                    maxLength="3"
                    onChange={this.handleKidneyDimensionChange}
                  />
                </div>
                <div
                  className={`left input-container${
                    this.state.lkwError ? " hasError" : ""
                  }`}
                >
                  <input
                    name="left Kidney Width"
                    value={this.props.leftKidneyWidth}
                    maxLength="3"
                    onChange={this.handleKidneyDimensionChange}
                  />
                </div>
                <div
                  className={`left input-container${
                    this.state.lkdError ? " hasError" : ""
                  }`}
                >
                  <input
                    name="left Kidney Depth"
                    value={this.props.leftKidneyDepth}
                    maxLength="3"
                    onChange={this.handleKidneyDimensionChange}
                  />
                </div>
              </div>
              <div className="label-container">
                <label>Length*&nbsp;(mm)</label>
                <label>
                  Width<sup>&#8224;</sup>&nbsp;(mm)
                </label>
                <label>
                  Depth<sup>&#8224;</sup>&nbsp;(mm)
                </label>
              </div>
              <div className="right-kidney-dimensions">
                <label>Right Kidney</label>
                <div
                  className={`right input-container${
                    this.state.rklError ? " hasError" : ""
                  }`}
                >
                  <input
                    name="right Kidney Length"
                    value={this.props.rightKidneyLength}
                    maxLength="3"
                    onChange={this.handleKidneyDimensionChange}
                  />
                </div>
                <div
                  className={`right input-container${
                    this.state.rkwError ? " hasError" : ""
                  }`}
                >
                  <input
                    name="right Kidney Width"
                    value={this.props.rightKidneyWidth}
                    maxLength="3"
                    onChange={this.handleKidneyDimensionChange}
                  />
                </div>
                <div
                  className={`right input-container${
                    this.state.rkdError ? " hasError" : ""
                  }`}
                >
                  <input
                    name="right Kidney Depth"
                    value={this.props.rightKidneyDepth}
                    maxLength="3"
                    onChange={this.handleKidneyDimensionChange}
                  />
                </div>
              </div>
            </div>
            {this.props.patient === "user" ? (
              <button
                className="calculate-tkv-button"
                onClick={this.handleCalculateTKV}
              >
                Calculate TKV
              </button>
            ) : null}
          </div>
          <img src={leftKidney} alt="Left Kidney" />
        </div>
      </div>
    );
  }
}
