import React, { Component } from "react";

export default class MobileAlgorithmFlowchart extends Component {
  render() {
    return (
      <div className="algorithm-flowchart">
        <div className="card">
          <div className="header">eGFR indexed for age</div>
          <div className="data">
            <p>CKD stage by age:</p>
            <p>
              at age 18-30 yr: CKD 1-3a (eGFR > 45 mL/min/1.73 m<sup>2</sup>)
            </p>
            <p>
              at age 30-40 yr: CKD 2-3a (eGFR 45-90 mL/min/1.73 m<sup>2</sup>)
            </p>
            <p>
              at age 40-50 yr: CKD 3a (eGFR 45-60 mL/min/1.73 m<sup>2</sup>)
            </p>
          </div>
        </div>
        <div className="flow-container">
          <div className="stop green">No: Likely slow progression</div>
          <div className="flow">
            Yes: Proceed to next step
            <span className="arrow" />
          </div>
        </div>
        <div className="card">
          <div className="header">Rapid historical eGFR decline</div>
          <div className="data">
            <p>
              Historical eGFR decline, with no other confounding cause than
              ADPKD:
            </p>
            <p>
              1) confirmed eGFR decline <span className="symbol">&ge;</span> 5
              mL/min/1.73 m<sup>2</sup> in one year and/or
            </p>
            <p>
              2) confirmed eGFR decline <span className="symbol">&ge;</span> 2.5
              mL/min/1.73 m<sup>2</sup> per year over a period of five years or
              more?
            </p>
          </div>
        </div>
        <div className="flow-container">
          <div className="stop green">No: Likely slow progression</div>
          <div className="flow">
            Data not available or not reliable
            <br />
            (e.g. in CKD 1)
            <span className="arrow" />
          </div>
          <div className="stop red">Yes: Rapid progression</div>
        </div>
        <div className="card">
          <div className="header">Rapid historical eGFR decline</div>
          <div className="data">
            <p>Historical kidney growth in typical ADKPD:</p>
            <p>
              (ht)TKV lncrease more than 5 % per year by repeated measurements (
              <span className="symbol">&ge;</span> 3)?
            </p>
            <p>
              Preferable by MRI (ellipsoid equation), if not available then by
              another reliable method (CT)
            </p>
          </div>
        </div>
        <div className="flow-container">
          <div className="stop green">No: Likely slow progression</div>
          <div className="flow">
            Data not available or not reliable
            <span className="arrow" />
          </div>
          <div className="stop red">Yes: Rapid progression</div>
        </div>
        <div className="card">
          <div className="header">
            Predicted rapid progression by ADPKD Imaging Class or PROPKD
          </div>
          <div className="data">
            <p>
              Predicted progression by baseline htTKV indexed for age and/or
              genotype:
            </p>
            <p>
              1) htTKV compatible with ADPKD Imaging Class 1C, 1D, 1E or US
              length &gt; 16.5 cm and/or
            </p>
            <p>
              2) truncating PKD1 mutation &#43; early symptoms (i.e. a PROPKD
              score &gt; 6)?
            </p>
          </div>
        </div>
        <div className="flow-container">
          <div className="stop green">No: Likely slow progression</div>
          <div className="flow">
            Data not available or not reliable
            <span className="arrow" />
          </div>
          <div className="stop orange">Yes: Likely rapid progression</div>
        </div>
        <div className="card">
          <div className="header">
            Predicted rapid progression by ADPKD Imaging Class or PROPKD
          </div>
          <div className="data">
            <p>
              Predicted progression by baseline htTKV indexed for age and/or
              genotype:
            </p>
            <p>
              1) htTKV compatible with ADPKD Imaging Class 1C, 1D, 1E or US
              length &gt; 16.5 cm and/or
            </p>
            <p>
              2) truncating PKD1 mutation &#43; early symptoms (i.e. a PROPKD
              score &gt; 6)?
            </p>
          </div>
        </div>
        <div className="flow-container">
          <div className="stop green">No: Likely slow progression</div>
          <div className="stop yellow">Yes: Possible rapid progression</div>
        </div>
      </div>
    );
  }
}
