import React, { Component, createRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";

import diseaseProgressionPdf from "./pdfs-base64/disease-progression.json";
import gfrAndTkvnPdf from "./pdfs-base64/gfr-and-tkv.json";
import imagingMethodologynPdf from "./pdfs-base64/imaging-methodology.json";
import propkdScorePdf from "./pdfs-base64/propkd-score.json";

import workerSrc from "pdfjs-dist/legacy/build/pdf.worker.entry";
pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

const base64pdfs = {
  "/pdfs/disease-progression.pdf": diseaseProgressionPdf,
  "/pdfs/gfr-and-tkv.pdf": gfrAndTkvnPdf,
  "/pdfs/propkd-score.pdf": propkdScorePdf,
  "/pdfs/imaging-methodology.pdf": imagingMethodologynPdf
};

export default class pdfModal extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = createRef(null);
    this.state = {
      loading: true,
      pages: 0
    };
  }

  render() {
    const { isShowing, pdf, onClose, onLoaded } = this.props;
    const modalContent = (
      <div className="modal-content">
        <span className="close" onClick={onClose} onTouchEnd={onClose} />
        <div
          style={{ maxHeight: "80vh", overflowY: "auto", overflowX: "hidden" }}
        >
          {this.state.loading && (
            <div className="pdf-loading">
              <p>Loading...</p>
            </div>
          )}

          <Document
            file={`data:application/pdf;base64,${base64pdfs[pdf].data}`}
            onLoadSuccess={({ numPages }) => {
              this.setState({ pages: numPages, loading: false });
              onLoaded();
            }}
          >
            {Array.apply(null, Array(this.state.pages))
              .map((x, i) => i + 1)
              .map((page) => (
                <Page
                  key={page}
                  pageNumber={page}
                  scale={2}
                  className={"pdf-page"}
                />
              ))}
          </Document>
        </div>
      </div>
    );
    return isShowing && <div className="video-modal">{modalContent}</div>;
  }
}
