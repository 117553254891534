import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getVariant, isMobileApp } from "../helpers/compat";
import ADPKDIconSVG from "../images/adpkd-icon.svg";
import SignupModal from "./SignupModal";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      modalReason: undefined,
      openNav: false
    };

    this.handleNavClick = this.handleNavClick.bind(this);
  }
  handleNavClick() {
    // if (this.state.openNav) {
    //   document
    //     .getElementById("adpkd-app")
    //     .removeEventListener("click", this.handleNavClick, {
    //       capture: true
    //     });
    // } else {
    //   document
    //     .getElementById("adpkd-app")
    //     .addEventListener("click", this.handleNavClick, {
    //       capture: true
    //     });
    // }
    this.setState({ openNav: !this.state.openNav });
  }
  render() {
    const currentLocation = isMobileApp()
      ? window.location.hash
      : window.location.pathname;
    const tokenIndex = isMobileApp() ? 2 : 1;
    const activeLink =
      currentLocation.length > tokenIndex ? currentLocation[tokenIndex] : "h";

    const NavLink = ({ to, children }) => {
      return (
        <Link
          className={`link-reset ${
            currentLocation.includes(to) ? "active" : ""
          }`}
          to={to}
          onClick={() => this.setState({ openNav: false })}
        >
          {children}
        </Link>
      );
    };

    const variant = getVariant();
    return (
      <div
        className={`site-header${
          currentLocation.split("/").length < 3 ? " active" : ""
        }`}
      >
        <Link className="home-icon" to="/">
          <img src={ADPKDIconSVG} alt="ADPKD Logo" />
        </Link>
        <div className={`nav-container${this.state.openNav ? " active" : ""}`}>
          {variant === "expert" && (
            <>
              <div className="header-nav">
                <NavLink to="/expert/prognostic-tools">
                  Prognostic Tools
                </NavLink>
                <NavLink to="/expert/adpkd-simulator">ADPKD Simulator</NavLink>
                <NavLink to="/expert/resources">Resources</NavLink>
              </div>
              {!isMobileApp() && (
                <span
                  className="sign-up"
                  onClick={() =>
                    this.setState({
                      showModal: true,
                      modalReason: "Clicked Header Link",
                      openNav: false
                    })
                  }
                >
                  Get Updates
                </span>
              )}
            </>
          )}
          {variant === "patient" && (
            <>
              <div className="header-nav">
                <NavLink to="/patient/information">Information</NavLink>
                <NavLink to="/patient/adpkd-simulator">
                  Patient Profiles
                </NavLink>
              </div>
            </>
          )}
        </div>
        <div
          className={`hamburger${this.state.openNav ? " active" : ""}`}
          onClick={this.handleNavClick}
        >
          <span />
          <span />
          <span />
        </div>
        {!isMobileApp() && (
          <SignupModal
            showModal={this.state.showModal}
            reason={this.state.modalReason}
            handleClose={() =>
              this.setState({ showModal: false, modalReason: undefined })
            }
          />
        )}
      </div>
    );
  }
}
