import React, { Component } from "react";
import AnimatedInput from "../helpers/AnimatedInput";
import Constants from "../helpers/Constants";
import dataLayer from "../helpers/dataLayer";
import leftKidney from "../images/kidney-measure-left.png";

import "animate.css/animate.min.css";

const PATIENT_NAMES = {
  patienta: "Patient A",
  patientb: "Patient B"
};

export default class KidneyDimensionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lkl: "",
      lkw: "",
      lkd: "",
      rkl: "",
      rkw: "",
      rkd: "",
      patientName: this.props.patientName
    };

    this.timeouts = [];
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      // Accordion has been opened
      if (this.props.open) {
        this.animateTextInput();
      } else {
        // Accordion has been closed
        this.timeouts.forEach(timeout => clearTimeout(timeout));
        this.timeouts = [];
        this.setState({
          lkl: "",
          lkw: "",
          lkd: "",
          rkl: "",
          rkw: "",
          rkd: ""
        });
      }
    }
  }

  animateTextInput() {
    const animationGroups = [
      {
        lkl: this.props.leftKidneyLength,
        rkl: this.props.rightKidneyLength
      },
      {
        lkw: this.props.leftKidneyWidth,
        rkw: this.props.rightKidneyWidth
      },
      {
        lkd: this.props.leftKidneyDepth,
        rkd: this.props.rightKidneyDepth
      }
    ];
    animationGroups.forEach((animationGroup, index) => {
      const timeout = setTimeout(() => {
        this.setState(animationGroup);
      }, index * Constants.TYPING_SPEED);

      this.timeouts.push(timeout);
    });

    // Send to data layer
    dataLayer.push({
      event: "tkv.calculator",
      action: "Viewed: Order an MRI or CT"
    });
    // Clean values so they are not picked up by future tags
    dataLayer.clean("action");
  }

  render() {
    if (this.props.patientName !== this.state.patientName) {
      // window.addEventListener("scroll", this.throttledScrollHandler);
      this.setState({
        lkl: "",
        lkw: "",
        lkd: "",
        rkl: "",
        rkw: "",
        rkd: "",
        patientName: this.props.patientName
      });
    }
    const lklValue = this.state.lkl;
    const lkwValue = this.state.lkw;
    const lkdValue = this.state.lkd;
    const rklValue = this.state.rkl;
    const rkwValue = this.state.rkw;
    const rkdValue = this.state.rkd;
    const patientName = PATIENT_NAMES[this.props.patientName] + "'s";
    return (
      <div id="kidney-dimensions-form">
        <div className="flex row">
          <div className="form-container">
            <div className="prompt">{patientName} kidney dimensions:</div>
            <div className="flex" ref={el => (this.AnimatedKidneyForm = el)}>
              <div className="left-kidney-dimensions">
                <label>Left Kidney</label>
                <div className="input-container">
                  <AnimatedInput kidneyValue={lklValue} />
                  <AnimatedInput kidneyValue={lkwValue} />
                  <AnimatedInput kidneyValue={lkdValue} />
                </div>
              </div>
              <div className="label-container">
                <label>Length*&nbsp;(mm)</label>
                <label>
                  Width<sup>&#8224;</sup>&nbsp;(mm)
                </label>
                <label>
                  Depth<sup>&#8224;</sup>&nbsp;(mm)
                </label>
              </div>
              <div className="right-kidney-dimensions">
                <label>Right Kidney</label>
                <div className="input-container">
                  <AnimatedInput kidneyValue={rklValue} />
                  <AnimatedInput kidneyValue={rkwValue} />
                  <AnimatedInput kidneyValue={rkdValue} />
                </div>
              </div>
            </div>
          </div>
          <img src={leftKidney} alt="Left Kidney" />
        </div>
      </div>
    );
  }
}
