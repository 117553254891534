import React, { Component } from "react";
import './FamilyHistory.scss'
import { Page } from "../../../components/Page";
import { TableWrapper } from "../../../components/Table";

import FamilyIconPath from '../icons/Family Tree.svg'
import DNATestingIconPath from '../icons/DNA Testing.svg'

export default class FamilyHistory extends Component {
  render() {
    return (
      <Page title="Information - Patient Medical History and Genetic Testing" className="genetic-testing">
        <h1>Gathering family history and genetic testing to understand the severity of ADPKD</h1>
        <TableWrapper>
          <table>
            <col style={{ width: '35%' }} />
            <col style={{ width: '65%' }} />
            <tbody>
              <tr style={{ 'padding-bottom': '1rem' }}>
                <th scope="row">
                  <img src={FamilyIconPath} />
                </th>
                <td>
                  <ul>
                    <li>Family history (knowing which family members have had kidney disease and at what age their kidneys failed) can help your doctors predict the type of gene that is altered and determine how aggressive your ADPKD may be<sup>1,2</sup>.</li>
                  </ul>
                  <ul>
                    <li>Approximately 10-15% of people diagnosed with ADPKD have no family history<sup>3</sup>. Therefore, your doctor may request genetic testing<sup>2</sup>.
                      <ul>
                        <li>Depending on the test done, a blood sample or saliva sample will be collected to perform the genetic test.</li>
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>
              <tr style={{ 'padding-bottom': '1rem' }}>
                <th scope="row">
                  <img src={DNATestingIconPath} />
                </th>
                <td>

                  <ul>
                    <li>Performing genetic testing and understanding how the gene is altered may help your doctor determine how quickly your kidneys may reach failure<sup>2</sup>.
                      <ul>
                        <li>Two widely known disease-causing genes (<em>PKD1</em> or <em>PKD2</em>) may be tested<sup>2</sup>.</li>
                        <li>Newly identified disease-causing genes may also be tested<sup>2</sup>.</li>
                      </ul>
                    </li>
                  </ul>
                </td>
              </tr>

            </tbody>
          </table>
        </TableWrapper>




        <p className="dfn-block">
          <span><dfn id="def-ADPKD">ADPKD</dfn> autosomal dominant polycystic kidney disease</span>
          <span style={{ padding: '0 0.5rem' }}>•</span>
          <span><dfn id="def-PKD">PKD</dfn> polycystic kidney disease</span>
        </p>



        <div className="footer-section">
          <div className="references">
            <strong className="title">References:</strong>
            <ol>
              <li>
                Chebib & Torres. (2021) <em>AJKD.</em> 78 (2): 282-292.
              </li>
              <li>
                Lanktree MB, et al. (2019). <em>Nephrol Dial Transplant</em>. 34: 1453-1460.
              </li>
              <li>
                Illiuta et al. (2017). <em>JASN</em> 28: 2768-2776
              </li>
            </ol>
          </div>
        </div>
      </Page>
    );
  }
}
