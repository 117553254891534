import React, { Component } from "react";
import { Page } from "../../components/Page";

import PracticalGuide from "../../images/practical-guide.jpg";

import "../../css/PracticalGuide.scss";

export default class USPRacticalGuide extends Component {
  render() {
    return (
      <Page
        title="Prognostic Tools - US Practical guide / Expert opinion"
        className="practical-guide"
      >
        <h1>
          US Practical guide<sup>1</sup>
        </h1>
        <img src={PracticalGuide} alt="Practical Guide" />
        <h1>
          Rapid Progression Assessment for Patients With ADPKD- 2021 US Expert
          Opinion<sup>1</sup>
        </h1>
        <section>
          <p className="title">Definition of Rapid Progression</p>
          <p className="content">Reaching kidney failure by age 62*</p>
        </section>
        <section>
          <p className="title">Risk of Rapid Progression</p>
          <p className="content">
            Risk of reaching kidney failure by age 62 by estimating age-adjusted
            TKV: <br />
            <strong>
              Mayo Imaging Class 1 (typical bilateral renal cystic disease) C, D
              or E
            </strong>
          </p>
        </section>
        <section>
          <p className="title">Definition of Rapid Progression</p>
          <p className="content">
            Measured rapid growth of TKV or decline in kidney function due to
            cystic burden: <br /> <br />
            <strong>
              GFR decline ≥ 2.5 ml/min/year (over 5 years) and Class 1C-1E
              <br /> <br />
              OR <br /> <br />
              TKV growth ≥ 5% per year (3 measurements at least 6 months apart)
            </strong>
          </p>
        </section>
        <div className="footer-section">
          <p>
            *In an analysis from the Mayo PKD clinical database on 1076 ADPKD
            patients reaching kidney failure, 50% and 75% of the patients
            reached kidney failure by age 54 and 62 years
          </p>
          <div>
            <p className="glossary">
              BMI=body mass index; CT=computed tomography; eGFR=estimated
              glomerular filtration rate; HDL=high-density lipoprotein;
              LDL=low-density lipoprotein; MRI=magnetic resonance imaging;
              Uosm=urine osmolality.
            </p>
          </div>
          <div className="references">
            <strong className="title">References:</strong>
            <p>
              1. Chebib FT, Perrone RD, Chapman AB, Dahl NK, Harris PC, Mrug M,
              Mustafa RA, Rastogi A, Watnick T, Alan SL, Torres VE. A practical
              guide for treatment of rapidly progressive ADPKD with tolvaptan.
              <i>Journal of the American Society of Nephrology</i>. 2018;
              29(10):2458-70
            </p>
            <p>
              2. Chebib FT, et al. Assessing Risk of Rapid Progression in
              Autosomal Dominant Polycystic Kidney Disease and Special
              Considerations for Disease-Modifying Therapy.{" "}
              <i>Am J Kidney Dis</i>. 2021;78(2):282-292.
            </p>
          </div>
        </div>
      </Page>
    );
  }
}
