import React, { Component } from "react";
import { Page } from "../../../components/Page";

import CATScanIcon from '../icons/CAT Scan.svg';
import MRIIcon from '../icons/MRI Scan.svg';
import UltrasoundIcon from '../icons/Ultrasound.svg';

import { TableWrapper } from "../../../components/Table";

export default class ImagingInformation extends Component {
  render() {
    return (
      <Page title="Information - Imaging" className="genetic-testing">

        <h1>Kidney Size is Measured with Imaging</h1>

        <TableWrapper>
          <table>
            <col style={{ width: '35%' }} />
            <col style={{ width: '65%' }} />
            <thead>

            </thead>
            <tbody>

              <tr>
                <th colspan="2">
                  <h2>Determining kidney size</h2>
                </th>
              </tr>
              <tr>
                <th scope='row'>
                  <img src={CATScanIcon} />
                </th>
                <td>
                  <ul>
                    <li>
                      Your kidney doctor works with a radiology team, who specializes in imaging.
                    </li>
                    <li>
                      Measurements taken during the imaging tests help calculate the volume of the kidneys (how big they are compared to a healthy kidney).
                    </li>
                    <li>
                      Knowing how big your kidneys are at the time of your imaging scan, can help predict how badly your kidneys are being damaged, and how quickly you may reach kidney failure.
                    </li>
                  </ul>
                </td>
              </tr>

              <tr>
                <th colspan="2">
                  <h2>MRI and CT Imaging</h2>
                </th>
              </tr>
              <tr>
                <th scope='row'>
                  <img src={MRIIcon} />
                </th>
                <td>
                  <ul>
                    <li>
                      <strong>Magnetic resonance imaging (MRI)</strong>: a large tube scanner uses a
                      magnet, radio waves, and a computer to create an image.
                    </li>
                    <li>
                      <strong>Computed tomography scan (CT or CAT)</strong>: this scanner typically looks like a
                      large donut and uses x-rays and a computer to create an image.
                    </li>
                  </ul>
                </td>
              </tr>

              <tr>
                <th colspan="2">
                  <h2>Ultrasound imaging</h2>
                </th>
              </tr>
              <tr>
                <th scope='row'>
                  <img src={UltrasoundIcon} />
                </th>
                <td>
                  <ul>
                    <li>
                      If an MRI or CT scan is not an option, your doctor may perform an ultrasound.
                    </li>
                    <ul>
                      <li>
                        Ultrasound imaging does not use dyes or radiation.
                      </li>
                      <li>
                        This imaging test is like the one used in pregnant women; however, they will be taking pictures of your kidneys.
                      </li>
                    </ul>
                    <li>
                      Kidney length is measured and can help doctors predict how badly your kidneys are being damaged and how quickly you may reach kidney failure.
                    </li>
                  </ul>
                </td>
              </tr>

            </tbody>
          </table>
        </TableWrapper>

        {/* <h1>Kidney Size is Measured with Imaging</h1>

        <h2>Types of Imaging</h2>
        <Row heroIconSlot={<img src={MRIIcon} />} contentSlot={
          <ul>
            <li>
              <strong>Magnetic resonance imaging (MRI)</strong>: a large tube scanner uses a
              magnet, sound waves and a computer to create an image.
            </li>
            <li>
              <strong>Computed tomography scan (CT or CAT)</strong>: this scanner typically looks like a
              large donut and uses x-rays and a computer to create an image.
            </li>
          </ul>} />



        <h2>Determining Kidney Size</h2>

        <Row heroIconSlot={<img src={CATScanIcon} />} contentSlot={
          <ul>
            <li>
              Your kidney doctor works with a radiology team, who specializes in imaging.
            </li>
            <li>
              Measurements taken during the imaging tests help calculate the volume of the kidneys (how big they are compared to a healthy kidney).
            </li>
            <li>
              Knowing how big your kidneys are at the time of your imaging scan, can help predict how badly your kidneys are being damaged and how close you are to kidney failure.
            </li>
          </ul>
        } />


        <h2>Ultrasound Imaging</h2>

        <Row heroIconSlot={<img src={UltrasoundIcon} />} contentSlot={
          <ul>
            <li>
              If an MRI or CT scan is not an option, your doctor may perform an ultrasound.
            </li>
            <ul>
              <li>
                Ultrasound imaging does not use dyes or radiation.
              </li>
              <li>
                This imaging test is like the one used in pregnant women; however, they will be taking pictures of your kidneys.
              </li>
            </ul>
            <li>
              Kidney length is measured and can help doctors predict how badly your kidneys are being damaged and how quickly you may reach kidney failure.
            </li>
          </ul>
        } /> */}



        <p className="dfn-block">
          <span><dfn id="def-MRI">MRI</dfn> magnetic resonance imaging</span>
          <span style={{ padding: '0 0.5rem' }}>•</span>
          <span><dfn id="def-CT">CT</dfn> computerised tomography</span>
        </p>


        {/* <div className="footer-section">
          <div className="references">
            <strong className="title">References:</strong>
            <p>ahn H et al., 2022.</p>
            <p>orolev IO et al., 2014.</p>
            <p>
              lzheimer’s Association 2022: Alzheimer’s Disease Facts and
              Figures., 2022
            </p>
            <p>ajan KB et al., 2021</p>
            <p>arasco RA et al., 2020</p>
          </div>
        </div> */}
      </Page>
    );
  }
}
