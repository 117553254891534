import React, { Component } from "react";
import { Link } from "react-router-dom";
// import KidneyIcon from "../images/kidney-icon";
import { Page } from "../components/Page";
import DoctorIcon from "../images/icon-doctor";
import UserIcon from "../images/icon-user";

import "./index.scss";

export default class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <Page
        title="Autosomal Dominant Polycystic Kidney Disease (ADPKD) Simulator"
        className="grid-page-layout"
      >
        <div className="preliminary-info">
          <div>
            {/* <img className="main-image" src={HomePageKidney} alt="Kidney" /> */}
            <h1 className="title">
              <strong>ADPKDsim</strong> is an educational resource with
              information on assessing the rate of ADPKD progression
            </h1>
            <p className="sub-header">
              In patients with autosomal dominant polycystic kidney disease
              (ADPKD), assessing the{" "}
              <strong>rate of disease progression</strong> is an important part
              of disease management.
            </p>
          </div>

        </div>

        <div className="card-container" data-full-bleed={false}>
          <Link className="link-reset" to="/patient">
            <div className="card" data-variant='patient'>
              <UserIcon />
              <div>
                <h2>Patient</h2>
                {/* <p>
                      See how different methods evaluate risk factors to <strong>assess the rate of disease progression</strong> in ADPKD.
                    </p> */}
                <span className="link-text" data-variant='patient'>
                  Information for patients
                  <span className="arrow" />
                </span>
              </div>
            </div>
          </Link>
          <Link className="link-reset" to="/expert">
            <div className="card" data-variant='expert'>
              <DoctorIcon />
              <div>
                <h2>Healthcare Team Member</h2>
                {/* <p>
                      Select from hypothetical patient profiles with <strong>simulated disease progression</strong> to see utilization of prognostic tools in ADPKD.
                    </p> */}
                <span className="link-text" data-variant='expert'>
                  Information for health <br /> care professionals
                  <span className="arrow" />
                </span>
              </div>
            </div>
          </Link>
        </div>



        {/* <div className="banner">
          <KidneyIcon />
          <p>
            ADPKDsim is an educational resource with information on assessing
            the rate of disease progression in ADPKD. It provides an overview of
            different prognostic tools and simulations of disease progression in
            hypothetical patient profiles.
          </p>
        </div> */}
      </Page>
    );
  }
}
