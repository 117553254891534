import React, { Component } from "react";
import { Link } from "react-router-dom";
import { getVariant, isMobileApp } from "../helpers/compat";
import Constants from "../helpers/Constants";
import NavigatorModalPrivacy from "./NavigatorModal";
import NavigatorModalTerms from "./NavigatorModal";

import tools from "../pages/tools/Tools";
import caseStudies from "../profileData";

export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalOpen: false,
      showNavigationModalPrivacy: false,
      showNavigationModalTerms: false
    };
  }
  render() {
    const currentDate = Constants.month + " " + Constants.year;
    const PRBNumber = Constants.PRB;
    const privacyLink = Constants.privacy_link;
    const termsLink = Constants.terms_link;
    const variant = getVariant();
    return (
      <div className="footer">
        <div className="flex sitemap">
          {variant === "expert" && (
            <>
              <div className="links-container">
                <Link
                  className="link-reset header"
                  to="/expert/prognostic-tools"
                >
                  Prognostic Tools
                </Link>
                {tools.expert.map((tool, index) => (
                  <Link
                    key={index}
                    className="link-reset"
                    to={`${tool.url}/${tool.param}`}
                  >
                    {tool.name}
                  </Link>
                ))}
              </div>
              <div className="links-container">
                <Link
                  className="link-reset header"
                  to="/expert/adpkd-simulator"
                >
                  ADPKD Simulator
                </Link>
                {caseStudies[variant].map((cs, index) => (
                  <Link
                    key={index}
                    className="link-reset"
                    to={`/expert/adpkd-simulator/patient-profiles/${cs.tag}`}
                  >
                    {cs.tag}
                  </Link>
                ))}
              </div>
              <div className="links-container">
                <Link className="link-reset header" to="/expert/resources">
                  Resources
                </Link>
                <Link
                  className="link-reset"
                  to="/expert/resources/adpkd-clinical-education"
                >
                  ADPKD Clinical Education
                </Link>
                <Link
                  className="link-reset"
                  to="/expert/resources/adpkd-patient-perspectives"
                >
                  ADPKD Patient Perspective
                </Link>
                <Link
                  className="link-reset"
                  to="/expert/resources/additional-information"
                >
                  Additional Information
                </Link>
              </div>
            </>
          )}
          {variant === "patient" && (
            <>
              <div className="links-container">
                <Link className="link-reset header" to="/patient/information">
                  Information
                </Link>
                {tools.patient.map((tool, index) => (
                  <Link
                    key={index}
                    className="link-reset"
                    to={`${tool.url}/${tool.param}`}
                  >
                    {tool.name}
                  </Link>
                ))}
              </div>
              <div className="links-container">
                <Link
                  className="link-reset header"
                  to="/patient/adpkd-simulator"
                >
                  Patient Profiles
                </Link>
                {caseStudies[variant].map((cs, index) => (
                  <Link
                    key={index}
                    className="link-reset"
                    to={`/patient/adpkd-simulator/patient-profiles/${cs.tag}`}
                  >
                    {cs.tag}
                  </Link>
                ))}
              </div>
            </>
          )}
        </div>
        {!isMobileApp() && (
          <div className="flex privacy-terms">
            <div className="flex">
              <a
                className="button confirm"
                onClick={() =>
                  this.setState({ showNavigationModalPrivacy: true })
                }
              >
                Privacy Policy
              </a>
              <a
                className="button confirm"
                onClick={() =>
                  this.setState({ showNavigationModalTerms: true })
                }
              >
                Terms of Use
              </a>
            </div>
          </div>
        )}
        <div className="flex legal">
          <div className="flex">
            <span>
              <span className="copy-symbol">&copy;</span>
              {Constants.year} Otsuka Pharmaceutical Development &amp;
              Commercialization, Inc. All Rights Reserved.
            </span>
          </div>
          <div className="date">{currentDate}</div>
          <div className="number">{PRBNumber}</div>
        </div>
        <NavigatorModalPrivacy
          showNavigationModal={this.state.showNavigationModalPrivacy}
          link={privacyLink}
          handleClose={() =>
            this.setState({ showNavigationModalPrivacy: false })
          }
        />
        <NavigatorModalTerms
          showNavigationModal={this.state.showNavigationModalTerms}
          link={termsLink}
          handleClose={() => this.setState({ showNavigationModalTerms: false })}
        />
      </div>
    );
  }
}
