// expecting a yValue of 0 - 16000
export const getYPercent = yValue => {
  const increment = 0.136; // the percent height a quadrant covers of the full chart
  let t0 = 0; // initial value for the min value of the containing quadrant
  let t1 = 200; // initial value for the max value of the containing quadrant
  let p0 = 0; // initial value for the percent of the bottom edge of the containing quadrant

  // loop through the quadrants of the chart
  // quadrants start at 0, 200, then double thereafter
  // 25600 is the final doubling after 16000
  for (let i = 1; t0 <= 25600; i += 1) {
    if (yValue <= t1) break; // stop looping if we've found the containing quadrant

    t0 = i === 1 ? 200 : t0 * 2; // set the min value of the containing quadrant (200 or double)
    t1 = t0 * 2; // set the max edge of the containing quadrant (double of t1)
    p0 = i * increment; // set the percent of the bottom edge of the containging value
  }

  const percent = (yValue - t0) / (t1 - t0); // get the linear percent between t0 and t1
  const inverse = 1 - percent; // get the inverse (0 = 1, 1 = 0)
  const mod = percent + 1.5 * inverse; // determining a diminishing modifier, accounting for doubling
  const adjusted = percent * mod; // adjusting percentage by modifier
  const yDiff = p0 + increment * adjusted; // adding the mod * increment to the bottom edge of the containing quadrant
  const rounded = 1 - Math.round(yDiff * 1000) / 1000; // rounding and reversing percentage for css positioning

  return rounded;
};
