import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "../../../Modal";
import Chart from "./Chart";
import { graphWidth, graphHeight, graphScaleFactor } from "./index";
import "./modal-charts.scss";

class ModalCharts extends Component {
  render() {
    return (
      <Modal isShowing={this.props.isZoomed} onHide={this.props.unZoom}>
        <div className="charts-modal-container">
          <Chart
            seek={this.props.seek}
            index={this.props.index}
            isTkv={true}
            currentValue={this.props.tkvVal}
            graphWidth={graphWidth}
            graphHeight={graphHeight}
            scaleFactor={graphScaleFactor}
            patientData={this.props.patientData}
            normalEgfr={this.props.normalEgfr}
            reverseScaleProgress={0}
            onClick={this.props.unZoom}
            isRevealed={this.props.isRevealed}
            expanderVisible={false}
          />
          <Chart
            seek={this.props.seek}
            index={this.props.index}
            isTkv={false}
            currentValue={this.props.egfrVal}
            graphWidth={graphWidth}
            graphHeight={graphHeight}
            scaleFactor={graphScaleFactor}
            patientData={this.props.patientData}
            normalEgfr={this.props.normalEgfr}
            reverseScaleProgress={0}
            onClick={this.props.unZoom}
            isRevealed={this.props.isRevealed}
            expanderVisible={false}
          />
          <span
            className="close"
            onClick={this.props.unZoom}
            onTouchEnd={this.props.unZoom}
          />
        </div>
      </Modal>
    );
  }
}

ModalCharts.propTypes = {
  isZoomed: PropTypes.bool.isRequired,
  unZoom: PropTypes.func.isRequired
};

export default ModalCharts;
