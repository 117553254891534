import React, { Component } from "react";
import { Card } from "../../../components/Card";
import { Page } from "../../../components/Page";
import './BloodTest.scss';

import KidneyStage1 from '../../../assets/kidney-stage-1.png';
import KidneyStage2 from '../../../assets/kidney-stage-2.png';
import KidneyStage3 from '../../../assets/kidney-stage-3.png';

import Cyst1 from '../icons/cyst-1.png';
import Cyst2 from '../icons/cyst-2.png';
import Cyst3 from '../icons/cyst-3.png';


export default class BloodTestInformation extends Component {
  render() {
    return (
      <Page title="Information - Blood Test" className="genetic-testing">
        <h1>Kidney Function is Determined by a Blood Test</h1>
        <p>A blood test is performed to measure creatinine levels in your blood.</p>
        <ul>
          <li>Creatinine is a waste product that the kidneys typically &ldquo;clean out&rdquo;.</li>
          <li>Everyone has creatinine in their bloodstream, but higher than normal levels could mean your kidneys are not working as well as they should.</li>
        </ul>

        <p>Estimated Glomerular Filtration Rate (eGFR) is a calculation to see how well your kidneys are filtering.  The equation uses the level of creatinine in your blood  to determine your eGFR.</p>

        <p>In early stages of disease, cysts are forming in your kidneys, but your kidneys can work harder to keep the kidneys functioning.</p>

        <p>Therefore, early in the disease, your blood test may not tell you how much damage your kidney really has.</p>

        <div className="egfr-levels">
          <Card title={'Cysts Forming'} subtitle={<span>Normal eGFR <strong >({'>'} 90)</strong></span>} iconUrl={KidneyStage1} heroImageUrl={Cyst1} bgColor='#7383c1' ringColor='#4b5ea8' />
          <Card title={'Cysts gets larger'} subtitle={<span>Slighty abnormal eGFR Level eGFR <strong >(60-89)</strong></span>} iconUrl={KidneyStage2} heroImageUrl={Cyst2} bgColor='#0d4eaa' ringColor='#3e2c93' />
          <Card title={'Cysts take over the kidney'} subtitle={<span>Abnormal eGFR Level <strong >({'<'} 60)</strong></span>} iconUrl={KidneyStage3} heroImageUrl={Cyst3} bgColor='#0f9999' ringColor='#00755c' />

        </div>



      </Page>
    );
  }
}
