import React from "react";

export default () => (
  <svg width="15px" height="14px" viewBox="0 0 15 14">
    <g id="ADPKD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Artboard-Copy-6"
        transform="translate(-431.000000, -1633.000000)"
        fill="#4A4A4A"
      >
        <g id="Group-3-Copy-2" transform="translate(431.000000, 1633.000000)">
          <path
            d="M2.02013512,10.9597566 C3.66207445,9.11288971 4.67147967,6.03413219 4.62180421,2.2163695 L3.35872301,2.2163695 C1.59160154,2.16727475 1.08623659,3.47280748 0.631209432,4.23571403 L0,4.23571403 C1.31275666,1.72413005 0.455027156,0 5.55570274,0 L14.9496622,0 L14.9496622,2.2163695 L11.4140946,2.2163695 C10.5802093,9.75370537 10.7067161,10.8370198 11.9187972,11.5262842 C12.4744999,11.846692 14.191946,11.9946222 14.418466,9.80280012 L15,9.80280012 C15,14.8763548 8.81308783,15.417689 8.68591866,11.0585921 C8.66074977,10.3938751 8.88859452,7.66007274 9.39395946,2.19246811 L6.01006756,2.19182213 C6.01006756,2.19182213 5.37885813,9.95008436 5.10067559,11.0327528 C4.89866207,11.8208527 4.7476487,13.6677196 3.1063717,13.8905839 C1.96979732,14.0876089 0.35435157,12.8066235 2.02013512,10.9597566"
            id="Fill-1"
          />
        </g>
      </g>
    </g>
  </svg>
);
