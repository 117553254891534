import React from "react";
import './Card.scss';

const Card = ({ title, subtitle, iconUrl, heroImageUrl, bgColor = 'white', ringColor = 'white' }) => {

    return (
        <div class="card-435320b5-7c6c-47c3-8460-804389f4ffbd" style={{ '--ring-color': ringColor, '--bg-color': bgColor }}>

            <div class='icon-container'>
                <img src={iconUrl} className='icon' />

                <div class="caption">{title}</div>
            </div>

            <figure className="hero-figure">
                <img src={heroImageUrl} />
                <figcaption>
                    {subtitle}
                </figcaption>
            </figure>



        </div>
    )
}

export { Card };

