import React from "react";
import { getVariant } from "../helpers/compat";

const Disclaimer = (props) => {
  const variant = getVariant();
  switch (variant) {
    case "patient":
      return (
        <div className="disclaimer">
          <p>
            The information provided through ADPKDsim website is intended for
            your educational benefit only. It is not intended as, nor is it a
            substitute for, medical care, advice, or professional diagnosis.
            Users seeking medical advice should consult with a health care
            professional.
          </p>
        </div>
      );
    case "expert":
      return (
        <div className="disclaimer">
          <p>
            The information provided on the ADPKDsim website is intended for
            your educational benefit only. It is not intended as, nor is it a
            substitute for medical care or advice or professional diagnosis.
          </p>
          <p>
            Health care professionals should use their independent judgment when
            reviewing the ADPKDsim's educational resources. Users seeking
            medical advice should consult with a health care professional.
          </p>
        </div>
      );
    default:
      return (
        <div className="disclaimer">
          <p>
            The information provided on the ADPKDsim website is intended for
            your educational benefit only. It is not intended as, nor is it a
            substitute for medical care or advice or professional diagnosis.
          </p>
          <p>
            Health care professionals should use their independent judgment when
            reviewing the ADPKDsim's educational resources. Users seeking
            medical advice should consult with a health care professional.
          </p>
        </div>
      );
  }
};

export default Disclaimer;
