import React, { Component } from "react";
import Constants from "../helpers/Constants";
import { getYPercent } from "../helpers/getYPercent";
import calcVolume from "../helpers/CalculateVolume";
import imagingChart from "../images/chart.png";
import imagingChartClass1A from "../images/class1A.png";
import imagingChartClass1B from "../images/class1B.png";
import imagingChartClass1C from "../images/class1C.png";
import imagingChartClass1D from "../images/class1D.png";
import imagingChartClass1E from "../images/class1E.png";
import jeremyChart from "../images/patient-chart-a.jpg";
import angelaChart from "../images/patient-chart-b.jpg";

const CLASSIFICATION = {
  patienta: "Patient A is likely at high risk for rapid progression.",
  patientb: "Patient B is likely at low risk for rapid progression."
};

const IMAGE_NATURAL_WIDTH = 600;
const IMAGE_NATURAL_HEIGHT = 318;

export default class ClassificationChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      top: "",
      left: "",
      boxTop: "",
      boxLeft: "",
      boxHeight: "",
      boxWidth: "",
      updatePoint: false
    };

    this.plotPoint = this.plotPoint.bind(this);
  }
  componentDidMount() {
    if (this.props.chartClassification) {
      this.plotPoint();
    }
    window.addEventListener("resize", this.plotPoint);
  }
  static getDerivedStateFromProps(props, state) {
    if (props.plotGraph && !state.updatePoint) {
      return { updatePoint: true };
    }
    return { updatePoint: false };
  }
  getSnapshotBeforeUpdate() {
    if (this.state.updatePoint) {
      return true;
    }
    return null;
  }
  componentDidUpdate(prevProps, prevState, snapshot) {
    if (snapshot) {
      this.plotPoint();
    }
  }
  plotPoint() {
    const leftTKV = calcVolume(
      this.props.leftKidneyLength,
      this.props.leftKidneyWidth,
      this.props.leftKidneyDepth
    );
    const rightTKV = calcVolume(
      this.props.rightKidneyLength,
      this.props.rightKidneyWidth,
      this.props.rightKidneyDepth
    );
    let TKVValue = null;
    if (this.props.skipToStep3) {
      TKVValue = parseFloat(this.props.userInputTKVValue);
    } else {
      TKVValue = Number((Number(leftTKV) + Number(rightTKV)).toFixed(1));
    }
    const htTKVValue = Number(
      (Number(TKVValue) / Number(this.props.height)).toFixed(1)
    );

    const imageHeight = this.classificationChart.children[0].height;
    const imageWidth = this.classificationChart.children[0].width;
    const imageScaleWidth = imageWidth / IMAGE_NATURAL_WIDTH;
    const imageScaleHeight = imageHeight / IMAGE_NATURAL_HEIGHT;

    const imageAge = (this.props.age - 15) / 65;
    const left = (34 + 556 * imageAge) * imageScaleWidth;
    const imageTKV = getYPercent(htTKVValue);
    const top = 288 * imageTKV * imageScaleHeight;
    const boxLeft = 40 * imageScaleWidth;
    const boxTop = 36 * imageScaleHeight;
    const boxFullHeight =
      imageHeight - top >= imageHeight ? imageHeight : imageHeight - top;
    const boxHeight = boxFullHeight - boxTop;
    const boxWidth = left - boxLeft + 10;
    this.setState({
      left,
      top: top < 0 ? 0 : top,
      boxLeft,
      boxTop,
      boxHeight,
      boxWidth
    });
  }
  render() {
    const { top, left, boxTop, boxLeft, boxHeight, boxWidth } = this.state;
    const classification = this.props.chartClassification;
    const imagingChartBlank = (
      <img
        className="imaging-chart"
        src={imagingChart}
        alt="Classification Chart"
      />
    );
    const patientChart = {
      patienta: (
        <img
          className="imaging-chart"
          src={jeremyChart}
          alt="Classification Chart Jeremy"
        />
      ),
      patientb: (
        <img
          className="imaging-chart"
          src={angelaChart}
          alt="Classification Chart Angela"
        />
      )
    };

    const ClassificationImages = {
      Class1A: (
        <img
          className="imaging-chart"
          src={imagingChartClass1A}
          alt="Classification 1A"
        />
      ),
      Class1B: (
        <img
          className="imaging-chart"
          src={imagingChartClass1B}
          alt="Classification 1B"
        />
      ),
      Class1C: (
        <img
          className="imaging-chart"
          src={imagingChartClass1C}
          alt="Classification 1C"
        />
      ),
      Class1D: (
        <img
          className="imaging-chart"
          src={imagingChartClass1D}
          alt="Classification 1D"
        />
      ),
      Class1E: (
        <img
          className="imaging-chart"
          src={imagingChartClass1E}
          alt="Classification 1E"
        />
      )
    };
    return (
      <div id="imaging-classification-chart">
        <div className="flex">
          <div className="chart-container">
            <p className="image-title">
              <strong>ADPKD Imaging Classification by htTKV and Age*</strong>
            </p>
            <div
              ref={el => (this.classificationChart = el)}
              className={`image-cover${
                this.props.patient === "user" && classification.length === 0
                  ? ""
                  : " clear"
              }`}
            >
              {this.props.patient !== "user" ? (
                <div
                  className={
                    this.props.stepFourAccordion
                      ? `chart animated ${Constants.animation.chart}`
                      : "vis-hidden"
                  }
                >
                  {imagingChartBlank}
                </div>
              ) : null}
              {this.props.patient !== "user"
                ? patientChart[this.props.patient]
                : classification
                ? ClassificationImages[classification]
                : imagingChartBlank}
              {classification ? (
                <div>
                  <div className="point" style={{ top, left }}>
                    <span className="dot" />
                  </div>
                  <div
                    className="dotted-box"
                    style={{
                      height: boxHeight,
                      width: boxWidth,
                      left: boxLeft,
                      bottom: boxTop
                    }}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div className="classification-container">
            <p>
              <strong>Patient Classifications*</strong>
            </p>
            <div className="classification-table">
              <div className="flex header">
                <span>CLASS</span>
                <span>ESTIMATED KIDNEY GROWTH RATE YEARLY % INCREASE</span>
                <span>RISK FOR eGFR DECLINE</span>
              </div>
              <div
                className={`flex row${
                  classification === "Class1E" ? " active" : ""
                }`}
              >
                <span>1E</span>
                <span>&gt;6%</span>
                <span>High Risk</span>
              </div>
              <div
                className={`flex row${
                  this.props.patient === "patienta"
                    ? " active"
                    : classification === "Class1D"
                    ? " active"
                    : ""
                }`}
              >
                <span>1D</span>
                <span>4.5% - 6%</span>
                <span>High Risk</span>
              </div>
              <div
                className={`flex row${
                  classification === "Class1C" ? " active" : ""
                }`}
              >
                <span>1C</span>
                <span>3% - 4.5%</span>
                <span>High Risk</span>
              </div>
              <div
                className={`flex row${
                  classification === "Class1B" ? " active" : ""
                }`}
              >
                <span>1B</span>
                <span>1.5% - 3%</span>
                <span>Intermediate Risk</span>
              </div>
              <div
                className={`flex row${
                  this.props.patient === "patientb"
                    ? " active"
                    : classification === "Class1A"
                    ? " active"
                    : ""
                }`}
              >
                <span>1A</span>
                <span>&lt;1.5%</span>
                <span>Low Risk</span>
              </div>
            </div>
          </div>
        </div>
        <p>
          Adapted with permission of The American Society of Nephrology, from
          Irazabal MV, et al. Imaging classification of autosomal polycystic
          kidney disease: a simple model for selecting patients for clinical
          trials. <i>J Am Soc Nephrol.</i> 2015;26(1):160-172.
        </p>
        {this.props.patient !== "user" ? (
          <p className="classification">{CLASSIFICATION[this.props.patient]}</p>
        ) : null}
      </div>
    );
  }
}
