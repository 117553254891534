import React, { Component } from "react";
import { Page } from "../../components/Page";
import AnimatedKidneyDimensionsForm from "../../components/AnimatedKidneyDimensionsForm";
import KidneyDimensionsForm from "../../components/KidneyDimensionsForm";
import CalculateTKVForm from "../../components/CalculateTKVForm";
import CalculateHTTKVForm from "../../components/CalculateHTTKVForm";
import CalculateAgeForm from "../../components/CalculateAgeForm";
import ClassificationData from "./ClassificationData";
import ClassificationChart from "../../components/ClassificationChart";
import TKVModal from "../../components/TKVModal";
import calcVolume from "../../helpers/CalculateVolume";
import QuestionMark from "../../images/questionMark";
import PatientIcon from "../../images/tkv-patient";
import PI from "../../images/pi.js";
import SagittalView from "../../images/sagittal-view.png";
import CoronalView from "../../images/coronal-view.png";
import TransverseView from "../../images/transverse-view.png";
import animate from "../../helpers/animate";
import smoothScrollTo from "../../helpers/smoothScrollTo";

import "../../css/TKVCalculator.scss";
import { Link } from "react-router-dom";

const PATIENTS = {
  patienta: {
    leftKidneyLength: "189",
    leftKidneyWidth: "93",
    leftKidneyDepth: "79",
    rightKidneyLength: "185",
    rightKidneyWidth: "98",
    rightKidneyDepth: "81",
    calculateTKV: true,
    height: "1.85",
    calculateHTTKV: true,
    displayResults: true,
    patient: "patienta"
  },
  patientb: {
    leftKidneyLength: "130",
    leftKidneyWidth: "67",
    leftKidneyDepth: "46",
    rightKidneyLength: "127",
    rightKidneyWidth: "71",
    rightKidneyDepth: "50",
    calculateTKV: true,
    height: "1.70",
    calculateHTTKV: true,
    displayResults: true,
    patient: "patientb"
  },
  user: {
    leftKidneyLength: "",
    leftKidneyWidth: "",
    leftKidneyDepth: "",
    rightKidneyLength: "",
    rightKidneyWidth: "",
    rightKidneyDepth: "",
    calculateTKV: false,
    height: "",
    age: "",
    plotGraph: false,
    calculateHTTKV: false,
    displayResults: false,
    patient: "user",
    chartClassification: "",
  }
};

const PATIENT_NAMES = {
  patienta: "Patient A",
  patientb: "Patient B",
};

export default class TKVCalculator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...PATIENTS[this.props.match.params.patient || "patienta"],
      showModal: false,
      modalType: "typical",
      stepOneAccordion: {
        open: false,
        arrowRotated: false,
      },
      stepTwoAccordion: {
        open: false,
        arrowRotated: false,
      },
      stepThreeAccordion: {
        open: false,
        arrowRotated: false,
      },
      stepFourAccordion: {
        open: false,
        arrowRotated: false,
      },
      accordionIsAnimating: false,
      skipToStep3: false,
      userInputTKVValue: "",
    };

    this.getKidneyDimensions = this.getKidneyDimensions.bind(this);
    this.getHeight = this.getHeight.bind(this);
    this.getAge = this.getAge.bind(this);
    this.plotGraph = this.plotGraph.bind(this);
    this.calculateTKV = this.calculateTKV.bind(this);
    this.calculateHTTKV = this.calculateHTTKV.bind(this);
    this.stepTwoAccordionRef = null;
    this.stepThreeAccordionRef = null;
    this.stepFourAccordionRef = null;
  }
  componentDidUpdate(prevProps) {
    if (
      this.props.match.params.patient &&
      this.state.patient !== this.props.match.params.patient
    ) {
      this.setPatient(this.props.match.params.patient);
      this.resetAccordions();
    }
  }
  // componentDidMount() {
  //   if (this.props.match.params.patient) {
  //     this.setPatient(this.props.match.params.patient);
  //   } else {
  //     this.setPatient("patienta");
  //   }
  // }
  setPatient(patientName) {
    this.setState({ ...PATIENTS[patientName] });
  }
  getKidneyDimensions(e) {
    let inputNum = Number(e.target.value.trim()).toString();
    if (!isNaN(Number(inputNum))) {
      this.setState({
        [e.target.name.split(" ").join("")]: inputNum,
        calculateTKV: false,
        chartClassification: "",
      });
    }
  }
  getHeight(e) {
    if (typeof e === "number") {
      this.setState({
        height: e.toString(),
        displayResults: false,
        chartClassification: "",
      });
    } else if (!isNaN(Number(e.target.value))) {
      this.setState({
        height: e.target.value,
        displayResults: false,
        chartClassification: "",
      });
    }
  }

  getTKV = (e) => {
    if (typeof e === "number") {
      this.setState({
        userInputTKVValue: e.toString(),
        displayResults: false,
        chartClassification: "",
      });
    } else if (!isNaN(Number(e.target.value))) {
      this.setState({
        userInputTKVValue: e.target.value,
        displayResults: false,
        chartClassification: "",
      });
    }
  };

  getAge(e) {
    let inputNum = Number(e.target.value.trim()).toString();
    if (!isNaN(Number(inputNum))) {
      this.setState({
        age: inputNum,
        plotGraph: false,
      });
    }
  }
  plotGraph() {
    const leftTKV = calcVolume(
      this.state.leftKidneyLength,
      this.state.leftKidneyWidth,
      this.state.leftKidneyDepth
    );
    const rightTKV = calcVolume(
      this.state.rightKidneyLength,
      this.state.rightKidneyWidth,
      this.state.rightKidneyDepth
    );
    let TKVValue = 0;
    if (this.state.skipToStep3) {
      TKVValue = parseFloat(this.state.userInputTKVValue);
    } else {
      TKVValue = Number((Number(leftTKV) + Number(rightTKV)).toFixed(1));
    }
    const htTKVValue = Number((Number(TKVValue) / Number(this.state.height)).toFixed(1));

    // Class1A
    if (htTKVValue <= Number(ClassificationData.Class1A[this.state.age])) {
      this.setState({ chartClassification: "Class1A", plotGraph: true });
      return true;
    }

    // Class1B
    if (htTKVValue <= Number(ClassificationData.Class1B[this.state.age])) {
      this.setState({ chartClassification: "Class1B", plotGraph: true });
      return true;
    }

    // Class1C
    if (htTKVValue <= Number(ClassificationData.Class1C[this.state.age])) {
      this.setState({ chartClassification: "Class1C", plotGraph: true });
      return true;
    }

    // Class1D
    if (htTKVValue <= Number(ClassificationData.Class1D[this.state.age])) {
      this.setState({ chartClassification: "Class1D", plotGraph: true });
      return true;
    }

    // Class1E
    if (htTKVValue > Number(ClassificationData.Class1E[this.state.age])) {
      this.setState({ chartClassification: "Class1E", plotGraph: true });
      return true;
    }
  }
  calculateTKV() {
    this.setState({
      calculateTKV: true,
    });
  }
  calculateHTTKV() {
    this.setState({
      age: "",
      displayResults: true,
      plotGraph: false,
    });

    this.scrollStep("stepFourAccordion", this.stepFourAccordionRef);
  }
  resetAccordions() {
    this.setState({
      stepOneAccordion: {
        open: false,
        arrowRotated: false,
      },
      stepTwoAccordion: {
        open: false,
        arrowRotated: false,
      },
      stepThreeAccordion: {
        open: false,
        arrowRotated: false,
      },
      stepFourAccordion: {
        open: false,
        arrowRotated: false,
      },
    })
  }
  scrollStep(animationStep, toggleEl, event) {
    let allowClosing = false;

    if (!toggleEl && !event) {
      return;
    }

    if (!toggleEl) {
      toggleEl = event.currentTarget;
      allowClosing = true;
    }
    // Bail if the accordion will close and the toggleEl was explicitly set meaning it was triggered by a form
    if (this.state[animationStep].open && !allowClosing) {
      smoothScrollTo(toggleEl, -10);
      return;
    }

    // Bail if the accordion is already animating
    if (this.state.accordionIsAnimating) {
      return;
    }

    const accordionEl = toggleEl.nextSibling;
    if (this.state[animationStep].open) {
      this.setState((prevState) => ({
        [animationStep]: {
          ...prevState[animationStep],
          arrowRotated: false,
        },
        accordionIsAnimating: true,
      }));
      animate(
        accordionEl.scrollHeight,
        0,
        300,
        (value) => {
          accordionEl.style.height = `${value}px`;
        },
        () => {
          accordionEl.style.cssText = "";
          this.setState((prevState) => ({
            [animationStep]: {
              ...prevState[animationStep],
              open: false,
            },
            accordionIsAnimating: false,
          }));
        }
      );
    } else {
      smoothScrollTo(toggleEl, -10);
      this.setState((prevState) => ({
        [animationStep]: {
          ...prevState[animationStep],
          arrowRotated: true,
        },
        accordionIsAnimating: true,
      }));
      accordionEl.style.visibility = "visible";
      animate(
        0,
        accordionEl.scrollHeight,
        300,
        (value) => {
          accordionEl.style.height = `${value}px`;
        },
        () => {
          accordionEl.style.cssText = "";
          this.setState((prevState) => ({
            [animationStep]: {
              ...prevState[animationStep],
              open: true,
            },
            accordionIsAnimating: false,
          }));
        }
      );
    }
  }

  handleSkipToStep3 = (event) => {
    const target = event.target;

    this.setState({
      skipToStep3: target.checked,
      calculateTKV: target.checked,
      displayResults: false,
      chartClassification: "",
    });
  };

  render() {
    const kidneyData = { ...this.state };
    const {
      stepOneAccordion,
      stepTwoAccordion,
      stepThreeAccordion,
      stepFourAccordion,
    } = this.state;

    var title = "Using Total Kidney Volume (TKV) to Assess Disease Progression";
    if (this.state.patient === "patienta") {
      title += " - Sample Patient A";
    }
    if (this.state.patient === "patientb") {
      title += " - Sample Patient B";
      if (this.state.patient === "user") {
        title += " - Practice Values";
      }
    }

    return (
      <Page title={title} className="tkv-calculator-app">
        <h1>MRI/CT-calculated Total Kidney Volume</h1>
        <p className="description">
          Total kidney volume (TKV) is a strong predictor of future renal insufficiency in ADPKD.
          <sup>1</sup> Various imaging modalities (MRI, CT, US) and post processing methods
          (stereology and ellipsoid calculations) are available to determine TKV.<sup>1,2</sup>
        </p>
        <p className="description">
          Once TKV has been calculated, it can be used to assess the risk of ADPKD progression 
          in <strong>Typical</strong> (Class 1) patients, more specifically by estimating TKV growth 
          and eGFR decline over time. This method can be useful in assessing younger patients who 
          may still have normal eGFR levels.<sup>2,3</sup>
        </p>
        <p className="description">
          For <strong>Atypical</strong> (class 2) patients, ADPKD progression should be classified 
          using an expanded imaging classification model which employs a recalculated htTKV measure 
          that excludes prominent exophytic cysts to improve prediction for eGFR decline.<sup>4</sup>
        </p>
        <div className="typical-buttons">
        <button
            className="open-modal typical-button"
            title="Typical Comparison"
            onClick={() => this.setState({ showModal: true, modalType: "typical" })}
          >
            <strong>Typical</strong> (Class 1)
          </button>
          <button
            className="open-modal typical-button"
            title="Atypical Comparison"
            onClick={() => this.setState({ showModal: true, modalType: "atypical" })}
          >
            <strong>Atypical</strong> (Class 2)
          </button>
        </div>
        <div className="patient-selector">
          <p className="title">
            View a hypothetical patient sample calculation or enter practice values to
            <br />
            learn how to measure TKV to assess disease progression:
          </p>
          <div className="flex">
            <Link
              to="/expert/prognostic-tools/tkv-calculator/patienta"
              className={`patient${this.state.patient === "patienta" ? " selected" : ""}`}
            >
              <span className="selector-title">
                <span className="name">Patient A (29)</span>
                <span className="height">height {PATIENTS.patienta.height} m</span>
              </span>
              <PatientIcon />
            </Link>
            <Link
              to="/expert/prognostic-tools/tkv-calculator/patientb"
              className={`patient${this.state.patient === "patientb" ? " selected" : ""}`}
            >
              <span className="selector-title">
                <span className="name">Patient B (45)</span>
                <span className="height">height {PATIENTS.patientb.height} m</span>
              </span>
              <PatientIcon />
            </Link>
            <Link
              to="/expert/prognostic-tools/tkv-calculator/user"
              className={`patient${this.state.patient === "user" ? " selected" : ""}`}
            >
              <span>Enter Practice Values</span>
              <QuestionMark />
            </Link>
          </div>
        </div>
        <div>
          <h3>
            How to Measure TKV
            <span> using MRI or CT images and the ellipsoid volume equation</span>
          </h3>

          <div className="skip-to-step-3-wrap">
            <div className="skip-to-step-3">
              <div className="input-wrap">
                <input
                  type="checkbox"
                  name="skipToStep3"
                  id="skipToStep3"
                  checked={this.state.skipToStep3}
                  onChange={this.handleSkipToStep3}
                />
                <span className="checkmark" />
              </div>
              <label htmlFor="skipToStep3">
                Skip to Step 3: How to Use TKV to Assess the Risk for ADPKD Progression.
              </label>
            </div>
            {this.state.skipToStep3 ? (
              <div className="steps-hidden-message">
                <p>
                  Steps 1 &amp; 2: How to Measure TKV using MRI or CT images are hidden. Please
                  uncheck the box above to see them.
                </p>
              </div>
            ) : null}
          </div>

          {!this.state.skipToStep3 ? (
            <>
              <p className="description">
                Determining TKV by stereology can be time-consuming and depends on specialized
                software that is not available to all clinicians. Estimation of TKV by the ellipsoid
                volume equation (shown below) requires only a few minutes and has sufficient
                precision to substitute stereology in the classification assignment.
                <sup>2,3</sup>
              </p>
              <p className="description">
                The ellipsoid volume equation uses length, width, and depth measurements determined
                from the sagittal, coronal and transverse views of an MRI or CT scan.*<sup>3,5</sup>
              </p>
              <div className="kidney-mri-container">
                <div className="sagittal-coronal-container">
                  <div>
                    <p>Sagittal View</p>
                    <img src={SagittalView} alt="Sagittal View" />
                  </div>
                  <div>
                    <p>Coronal View</p>
                    <img src={CoronalView} alt="Coronal View" />
                  </div>
                </div>
                <div>
                  <p>Transverse View</p>
                  <img src={TransverseView} alt="Transverse View" />
                </div>
              </div>
              <p className="mri footnote">
                * Imaging services such as MRI or CT may not be available in all cases. Radiologists
                may take a different approach than the example provided to determine kidney length,
                width and depth measurements from MRI or CT scans.
              </p>
              <section>
                <button
                  className={`toggle-section step animated${stepOneAccordion.open ? " open" : ""}${
                    stepOneAccordion.arrowRotated ? " arrow-rotated" : ""
                  }`}
                  onClick={this.scrollStep.bind(this, "stepOneAccordion", null)}
                >
                  <div className="instructions">
                    <div className="step-number">
                      <span>1</span>
                    </div>
                    <span>
                      <strong>Order an MRI or CT:</strong> Request length (L), width (W), and depth
                      (D) dimensions for both kidneys.
                    </span>
                  </div>
                </button>
                <div className={`padded-section${stepOneAccordion.open ? " open" : ""}`}>
                  <div className="content-wrap">
                    {this.state.patient === "patienta" ? (
                      <AnimatedKidneyDimensionsForm
                        {...kidneyData}
                        patientName={this.state.patient}
                        open={stepOneAccordion.open}
                      />
                    ) : this.state.patient === "patientb" ? (
                      <AnimatedKidneyDimensionsForm
                        {...kidneyData}
                        patientName={this.state.patient}
                        open={stepOneAccordion.open}
                      />
                    ) : (
                      <KidneyDimensionsForm
                        {...kidneyData}
                        calculateTKV={this.calculateTKV}
                        kidneyDimensionChange={this.getKidneyDimensions}
                        onGoToNextStep={this.scrollStep.bind(
                          this,
                          "stepTwoAccordion",
                          this.stepTwoAccordionRef
                        )}
                      />
                    )}
                    <div className="kidney-form-footnote">
                      <p>
                        <strong>*Length.</strong> The length can be obtained by averaging the
                        maximum longitudinal diameters taken from coronal and sagittal images.
                        Alternatively, a single, longitudinal diameter measurement can be obtained
                        from a tilted coronal image.<sup>3,4</sup>
                      </p>
                      <p>
                        <strong>
                          <sup>&#8224;</sup>Width and depth.
                        </strong>{" "}
                        Use the width and depth of the kidney obtained from transverse images at the
                        maximum transverse diameter.
                        <sup>3</sup>
                      </p>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <button
                  className={`toggle-section step animated${stepTwoAccordion.open ? " open" : ""}${
                    stepTwoAccordion.arrowRotated ? " arrow-rotated" : ""
                  }`}
                  onClick={this.scrollStep.bind(this, "stepTwoAccordion", null)}
                  ref={(el) => (this.stepTwoAccordionRef = el)}
                >
                  <div className="instructions">
                    <div className="step-number">
                      <span>2</span>
                    </div>
                    <span>
                      <strong>Calculate TKV:</strong>&nbsp;
                      <span className="calculation-instructions">
                        <span>Use the ellipsoid volume (V) formula </span>
                        <span className="no-wrap">
                          <div className="pi-formula">
                            <span className="pi-symbol">
                              <PI />
                            </span>
                            <span>6</span>
                          </div>
                          <span>• ( L x W x D ) = V.</span>
                        </span>
                        <sup>5,6</sup>
                      </span>
                    </span>
                  </div>
                </button>
                <div className={`padded-section${stepTwoAccordion.open ? " open" : ""}`}>
                  <div className="content-wrap">
                    <CalculateTKVForm step={0} open={stepTwoAccordion.open} />
                    {this.state.calculateTKV ? (
                      <span>
                        <CalculateTKVForm step={1} {...kidneyData} open={stepTwoAccordion.open} />
                        <CalculateTKVForm step={2} {...kidneyData} open={stepTwoAccordion.open} />
                        <CalculateTKVForm step={3} {...kidneyData} open={stepTwoAccordion.open} />
                        {this.state.patient === "user" ? (
                          <div className="jump-link-container">
                            <button
                              onClick={this.scrollStep.bind(
                                this,
                                "stepThreeAccordion",
                                this.stepThreeAccordionRef
                              )}
                            >
                              <span className="next-steps-link">
                                Now do steps 3 and 4 below to assess disease progression.
                              </span>
                              <span className="arrow" />
                            </button>
                          </div>
                        ) : null}
                      </span>
                    ) : null}
                  </div>
                </div>
              </section>
            </>
          ) : null}
          <h3>How to Use TKV to Assess Risk for ADPKD Progression</h3>
          <p className="description">
            With height-adjusted TKV (htTKV), you can see&nbsp;
            {this.state.patient !== "user" ? `${PATIENT_NAMES[this.state.patient]}'s` : "your"}
            &nbsp;classification for annual kidney growth and risk for eGFR decline.
            <sup>3</sup>
          </p>
          <section id="tkv-assess-disease-progression">
            <button
              className={`toggle-section step animated${stepThreeAccordion.open ? " open" : ""}${
                stepThreeAccordion.arrowRotated ? " arrow-rotated" : ""
              }`}
              onClick={this.scrollStep.bind(this, "stepThreeAccordion", null)}
              ref={(el) => (this.stepThreeAccordionRef = el)}
            >
              <div className="instructions">
                <div className="step-number">
                  <span>3</span>
                </div>
                <span>
                  <strong>Calculate htTKV:</strong> Use the TKV and height to calculate the htTKV.
                  <sup>5</sup>
                </span>
              </div>
            </button>
            <div className={`padded-section${stepThreeAccordion.open ? " open" : ""}`}>
              <div className="content-wrap">
                {/* start calculatehtTKV */}
                <CalculateHTTKVForm step={0} />
                {this.state.calculateTKV ? (
                  <span>
                    <CalculateHTTKVForm
                      step={1}
                      {...kidneyData}
                      getHeight={this.getHeight}
                      calculateHTTKV={this.calculateHTTKV}
                      open={stepThreeAccordion.open}
                      className="calculate-httkv-form-2"
                      manualCalculation={this.state.patient === "user" && this.state.skipToStep3}
                      getTKV={this.getTKV}
                      userInputTKVValue={this.state.userInputTKVValue}
                    />
                  </span>
                ) : null}
                {/* end calculatehtTKV */}
              </div>
            </div>
          </section>
          <section>
            <button
              className={`toggle-section step animated${stepFourAccordion.open ? " open" : ""}${
                stepFourAccordion.arrowRotated ? " arrow-rotated" : ""
              }`}
              onClick={this.scrollStep.bind(this, "stepFourAccordion", null)}
              ref={(el) => (this.stepFourAccordionRef = el)}
            >
              <div className="instructions">
                <div className="step-number">
                  <span>4</span>
                </div>
                <span>
                  <strong>Determine ADPKD Imaging Classification:</strong> Plot htTKV by age on the
                  graph below.<sup>3</sup>
                </span>
              </div>
            </button>
            <div className={`padded-section${stepFourAccordion.open ? " open" : ""}`}>
              <div className="content-wrap">
                {/* Determine by age */}
                {this.state.displayResults && this.state.patient === "user" ? (
                  <CalculateAgeForm
                    age={this.state.age}
                    getAge={this.getAge}
                    plotGraph={this.plotGraph}
                  />
                ) : null}
                <ClassificationChart {...this.state} open={stepFourAccordion.open} />
              </div>
            </div>
          </section>
          <Link
            className="restart-link"
            to={`/prognostic-tools/tkv-calculator/${this.state.patient}`}
          >
            Reset
          </Link>
        </div>
        <div className="footer-section">
          <p>*Classification is a simple model for selecting patients for clinical trials.</p>
          <div>
            <p className="glossary">
              ADPKD=autosomal dominant polycystic kidney disease; TKV=total kidney volume;
              MRI=magnetic resonance imaging; CT=computed tomography; US=ultrasound; eGFR=estimated
              glomerular filtration rate; htTKV=height-adjusted total kidney volume.
            </p>
          </div>
          <div className="references">
            <strong className="title">References:</strong>
            <p>
              1. Bhutani H, et al. A comparison of ultrasound and magnetic resonance imaging shows
              that kidney length predicts chronic kidney disease in autosomal dominant polycystic
              kidney disease. <i>Kidney Int.</i> 2015;88:146-151.
            </p>
            <p>
              2. Soroka S, et al. Assessing risk of disease progression and pharmacological
              management of autosomal dominant kidney disease: a Canadian expert consensus.{" "}
              <i>Can J Kidney Health Dis.</i> 2017;4:1-12.
            </p>
            <p>
              3. Irazabal MV, et al. Imaging classification of autosomal dominant polycystic kidney
              disease: a simple model for selecting patients for clinical trials.{" "}
              <i>J Am Soc Nephrol.</i> 2015;26:160-172.
            </p>
            <p>
              4. Bae, K.T. et al. Expanded imaging classification of autosomal dominant polycystic 
              kidney disease. <i>Journal of the American Society of Nephrology</i>. 2020; 31(7), 
              pp.1640-1651
            </p>
            <p>
              5. Higashihara E, et al. Kidney volume estimations with ellipsoid equations by
              magnetic resonance imaging in autosomal dominant polycystic kidney disease.{" "}
              <i>Nephron.</i> 2015;129:253-262.
            </p>
            <p>
              6. Imaging classification of ADPKD: a simple model for selecting patients for clinical
              trials.
              http://www.mayo.edu/research/documents/pkd-center-adpkd-classification/doc-20094754.
              Accessed: January 09, 2019.
            </p>
          </div>   
        </div>
        <TKVModal
          showModal={this.state.showModal}
          content={this.state.modalType}
          handleClose={() => this.setState({ showModal: false })}
        />
      </Page>
    );
  }
}
