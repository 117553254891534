import React from "react";
import { Link } from "react-router-dom";

import "../css/Tabs.scss";

const Tabs = (props) => {
  const { data, current } = props;

  return (
    <div className="tabbed-top desktop-only">
      {data.length > 1 && (
        <nav className="tab-nav">
          {data.map(({ title, slug, href }, index) => (
            <Link
              key={index}
              className={current === slug ? "tab selected" : "tab"}
              to={href}
            >
              {title}
            </Link>
          ))}
        </nav>
      )}
    </div>
  );
};

export default Tabs;
