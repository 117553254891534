import React from "react";

export default (props) => (
  <svg width="44px" height="35px" viewBox="0 0 44 35" {...props}>
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-556.000000, -429.000000)">
        <g
          id="icon-prognostic-tools-copy"
          transform="translate(556.000000, 429.000000)"
        >
          <path
            d="M21.4565543,0 C15.9411554,0 10.4257566,0 4.91035778,0 C2.98489265,0 2.00492446,0.984498329 2,2.95349499 C2,5.3015235 2,7.65447451 2,10.0074255 C2,15.2744916 2,20.5464801 2,25.8184687 C2,26.5765324 2.07879141,26.9900217 3.01936389,26.9998667 C3.95993637,27.0097117 4.00425654,26.4731601 4.00425654,25.7741663 C4.00425654,18.3165914 4.00425654,10.8590166 3.97963422,3.39651924 C3.97963422,2.26434616 4.33419558,1.91977174 5.4569732,1.9443842 C16.1463414,1.96079251 26.8373511,1.95258835 37.5300023,1.91977174 C38.716798,1.91977174 39.046737,2.29388111 39.0418125,3.45558914 C38.9925679,10.873784 39.0418125,18.291979 39.0073413,25.7101739 C39.0073413,26.4780826 39.0073413,26.9998667 40.056252,26.9801767 C41.1051626,26.9604867 40.9968244,26.3894777 40.9968244,25.7200188 C40.9968244,18.1721983 40.9968244,10.632582 40.9968244,3.10116974 C40.9919,0.925428429 40.0661009,0 37.8796392,0 L21.4565543,0 Z"
            id="Path"
            fill="#66B6CA"
          />
          <path
            d="M36.9997848,5.20854081 C36.9997848,4.24790581 36.7453425,4 35.8450081,4 C26.6231049,4.03443136 17.4012018,4.03443136 8.17929865,4 C7.30832301,4 6.99516323,4.18592935 7.00005635,5.18271728 C7.0424634,11.3803624 7.0424634,17.5866154 7.00005635,23.8014763 C7.00005635,24.767276 7.26428492,25.0100171 8.15483304,24.9996877 C12.7494742,24.9532053 17.3392223,24.9996877 21.9289703,24.9996877 C26.5187183,24.9996877 31.1916495,24.9996877 35.8254356,24.9996877 C36.686625,24.9996877 37.009571,24.8240877 36.9997848,23.8221351 C36.9541156,17.6176037 36.9541156,11.4130722 36.9997848,5.20854081 Z M25.0409956,20.2688185 C24.3070274,20.7284772 23.7492115,20.785289 23.2794719,19.8194893 C22.6237936,18.5128191 21.8115354,17.2887842 21.150964,15.982114 C20.7252624,15.1505966 20.3778508,15.1919142 19.8445005,15.8323375 C19.4775164,16.2713374 19.0420286,16.6431961 18.6358995,17.0460431 L18.3276328,16.9375843 L18.3276328,8.4416457 L25.579239,12.8419738 C24.693584,13.4927265 23.6562422,13.3584442 22.7901597,13.9368911 C23.6513491,15.4863024 24.4782866,17.0357137 25.3884073,18.5024897 C25.8728263,19.2875247 25.7847501,19.8039952 25.0409956,20.2688185 Z"
            id="Shape"
            fill="#5EC0CD"
            fillRule="nonzero"
          />
          <path
            d="M22.066328,28 L40.6636545,28 C42.9999284,28 43.91391,28.6842391 43.9969993,30.4851565 C44.0754093,32.741909 42.6085343,34.6952997 40.6098909,34.9956609 C40.4143868,34.9956609 40.2091075,34.9956609 40.0087158,34.9956609 L4.02130051,34.9956609 C1.96134161,35.0983689 0.194598851,33.3658183 0.0134666662,31.0653913 C-0.123386197,29.1440478 0.785707821,28.0492652 2.60878346,28.0383174 C6.47487683,28.0109478 10.3360826,28.0383174 14.202176,28.0383174 L22.066328,28 Z M17.8972033,31.4704609 C17.8719405,30.9311216 17.6564017,30.425114 17.2980065,30.0637623 C16.9396112,29.7024105 16.4677199,29.5153171 15.9861508,29.5436435 C14.980422,29.5221026 14.1461188,30.4101344 14.1141991,31.5361478 C14.1974544,32.6506747 14.9990855,33.5274811 15.995926,33.5943391 C16.5005219,33.5987391 16.9856614,33.3765641 17.3429343,32.9774618 C17.7002072,32.5783595 17.8998331,32.0355969 17.8972033,31.4704609 Z M29.906042,31.6127826 C29.8708289,30.5094867 29.0885418,29.6193114 28.1041045,29.5623332 C27.1196671,29.5053549 26.2596135,30.3004731 26.1230379,31.3938261 C26.1059234,31.9575886 26.2902791,32.5057144 26.6352469,32.9167248 C26.9802147,33.3277351 27.4573028,33.5676835 27.9607763,33.5833913 C28.9538342,33.5521241 29.7789456,32.7162631 29.906042,31.6127826 Z M22.1103164,29.5436435 C21.0984522,29.45478 20.2094116,30.2897954 20.1112871,31.4211957 C20.0649484,32.2040542 20.4067511,32.9520282 21.0008335,33.3678086 C21.5949158,33.7835889 22.3450187,33.7998068 22.9529869,33.4100158 C23.5609551,33.0202247 23.9282796,32.2875827 23.908954,31.5033043 C23.8582129,30.4243328 23.0747514,29.5707318 22.1103164,29.5436435 Z"
            id="Shape"
            fill="#5EC0CD"
            fillRule="nonzero"
          />
          <path
            d="M21.4674535,0 L37.886083,0 C40.0719515,0 40.9974994,0.925253545 40.9974994,3.10058369 C40.9974994,10.6469778 40.9974994,18.193372 40.9974994,25.7397662 C40.9974994,26.4090985 41.0959619,26.9849212 40.0571821,26.9996859 C39.0184022,27.0144506 39.008556,26.5075297 39.008556,25.729923 C39.008556,18.31313 39.008556,10.896337 39.0430179,3.47954392 C39.0430179,2.31805542 38.7180915,1.93909519 37.5316178,1.94401676 C26.8451496,1.96042196 16.1439119,1.95385988 5.42790491,1.92433051 C4.31527815,1.92433051 3.95096673,2.244232 3.95096673,3.40079893 C3.99035175,10.861886 3.95096673,18.3180516 3.97558237,25.7742171 C3.97558237,26.4730788 4.02481364,27.009529 2.99095691,26.9996859 C1.95710019,26.9898428 1.9964852,26.5764316 2.00140833,25.7939033 C2.00140833,20.5229111 2.00140833,15.2519188 2.00140833,9.9858481 C2.00140833,7.63334174 2.00140833,5.28083539 2.00140833,2.9332506 C2.02110084,0.984312282 3.00080317,0 4.92574593,0 C10.4396485,0 15.953551,0 21.4674535,0 Z"
            id="Path"
            fill="#5EC0CD"
          />
        </g>
      </g>
    </g>
  </svg>
);
