import React from "react";

export default () => (
  <svg width="10px" height="8px" viewBox="0 0 10 8">
    <g id="ADPKD" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="Popup-CTA2"
        transform="translate(-872.000000, -537.000000)"
        fill="#9B9B9B"
      >
        <g id="Group-14" transform="translate(410.000000, 338.000000)">
          <g id="Group-12" transform="translate(119.000000, 178.000000)">
            <g id="Group-9" transform="translate(92.000000, 5.000000)">
              <polygon
                id="Triangle"
                transform="translate(256.000000, 20.000000) rotate(-180.000000) translate(-256.000000, -20.000000) "
                points="256 16 261 24 251 24"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);
