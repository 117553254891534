import animate from "./animate";

/**
 * Smooth scroll to element
 *
 * @param {HTMLElement} el
 * @param {number} offset
 * @param {boolean} scrollToBottom
 */
export default function smoothScrollTo(el, offset = 0) {
  const currentScrollPosition =
    (document.documentElement && document.documentElement.scrollTop) ||
    document.body.scrollTop;
  const elScrollPosition =
    el.getBoundingClientRect().top + currentScrollPosition + offset;

  const cancel = animate(
    currentScrollPosition,
    elScrollPosition,
    600,
    value => {
      window.scrollTo(0, value);
    },
    () => {
      // Try to focus on element
      el.focus();

      // If that element was not able to be focused, set tabindex and then refocus
      if (document.activeElement !== el) {
        el.tabIndex = -1;
        el.focus();
        // We can hide the outline here because normally this element wouldn't be focusable.
        // We made it focusable so the tab order could be set correctly
        el.style.outline = "none";
      }

      cancelScroll();
    }
  );

  addEventListenerMulti(
    document.querySelectorAll("html, body"),
    "scroll mousedown wheel DOMMouseScroll mousewheel touchmove",
    cancelScroll
  );

  function cancelScroll() {
    cancel();
    removeEventListenerMulti(
      document.querySelectorAll("html, body"),
      "scroll mousedown wheel DOMMouseScroll mousewheel touchmove",
      cancelScroll
    );
  }
}

/**
 * Add multiple event listeners
 *
 * @param {HTMLElement | NodeList} element HTMLElement or NodeList of HTMLElements
 * @param {string} listeners Space separated list of event listeners
 * @param {Function} callback Callback function
 */
function addEventListenerMulti(element, listeners, callback) {
  function addListeners(el) {
    listeners
      .split(" ")
      .forEach(listener => el.addEventListener(listener, callback));
  }

  if (NodeList.prototype.isPrototypeOf(element)) {
    [...element].forEach(addListeners);
  } else {
    addListeners(element);
  }
}

/**
 * Remove multiple event listeners
 *
 * @param {HTMLElement | NodeList} element HTMLElement or NodeList of HTMLElements
 * @param {string} listeners Space separated list of event listeners
 * @param {Function} callback Callback function
 */
function removeEventListenerMulti(element, listeners, callback) {
  function removeListeners(el) {
    listeners
      .split(" ")
      .forEach(listener => el.removeEventListener(listener, callback));
  }

  if (NodeList.prototype.isPrototypeOf(element)) {
    [...element].forEach(removeListeners);
  } else {
    removeListeners(element);
  }
}
