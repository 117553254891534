import React, { Component } from "react";
import PropTypes from "prop-types";
import Data from "../../../profileData";
import TourSteps from "../../../images/tour-steps";
import dataLayer from "../../../helpers/dataLayer";

import "./tour.scss";

const profileIntro = {
  Bill:
    "Click on the flags below to walk through an assessment that confirms rapid disease progression risk and assesses how rapidly Bill’s disease may progress",
  Angela:
    "Click on the flags below to walk through an assessment of Angela’s likely rate of disease progression",
  Denise:
    "Click on the flags below to walk through an assessment that confirms risk of rapid disease progression and assesses how rapidly Denise’s disease may progress",
  Frank:
    "Click on the flags below to walk through an assessment that confirms rapid disease progression risk and assesses how rapidly Frank’s disease may progress",
  Jeremy:
    "Click on the flags below to walk through an assessment that confirms risk of rapid disease progression and assesses how rapidly Jeremy’s disease may progress"
};


class Tour extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: this.props.currentTourStepOpen === this.props.stepNumber
    };


    this.hideTour = this.hideTour.bind(this);
    this.handleTourStep = this.handleTourStep.bind(this);
  }
  hideTour() {
    this.props.onTourClose();
  }
  handleTourStep() {
    const scrollSelector = !this.props.scrollSelector
      ? `.tour-step[data-tour-step="${this.props.stepNumber}"]`
      : this.props.scrollSelector;
    this.props.onTourStep(this.props.stepNumber, scrollSelector);

    // Send to data layer
    dataLayer.push({
      event: "tourStep.event",
      label: `${this.props.name} - Flag ${this.props.stepNumber} Clicked`
    });
    // Clean values so they are not picked up by future tags
    dataLayer.clean("label");
  }
  render() {
    const {
      stepNumber,
      name,
      direction,
      currentTourStepOpen,
      onTourStep,
      onTourClose,
      revealOnNext,
      offset = "0rem",
      variant
    } = this.props;
    const isOpen = stepNumber === currentTourStepOpen;
    const index = stepNumber - 1;
    const nextIndex = currentTourStepOpen + 1;


    const data = Data[variant].find(caseStudy => caseStudy.tag === name);
    const currentTourData = data.tourData

    const nextScrollSelector = !this.props.nextScrollSelector
      ? `.tour-step[data-tour-step="${nextIndex}"]`
      : this.props.nextScrollSelector;
    return (
      <div className={`tour-container${isOpen ? "" : " closed"}`}>
        <div className="tour-shade" onClick={this.hideTour} />
        {isOpen ? (
          <div
            className={`tour-step open${stepNumber === 0 ? " initial animated fadeIn" : " current-step"
              }`}
            data-tour-step={stepNumber}
            style={{ marginLeft: offset + "rem" }}
          >
            <span className="close" onClick={() => onTourClose()} />
            {stepNumber === 0 ? (
              <p>{data.profileIntro}</p>
            ) : (
              <div className="contents">
                <span className="step-number">{stepNumber}</span>
                <div style={{ width: '100%', height: '100%' }}>
                  {currentTourData[index]}
                </div>
                {direction === "vert" ? null : (
                  <span
                    className="next-button"
                    onClick={() => {
                      // Send to data layer
                      dataLayer.push({
                        event: "tourStep.event",
                        label: `${this.props.name} - Flag ${nextIndex} Clicked`
                      });
                      // Clean values so they are not picked up by future tags
                      dataLayer.clean("label");
                      onTourStep(nextIndex, nextScrollSelector, revealOnNext);
                    }}
                  >
                    NEXT
                  </span>
                )}
              </div>
            )}
          </div>
        ) : (
          <div
            className="tour-step"
            onClick={this.handleTourStep}
            style={{ marginLeft: offset + "rem" }}
          >
            {stepNumber !== 0 ? TourSteps[direction](stepNumber) : null}
          </div>
        )}
      </div>
    );
  }
}

Tour.propTypes = {
  stepNumber: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  direction: PropTypes.string.isRequired,
  currentTourStepOpen: PropTypes.number.isRequired,
  onTourStep: PropTypes.func,
  onTourClose: PropTypes.func.isRequired,
  scrollSelector: PropTypes.string,
  nextScrollSelector: PropTypes.string
};

export default Tour;
