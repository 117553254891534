import React from "react";
import PropTypes from "prop-types";
import "./Background.css";

class TickMarks extends React.PureComponent {
  render() {
    const { count } = this.props;
    var numbers = [];
    var i = 0;
    while (i <= count) {
      numbers.push(i);
      i++;
    }
    return (
      <div className="tick-marks">
        {numbers.map(n => (
          <div className="tick-mark" key={n}>
            <div className="tick-divider" />
          </div>
        ))}
      </div>
    );
  }
}

class Background extends React.PureComponent {
  render() {
    const {
      segments,
      seek,
      buttonWidth,
      isRevealed,
      hasPartialData,
      width = 0
    } = this.props;
    const progress = `${100 - seek * 100}%`;
    const history = segments.filter(seg => seg.history).length;
    const tracked = segments.length - history - 1;
    const backgroundColor =
      isRevealed || hasPartialData ? "#808080" : "#dddddd";
    return (
      <div
        className="slider-container"
        style={{ left: buttonWidth / 2, width }}
      >
        <div className="slider-background-progress">
          <div className="history" style={{ flex: history }} />
          <div className="tracked" style={{ flex: tracked }} />
        </div>
        <div
          className="slider-background"
          style={{ width: progress, backgroundColor }}
        />

        <TickMarks count={segments.length - 1} />
      </div>
    );
  }
}

Background.propTypes = {
  segments: PropTypes.array.isRequired,
  seek: PropTypes.number.isRequired,
  buttonWidth: PropTypes.number.isRequired,
  width: PropTypes.number,
  isRevealed: PropTypes.bool.isRequired,
  hasPartialData: PropTypes.bool.isRequired
};

export default Background;
