import React, { Component } from "react";
import Constants from "../helpers/Constants";

export default class AnimatedInput extends Component {
  render() {
    return (
      <div className="animated-input">
        <span
          className={
            this.props.kidneyValue
              ? `animated ${Constants.animation.data}`
              : "vis-hidden"
          }
        >
          {this.props.kidneyValue}
        </span>
      </div>
    );
  }
}
