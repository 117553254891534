import React, { Component } from "react";
import PropTypes from "prop-types";
import ProfileTour from "./ProfileTour";
import Header from "./Header";
import Baseline from "./Baseline";
import Timeline from "./Timeline";
import References from "./References";
import smoothScrollTo from "../../helpers/smoothScrollTo";

import "./Profile.scss";

class Profile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isRevealed: !props.patient.hasReveal,
      hasPartialData: props.patient.hasPartialData,
      currentTourStepOpen: 0
    };
  }

  render() {
    const { patient, zoomFactor } = this.props;
    return (
      <div className="patient">
        <div className="patient-header">
          <Header patient={patient} {...this.props} />
        </div>
        <div className="patient-container">
          <div className="patient-data">
            <Baseline
              variant={this.props.variant}
              patient={patient}
              isRevealed={this.state.isRevealed}
              currentTourStepOpen={this.state.currentTourStepOpen}
              onReveal={this.handleReveal}
              onUnReveal={this.handleUnReveal}
              onTourStep={this.handleTourStep}
              onTourClose={this.handleTourClose}
            />
          </div>
          <div className="patient-timeline-container">
            <Timeline
              variant={this.props.variant}
              patient={patient}
              zoomFactor={zoomFactor}
              isRevealed={this.state.isRevealed}
              hasPartialData={this.state.hasPartialData}
              currentTourStepOpen={this.state.currentTourStepOpen}
              onReveal={this.handleReveal}
              onTourStep={this.handleTourStep}
              onTourClose={this.handleTourClose}
            />
          </div>
        </div>
        {/** Tour Step Initial */}
        <ProfileTour variant={this.props.variant}
          stepNumber={0}
          name={patient.tag}
          direction={""}
          currentTourStepOpen={this.state.currentTourStepOpen}
          onTourStep={this.handleTourStep}
          onTourClose={this.handleTourClose}
        />
        <div className="profile-acronyms">
          <p>{patient.footnote}</p>
          {patient.acronymDefinitions}
        </div>
        <div className="references">
          <strong>References:</strong>
          <ol>
            {patient.references.map(ref => (<li>{ref}</li>))}
          </ol>
        </div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isRevealed: !nextProps.patient.hasReveal,
      hasPartialData: nextProps.patient.hasPartialData
    });
  }

  handleReveal = () => {
    this.setState({
      isRevealed: true
    });
  };

  handleUnReveal = () => {
    this.setState({
      isRevealed: false
    });
  };

  handleTourStep = (currentStep, scrollSelector, revealOnNext) => {
    if (revealOnNext) {
      this.handleReveal();
    }

    if (this.props.patient.hasReveal && this.state.isRevealed) {
      if (currentStep === 4) {
        currentStep++;
        scrollSelector = ".alternate-axis";
      }
    }
    this.setState(
      {
        currentTourStepOpen: currentStep
      },
      () => {
        const scrollEl = document.querySelector(scrollSelector);

        if (!scrollEl) {
          return;
        }

        smoothScrollTo(scrollEl, -30);
      }
    );
  };

  handleTourClose = () => {
    this.setState({ currentTourStepOpen: -1 });
  };
}

Profile.propTypes = {
  patient: PropTypes.object.isRequired,
  zoomFactor: PropTypes.number.isRequired
};

export default Profile;
