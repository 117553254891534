import React, { Component } from "react";
import GenderEffectsModal from "./GenderEffectsModal";

export default class PROPKDScoreTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showGenderEffectModal: false
    };
  }
  render() {
    let i = 0;
    return (
      <div className="propkd-score-tables">
        <GenderEffectsModal
          showModal={this.state.showGenderEffectModal}
          handleClose={() => this.setState({ showGenderEffectModal: false })}
        />
        <div className="score-table">
          <div className="row header">
            <div className="col">Variable</div>
            <div className="col">Points</div>
          </div>
          <div className="row">
            <div
              className="col link"
              onClick={() => this.setState({ showGenderEffectModal: true })}
            >
              being male
            </div>
            <div className="col">1</div>
          </div>
          <div className="row">
            <div className="col">hypertension before 35 years of age</div>
            <div className="col">2</div>
          </div>
          <div className="row">
            <div className="col">
              first urologic event* before 35 years of age
            </div>
            <div className="col">2</div>
          </div>
          <div className="row">
            <div className="col">mutation</div>
            <div className="col" />
          </div>
          <div className="row indent">
            <div className="col">
              <span>
                <i>PKD2</i> mutation
              </span>
            </div>
            <div className="col">0</div>
          </div>
          <div className="row indent">
            <div className="col">
              <span>
                nontruncating <i>PKD1</i> mutation
              </span>
            </div>
            <div className="col">2</div>
          </div>
          <div className="row indent">
            <div className="col">
              <span>
              <sup>§</sup>truncating <i>PKD1</i> mutation
              </span>
            </div>
            <div className="col">4</div>
          </div>
          <div className="row result">
            <div className="col">PROPKD Score =</div>
            <div className="col">SUM</div>
          </div>
        </div>
        <div className="example-calc">
          <h2 className="title">Sample PROPKD Score Calculation</h2>
          <p className="description">
            ADPKD patient info: 29 year old male with hypertension and a
            truncating <i>PKD1</i> mutation
          </p>
          <div className="point-container">
            <p>
              <strong>1</strong> point for being male
            </p>
            <p>
              <strong>2</strong> points for hypertension before 35 years of age
            </p>
            <p className="total">
              <strong>4</strong> points for a truncating <i>PKD1</i> mutation
            </p>
            <hr />
            <p className="score">
              <strong>7</strong> points <strong>PROPKD Score</strong>
            </p>
          </div>
          <p className="classification">
            <span className="red">HIGH</span> Risk of Progression to ESKD
          </p>
        </div>
        <div className="score-table-classification">
          <div className="header row">
            <div className="col">Total Points: PROPKD Score</div>
            <div className="col">Risk of Progression to ESKD</div>
          </div>
          <div className="yellow row">
            <div className="col">
              <div className="row">{i++}</div>
              <div className="row">{i++}</div>
              <div className="row">{i++}</div>
              <div className="row">{i++}</div>
            </div>
            <div className="col content">
              <h1 className="title">LOW</h1>
              <p className="subtitle">
                <strong>70.6 median age for ESKD onset</strong>
              </p>
              <p className="description">
                Eliminates evolution to ESKD before age 60<sup>&#8224;</sup>
              </p>
              <p className="footnote">
                <sup>&#8224;</sup>negative predictive value of 81.4%
              </p>
            </div>
          </div>
          <div className="orange row">
            <div className="col">
              <div className="row">{i++}</div>
              <div className="row">{i++}</div>
              <div className="row">{i++}</div>
            </div>
            <div className="col content">
              <h1 className="title">INTERMEDIATE</h1>
              <p className="subtitle">
                <strong>56.9 median age for ESKD onset</strong>
              </p>
            </div>
          </div>
          <div className="red row">
            <div className="col">
              <div className="row">{i++}</div>
              <div className="row">{i++}</div>
              <div className="row">{i++}</div>
            </div>
            <div className="col content">
              <h1 className="title">HIGH</h1>
              <p className="subtitle">
                <strong>49 median age for ESKD onset</strong>
              </p>
              <p className="description">
                Risk of rapid progression and a 92% chance of reaching kidney
                failure before age 60.
                {/* Forecasts ESKD onset before age 60<sup>&#8225;</sup> */}
              </p>
              {/* <p className="footnote">
                <sup>&#8225;</sup>positivepredictive value of 90.9%
              </p> */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
