import React from "react";
import AlgorithmIcon from "../../images/icon-algorithm.png";
import GeneticsIcon from "../../images/icon-genetics.png";
import GuideIcon from "../../images/icon-guide.png";
import KidneyMeasureIcon from "../../images/icon-kidney-measure.png";
import PROPKDIcon from "../../images/icon-propkd.png";
import RiskIcon from "../../images/icon-risk.png";
import TKVCalcIcon from "../../images/icon-tkv-calc.png";

import AlgorithmFlowchart from "./AlgorithmFlowchart";
import GeneticTesting from "./GeneticTesting";
import OtherRiskFactors from "./OtherRiskFactors";
import BloodTestInformation from "./patient/BloodTest";
import FamilyHistory from "./patient/FamilyHistory";
import ImagingInformation from "./patient/Imaging";
import PROPKDScore from "./PROPKDScore";
import TKVCalculator from "./TKVCalculator";
import UltrasoundKidneyLength from "./UltrasoundKidneyLength";
import USPRacticalGuide from "./USPracticalGuide";

import BloodTestTube from './icons/Blood Test Tube.svg';
import DNA from './icons/DNA.svg';
import MRIScan from './icons/MRI Scan.svg';

const tools = {
  expert: [
    {
      component: TKVCalculator,
      icon: TKVCalcIcon,
      name: "MRI/CT-calculated Total Kidney Volume",
      url: "/expert/prognostic-tools",
      param: "tkv-calculator"
    },
    {
      component: PROPKDScore,
      icon: PROPKDIcon,
      name: "PROPKD Score",
      url: "/expert/prognostic-tools",
      param: "propkd-score"
    },
    {
      component: AlgorithmFlowchart,
      icon: AlgorithmIcon,
      name: "ERA-EDTA Algorithm",
      url: "/expert/prognostic-tools",
      param: "era-edta-algorithm"
    },
    {
      component: UltrasoundKidneyLength,
      icon: KidneyMeasureIcon,
      name: "Ultrasound Kidney Length",
      url: "/expert/prognostic-tools",
      param: "ultrasound-kidney-length"
    },
    {
      component: USPRacticalGuide,
      icon: GuideIcon,
      name: "US Practical guide / Expert opinion",
      url: "/expert/prognostic-tools",
      param: "us-practical-guide"
    },
    {
      component: GeneticTesting,
      icon: GeneticsIcon,
      name: "Genetics in ADPKD",
      url: "/expert/prognostic-tools",
      param: "genetic-testing"
    },
    {
      component: OtherRiskFactors,
      icon: RiskIcon,
      name: "Other Risk Factors",
      url: "/expert/prognostic-tools",
      param: "other-risk-factors"
    }
  ],
  patient: [
    {
      component: BloodTestInformation,
      icon: BloodTestTube,
      name: <div><p>How well are your kidneys working?</p></div>,
      mainHeading: 'Blood test to measure creatinine/eGFR',
      url: "/patient/information",
      param: "blood-test"
    },
    {
      component: ImagingInformation,
      icon: MRIScan,
      name: <div><p>How big are your kidneys?</p></div>,
      mainHeading: 'Imaging (MRI, CT, or ultrasound)',
      url: "/patient/information",
      param: "imaging"
    },
    {
      component: FamilyHistory,
      icon: DNA,
      name: <div> <p>Has anyone had kidney disease in your family?</p> <p>What gene is altered?</p></div>,
      mainHeading: 'Patient / Family History and Genetic Testing',
      url: "/patient/information",
      param: "family-history"
    }
  ]
};

export default tools;
