/**
 * dataLayer provides a simple API for working with the Google Tag Manager
 * data layer.
 **/

// Add data layer(s) to the dataLayer
export function pushDataLayer(...data) {
  // If the dataLayer is not present calling this function is a no-op
  if (!dataLayerIsPresent) {
    return;
  }

  window.dataLayer.push(...data);
}

// Remove data from the data layer by key name
export function cleanDataLayer(...keys) {
  // To "clean" the values they must are set to undefined, this function
  // creates an object with the requested keys set to undefined and then
  // pushes the new object into the data layer.

  let data = {};
  for (let i = 0; i < keys.length; i++) {
    let key = keys[i];
    data[key] = undefined;
  }
  pushDataLayer(data);
}

// Check if the Google Tag Manager data layer is available (on the global
// window object).
export function dataLayerIsPresent() {
  return (
    window.dataLayer !== undefined &&
    typeof window.dataLayer === "object" &&
    typeof window.dataLayer.push === "function"
  );
}

export default {
  push: pushDataLayer,
  clean: cleanDataLayer
};
