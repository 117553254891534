import React, { Component } from "react";
import { Page } from "../../components/Page";
import PROPKDScoreTable from "../../components/PROPKDScoreTable";
import PROPKDScoreImage from "../../images/propkd-score.png";

export default class PROPKDScore extends Component {
  render() {
    return (
      <Page title="Prognostic Tools - PROPKD Score" className="propkd-score">
        <h1 className="title">PROPKD Score</h1>
        <p>
          Predicting Renal Outcome in Polycystic Kidney Disease (PROPKD) Scoring system predicts
          the likely hood of kidney failure before 60 years old.
        </p>
        <p>
          Multivariate survival analysis identified four variables that were significantly
          associated with age at ESKD onset, and a scoring system from 0 to 9 was developed as
          follows:<sup>1</sup>
        </p>
        <p className="calculator-title">PROPKD Calculator</p>
        <div className="img-container">
          <PROPKDScoreTable />
          <img className="total-points" src={PROPKDScoreImage} alt="PROPKD Calculator" />
        </div>
        <div className="footer-section">
          <p className="adapted-container">
            Adapted with permission of The American Society of Nephrology, from Cornec-Le Gall E, et
            al. The PROPKD score: a new algorithm to predict renal survival in autosomal dominant
            polycystic kidney disease. <i>J Am Soc Nephrol.</i> 2016;27(3):942-951.
          </p>
          <div className="limitation-container">
            <strong>Limitations</strong>
            <ul>
              <li>
                PROPKD score may not be helpful identifying rapid progression in patients &lt; 35
                years old unless they are already hypertensive and have experienced urologic
                complications.<sup>2</sup>
              </li>
              <li>
                PROPKD score requires genetic testing.<sup>1</sup>
              </li>
              <li>
                The genetic-based scoring systems are shown to be accurate prognosis for ADPKD
                patients on a subpopulation level but precision is yet to be demonstrated on an
                individual level.
              </li>
            </ul>
          </div>
          <p className="footnote">
            *previous urological events defined as gross hematuria, cyst infections, and flank pain
            related to cysts<sup>1</sup>
            <br />
            <sup>§</sup>up to 18% of truncating mutations lead to mild, not severe disease
          </p>
          <p className="glossary">
            ESKD=end-stage kidney disease; PKD=polycystic kidney disease; ADPKD=autosomal dominant
            polycystic kidney disease.
          </p>
          <div className="references">
            <strong>References:</strong>
            <p>
              1. Cornec-Le Gall E, et al. The PROPKD score: a new algorithm to predict renal
              survival in autosomal dominant polycystic kidney disease. <i>J Am Soc Nephrol.</i>
              2016;27(3):942-951.
            </p>
            <p>
              2. Chebib FT, Perrone RD, Chapman AB, Dahl NK, Harris PC, Mrug M,
              Mustafa RA, Rastogi A, Watnick T, Alan SL, Torres VE. A practical
              guide for treatment of rapidly progressive ADPKD with tolvaptan.
              <i>Journal of the American Society of Nephrology</i>. 2018;
              29(10):2458-70.
            </p>
          </div>
        </div>
      </Page>
    );
  }
}
