import React, { Component } from "react";
import "./index.scss";

export default class MRIScans extends Component {
  constructor(props) {
    super(props);
    this.state = {};

    this.handleClick = this.handleClick.bind(this);
  }
  handleClick(e) {
    if (e.target.className === "mri-modal") {
      this.props.onClose();
    }
  }
  render() {
    const patient = this.props.patient;
    const showModal = this.props.isShowing;
    const modalContent = (
      <div className="modal-content">
        <span className="close" onClick={this.props.onClose} />
        <div className="title-container">
          <h1 className="title-bar">
            MRI Scans and Measurements:&nbsp;{patient.tag}
          </h1>
        </div>
        <div className="mri-scan-container">
          <div className="image-container">
            <p className="title">Coronal View</p>
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/images/${patient.tag}-coronal.png`}
              alt="Coronal View"
            />
            <div className="image-label-container">
              <span className="image-label">Right Kidney</span>
              <span className="image-label">Left Kidney</span>
            </div>
            <div className="measurement-container">
              <span className="kidney-measurements">
                Length:*&nbsp;
                <strong>{patient.kidneyDimensions["right"].coronal}</strong>
              </span>
              <span className="kidney-measurements">
                Length:*&nbsp;
                <strong>{patient.kidneyDimensions["left"].coronal}</strong>
              </span>
            </div>
          </div>
          <div className="image-container">
            <p className="title">Transverse View</p>
            <img
              src={`${process.env.REACT_APP_PUBLIC_URL}/images/${patient.tag}-transverse.png`}
              alt="Transverse View"
            />
            <div className="image-label-container">
              <span className="image-label">Right Kidney</span>
              <span className="image-label">Left Kidney</span>
            </div>
            <div className="measurement-container">
              <span className="transverse-contianer">
                <span className="kidney-measurements">
                  Width:&nbsp;
                  <strong>
                    {patient.kidneyDimensions["right"].transverse.width}
                  </strong>
                </span>
                <span className="kidney-measurements">
                  Depth:&nbsp;
                  <strong>
                    {patient.kidneyDimensions["right"].transverse.depth}
                  </strong>
                </span>
              </span>
              <span className="transverse-contianer">
                <span className="kidney-measurements">
                  Width:&nbsp;
                  <strong>
                    {patient.kidneyDimensions["left"].transverse.width}
                  </strong>
                </span>
                <span className="kidney-measurements">
                  Depth:&nbsp;
                  <strong>
                    {patient.kidneyDimensions["left"].transverse.depth}
                  </strong>
                </span>
              </span>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <p>
            MRI scans are from the seminal studies in ADPKD disease
            progression developed and implemented by the Consortium for
            Radiologic Imaging Studies of Polycystic Kidney Disease (CRISP),
            courtesy of the University of Kansas PKD Center.
          </p>
          <p>
            <strong>*Length.</strong> The length can be obtained by averaging
            the maximum longitudinal diameters taken from coronal and sagittal
            images. Alternatively, a single, longitudinal diameter measurement
            can be obtained from a tilted coronal image. In this scenario, a
            single longitudinal measurement from a coronal image is being used.
          </p>
          <p>
            Radiologists may take a different approach than the example provided
            to determine kidney length, width and depth measurements from MRI or
            CT scans.
          </p>
        </div>
      </div>
    );
    return (
      showModal && (
        <div className="mri-modal" onClick={this.handleClick}>
          {modalContent}
        </div>
      )
    );
  }
}
