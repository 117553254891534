import React, { Component } from "react";
import { Page } from "../../components/Page";

import GeneticTestingIcon from "../../images/icon-genetic-testing.png";
import ChallengesIcon from "../../images/icon-challenges.png";
import IndicationsIcons from "../../images/icon-indications.png";

import "../../css/GeneticTesting.scss";

export default class GeneticTesting extends Component {
  render() {
    return (
      <Page
        title="Prognostic Tools - Genetic Testing"
        className="genetic-testing"
      >
        <h1>Genetics in ADPKD</h1>
        <section>
          <div className="title">
            <img
              className="icon"
              src={GeneticTestingIcon}
              alt="Genetics in ADPKD"
            />
            <h3>
              Genetics in ADPKD<sup>1</sup>
            </h3>
          </div>
          <div className="content">
            <p className="description">
              Average age at onset of kidney failure is associated with:
              <ul className="list image dna">
                <li>PKD1 truncating - most severe (56 YO)</li>
                <li>PKD1 non-truncating – intermediate (68 YO) </li>
                <li>PKD2 mutation - least severe (79 YO)</li>
                <li>
                  family history of member with Kidney failure &lt; 55 YO = PKD1
                  mutation prediction
                </li>
                <li>
                  family history of member with Kidney failure &gt; 70 YO = PKD2
                  mutation prediction<sup>1</sup>
                </li>
              </ul>
            </p>
          </div>
        </section>

        <section>
          <div className="title">
            <img
              className="icon"
              src={ChallengesIcon}
              alt="Challenges associated with genetic testing in ADPKD "
            />
            <h3>Challenges associated with genetic testing in ADPKD </h3>
          </div>
          <div className="content">
            <p className="description">
              Analysis of PKD1 is technically challenging due to its large size,
              high guanine–cytosine (GC) content and duplication of the first 33
              exons with a high degree of homology  (&gt;98% identity) to six
              nearby pseudogenes (PKD1P1–P6)<sup>2</sup>
            </p>
            <p className="description">
              To date, &gt;1250 and 200 pathogenic germline mutations in PKD1
              and PKD2, respectively, have been archived at the Mayo polycystic
              kidney disease (PKD) database, indicating a high degree of allelic
              heterogeneity, with no single mutation accounting for &gt;2% of
              patients<sup>2,3</sup>
            </p>
            <p className="description">
              Additionally, pathogenic mutations are found throughout the length
              of both genes with no obvious clustering or "hot spots"
              <sup>4</sup>
            </p>
          </div>
        </section>

        <section>
          <div className="title">
            <img
              className="icon"
              src={IndicationsIcons}
              alt="Current and evolving indications for genetic testing in ADPKD"
            />
            <h3>
              Current and evolving indications for genetic testing in ADPKD
              <sup>5</sup>
            </h3>
          </div>
          <div className="content">
            <p className="description">
              Scenarios where genetic testing is clinically indicated:
              <ul className="list image dna">
                <li>Suspected ADPKD with no apparent family history</li>
                <li>Suspected ADPKD with equivocal renal imaging findings</li>
                <li>
                  <details>
                    <summary>
                      ADPKD exclusion in young (e.g. &lt;25 years old) at-risk
                      subjects
                    </summary>
                    <main>
                      <ul>
                        <li>Living-related kidney donation evaluation </li>
                        <li>Obtaining life or disability insurance</li>
                      </ul>
                    </main>
                  </details>
                </li>
                <li>Prenatal and preimplantation genetic diagnosis</li>
                <li>
                  <details>
                    <summary>
                      Scenarios with evolving indication for genetic testing
                    </summary>
                    <main>
                      <ul>
                        <li>
                          Identifying ‘high-risk’ patients for novel disease
                          modifier therapy or clinical trial
                        </li>
                      </ul>
                    </main>
                  </details>
                </li>
                <li>
                  <details>
                    <summary>
                      Delineating the cause of atypical clinical presentation
                    </summary>
                    <main>
                      <ul>
                        <li>Early and severe disease</li>
                        <li>
                          Discrepancy between imaging findings and decrease in
                          renal function
                        </li>
                        <li>
                          Asymmetric, unilateral, segmental or lopsided cystic
                          kidneys
                        </li>
                        <li>Marked intrafamilial disease discordance</li>
                        <li>Suspected somatic mosaicism</li>
                        <li>Syndromic forms of polycystic kidney disease</li>
                      </ul>
                    </main>
                  </details>
                </li>
              </ul>
            </p>
          </div>
        </section>

        <div className="footer-section">
          <div className="references">
            <strong className="title">References:</strong>
            <p>
              1. Chebib FT, et al. Assessing Risk of Rapid Progression in
              Autosomal Dominant Polycystic Kidney Disease and Special
              Considerations for Disease-Modifying Therapy.{" "}
              <i>Am J Kidney Dis</i>. 2021;78(2):282-292
            </p>
            <p>
              2. Harris PC, Torres VE. Genetic mechanisms and signaling pathways
              in autosomal dominant polycystic kidney disease.{" "}
              <i>J Clin Invest</i>
              2014; 124:2315–2324
            </p>
            <p>3. http://pkdb.mayo.edu</p>
            <p>
              4. Song X, Haghighi A, Iliuta IA, Pei Y. Molecular diagnosis of
              autosomal dominant polycystic kidney disease.{" "}
              <i>Expert review of molecular diagnostics</i>. 2017 Oct
              3;17(10):885-95.
            </p>
            <p>
              5. Lanktree MB, IliutaI-A, HaghighiA,SongX,PeiY: Evolving role of
              genetic testing for the clinical management of autosomal dominant
              polycystic kidney disease. <i>Nephrol Dial Transplant</i> 34:
              1453–1460, 2019
            </p>
          </div>
        </div>
      </Page>
    );
  }
}
