import React, { Component } from "react";

import TypicalA from "../images/typical-a.png";
import TypicalB from "../images/typical-b.png";
import TypicalAMRI from "../images/typical-a-mri.png";
import TypicalBMRI from "../images/typical-b-mri.png";


import AtypicalA from "../images/atypical-a.png";
import AtypicalB from "../images/atypical-b.png";
import AtypicalC from "../images/atypical-c.png";
import AtypicalD from "../images/atypical-d.png";
import AtypicalE from "../images/atypical-e.png";
import AtypicalF from "../images/atypical-f.png";
import AtypicalAMRI from "../images/atypical-a-mri.png";
import AtypicalBMRI from "../images/atypical-b-mri.png";
import AtypicalCMRI from "../images/atypical-c-mri.png";
import AtypicalDMRI from "../images/atypical-d-mri.png";
import AtypicalEMRI from "../images/atypical-e-mri.png";
import AtypicalFMRI from "../images/atypical-f-mri.png";

import "../css/TKVModal.scss";



const kidneyImages = {
  typical: {
    illustrations: [
      { caption: 'A', url: TypicalA },
      { caption: 'A', url: TypicalB }
    ],
    mri: [
      { caption: 'A', url: TypicalAMRI },
      { caption: 'A', url: TypicalBMRI },
    ]
  },
  atypical: {
    illustrations: [

      { caption: 'A', url: AtypicalA },
      { caption: 'B', url: AtypicalB },
      { caption: 'C', url: AtypicalC },
      { caption: 'D', url: AtypicalD },
      { caption: 'E', url: AtypicalE },
      { caption: 'F', url: AtypicalF }
    ],
    mri: [

      { caption: 'A', url: AtypicalAMRI },
      { caption: 'B', url: AtypicalBMRI },
      { caption: 'C', url: AtypicalCMRI },
      { caption: 'D', url: AtypicalDMRI },
      { caption: 'E', url: AtypicalEMRI },
      { caption: 'F', url: AtypicalFMRI }
    ]
  }
};

export default class TKVModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentView: "illustrations"
    };

    this.handleClick = this.handleClick.bind(this);
  }
  handleChange(e) {
    this.setState({ currentView: e.currentTarget.value });
  }
  handleClick(e) {
    if (e.target.className === "tkv-modal") {
      this.props.handleClose();
    }
  }
  render() {
    const showModal = this.props.showModal;
    const modalContent = (
      <div className="modal-content">
        <div className="view-switch">
          <strong>View as:</strong>
          <fieldset className="radio-row">
            <label className="radio-container">
              Illustrations
              <input
                type="radio"
                name="illustrations"
                value="illustrations"
                checked={this.state.currentView === "illustrations"}
                onChange={this.handleChange.bind(this)}
              />
              <span className="checkmark" />
            </label>
            <label className="radio-container">
              MRI scans
              <input
                type="radio"
                name="mri"
                value="mri"
                checked={this.state.currentView === "mri"}
                onChange={this.handleChange.bind(this)}
              />
              <span className="checkmark" />
            </label>
          </fieldset>
        </div>
        <span className="close" onClick={this.props.handleClose} />
        {this.props.content === "typical" && <div className="typical">

          <div className="content-container">
            <h1 className="title">Typical</h1>
            <p className="disclaimer">
              Risk of disease progression can be assessed by ADPKD Imaging
              Classification for typical (Class 1) ADPKD patients.
            </p>
            <p className="description">
              Typical (or Class 1) ADPKD patients present with bilateral and
              diffuse distribution, from early (A) to late stage (B) destruction
              of kidney tissue by cysts, where all cysts contribute similarly to
              the patient’s TKV.<sup>1</sup>
            </p>
          </div>
          <div className="img-container">
            <div className="img-row">
              <span>
                <img class='kidney-row-item'
                  src={kidneyImages.typical[this.state.currentView][0].url}
                  alt="Early"
                />
                <p>(A) Early</p>
              </span>
              <span>
                <img class='kidney-row-item'
                  src={kidneyImages.typical[this.state.currentView][1].url}
                  alt="Late Stage"
                />
                <p>(B) Late Stage</p>
              </span>
            </div>
          </div>
        </div>}
        {this.props.content === "atypical" && <div className="atypical">
          <div className="content-container">
            <h1 className="title">Atypical</h1>
            <p className="disclaimer">
              Using a recalculated htTKV may allow inclusion of class 2 patients
              in the Mayo classification of ADPKD and re-classification of class
              1 patients with prominent exophytic cysts.
            </p>
            <p className="description">
              Atypical (or Class 2) ADPKD includes ADPKD patients that present
              with unilateral (A), segmental (B), asymmetric (C), or lopsided
              presentation (D); subclass 2A, but also includes patients that
              present with bilateral distribution with acquired unilateral
              atrophy (E) or bilateral kidney atrophy (F); subclass 2B.{" "}
              <sup>1,2</sup>
            </p>
            <div className="footer-section">
              <p className="glossary">
                ADPKD=autosomal dominant polycystic kidney disease;
                <br />
                TKV=total kidney volume.
              </p>
              <div className="references">
                <strong>References:</strong>
                <p>
                  1. Irazabal MV, et al. Imaging classification of autosomal
                  dominant polycystic kidney disease: a simple model for
                  selecting patients for clinical trials.{" "}
                  <i>J Am Soc Nephrol.</i>
                  2015;26:160-172.
                </p>
                <p>
                  2. Soroka S, et al. Assessing risk of disease progression and
                  pharmacological management of autosomal dominant polycystic
                  kidney disease: a Canadian expert consensus.{" "}
                  <i>Can J Kidney Health Dis.</i> 2017;4:1-12.
                </p>
              </div>
            </div>
          </div>
          <div className="img-container">
            <div className="img-row">
              <span>
                <img class='kidney-row-item'
                  src={kidneyImages.atypical[this.state.currentView][0].url}
                  alt="Unilateral"
                />
                <p>(A) Unilateral</p>
              </span>
              <span>
                <img class='kidney-row-item'
                  src={kidneyImages.atypical[this.state.currentView][1].url}
                  alt="Segmental"
                />
                <p>(B) Segmental</p>
              </span>
            </div>
            <div className="img-row">
              <span>
                <img class='kidney-row-item'
                  src={kidneyImages.atypical[this.state.currentView][2].url}
                  alt="Asymmetric"
                />
                <p>(C) Asymmetric</p>
              </span>
              <span>
                <img class='kidney-row-item'
                  src={kidneyImages.atypical[this.state.currentView][3].url}
                  alt="Bilateral Atypical"
                />
                <p>(D) Lopsided</p>
              </span>
            </div>
            <div className="img-row">
              <span>
                <img class='kidney-row-item'
                  src={kidneyImages.atypical[this.state.currentView][4].url}
                  alt="Bilateral distribution with acquired unilateral atrophy"
                />
                <p>
                  (E) Bilateral distribution with acquired unilateral atrophy
                </p>
              </span>
              <span>
                <img class='kidney-row-item'
                  src={kidneyImages.atypical[this.state.currentView][5].url}
                  alt="Bilateral distribution with acquired bilateral atrophy"
                />
                <p>
                  (F) Bilateral distribution with acquired bilateral atrophy
                </p>
              </span>
            </div>
            {this.state.currentView === "illustrations" ? null : (
              <p className="footnote">
                Adapted with permission of The American Society of Nephrology,
                from Irazabal MV, et al. Imaging classification of autosomal
                polycystic kidney disease: a simple model for selecting patients
                for clinical trials. <i>J Am Soc Nephrol.</i>{" "}
                2015;26(1):160-172.
              </p>
            )}
          </div>
        </div>}
      </div>
    );
    return (
      showModal && (
        <div className="tkv-modal" onClick={this.handleClick}>
          {modalContent}
        </div>
      )
    );
  }
}
