/**
 * Animate value
 *
 * @param {number} from
 * @param {number} to
 * @param {number} duration
 * @param {function} update
 * @param {function} done
 */
export default function animate(
  from,
  to,
  duration,
  update,
  done = function() {}
) {
  let cancel = false;
  let start = null;
  const change = to - from;

  function easeInOutQuad(t, b, c, d) {
    return -c * (t /= d) * (t - 2) + b;
  }

  function loop(timestamp) {
    if (cancel) {
      done();
      return;
    }

    start = !start ? timestamp : start;
    const progress = timestamp - start;

    update(easeInOutQuad(progress, from, change, duration));

    if (progress < duration) {
      window.requestAnimationFrame(loop);
    } else {
      done();
    }
  }

  window.requestAnimationFrame(loop);

  return function cancelLoop() {
    cancel = true;
  };
}
