import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Page } from "../../components/Page";


import ChartIcon from "../../images/icon-chart.svg";
import DropIcon from "../../images/icon-drop.svg";
import EGFRIcon from "../../images/icon-egfr.svg";
import HeartIcon from "../../images/icon-heart.svg";
import HelixIcon from "../../images/icon-helix.svg";
import MaleIcon from "../../images/icon-male.svg";
import RiskIcon from "../../images/icon-risk.svg";
import TKVIcon from "../../images/icon-tkv.svg";
import USKLIcon from "../../images/icon-uskl.svg";

import data from "../../profileData";

import "../../css/SimulatorHomepage.scss";

const patients = data.patients;


const patientRiskDescriptions = {
  Bill: (
    <>
      <p>Being male</p>
      <p>Hypertension before age 35</p>
      <p>Urologic events before age 35</p>
      <p>
        Truncating <i>PKD1</i> mutation
      </p>
      <p>TKV greater than expected for age</p>
    </>
  ),
  Angela: (
    <>
      <p>Urologic events before age 35</p>
    </>
  ),
  Denise: (
    <>
      <p>US-KL greater than expected for age*</p>
      <p>Hypertension before age 35</p>
      <p>Urologic events before age 35</p>
      <p>Family history of ESKD by age 58</p>
    </>
  ),
  Frank: (
    <>
      <p>Being male</p>
      <p>
        eGFR decline <span className="symbol">&ge;</span> 5 mL/min/1.73 m
        <sup>2</sup> within a year
      </p>
      <p>Hypertension before age 35</p>
      <p>Urologic events before age 35</p>
      <p>Family history of ESKD by age 58</p>
      <p>TKV greater than expected for age</p>
    </>
  ),
  Jeremy: (
    <>
      <p>Being male</p>
      <p>Urologic events before age 35</p>
      <p>Family history of ESKD by age 58</p>
    </>
  )
};

const riskFactors = [
  <div className="risk-factor">
    <div className="icon">
      <img src={MaleIcon} alt="Male Icon" />
    </div>
    <p>Being male</p>
  </div>,
  <div className="risk-factor">
    <div className="icon">
      <img src={EGFRIcon} alt="eGFR Icon" />
    </div>
    <p>Rapid historical eGFR decline*</p>
  </div>,
  <div className="risk-factor">
    <div className="icon">
      <img src={USKLIcon} alt="US-KL Icon" />
    </div>
    <p>US-KL >16.5 cm in patients less than 45 years old†</p>
  </div>,
  <div className="risk-factor">
    <div className="icon">
      <img src={HeartIcon} alt="Heart Icon" />
    </div>
    <p>Hypertension before age 35</p>
  </div>,
  <div className="risk-factor">
    <div className="icon">
      <img src={DropIcon} alt="Drop Icon" />
    </div>
    <p>Urologic events before age 35</p>
  </div>,
  <div className="risk-factor">
    <div className="icon">
      <img src={ChartIcon} alt="Chart Icon" />
    </div>
    <p>Family history of ESKD by age 58</p>
  </div>,
  <div className="risk-factor">
    <div className="icon">
      <img src={HelixIcon} alt="Helix Icon" />
    </div>
    <p>
      Truncating <i>PKD1</i> mutation
    </p>
  </div>,
  <div className="risk-factor">
    <div className="icon">
      <img src={TKVIcon} alt="TKV Icon" />
    </div>
    <p>TKV greater than expected for age</p>
  </div>,
  <div className="risk-factor">
    <div className="icon">
      <img src={RiskIcon} alt="Other Risk Factors Icon" />
    </div>
    <p>Other Risk Factors</p>
  </div>
];

const factorIndicators = [
  // being male
  <>
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col" />
    <div className="col" />
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col">
      <span className="circle" />
    </div>
  </>,
  // being Frank
  <>
    <div className="col" />
    <div className="col" />
    <div className="col" />
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col" />
  </>,
  // US-KL
  <>
    <div className="col" />
    <div className="col" />
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col" />
    <div className="col" />
  </>,
  // Hypertension
  <>
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col" />
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col" />
  </>,
  // Urologic
  <>
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col">
      <span className="circle" />
    </div>
  </>,
  // history
  <>
    <div className="col" />
    <div className="col" />
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col">
      <span className="circle" />
    </div>
  </>,
  // mutation
  <>
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col" />
    <div className="col" />
    <div className="col" />
    <div className="col" />
  </>,
  // TKV
  <>
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col" />
    <div className="col" />
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col" />
  </>,
  // Other Risk Factors
  <>
    <div className="col" />
    <div className="col" />
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col">
      <span className="circle" />
    </div>
    <div className="col" />
  </>
];
export default class ADPKDSimulatorHomepage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
  }
  render() {
    const patientSubset = data[this.props.variant]

    const makeProfileLinks = patientSubset.map((patient) => {
      return (
        <div key={patient.tag} className="col">
          <Link
            className="link-reset"
            key={patient.tag}
            to={`/${this.props.variant}/adpkd-simulator/patient-profiles/${patient.tag}`}
          >
            <img src={patient.profilePic} alt={patient.tag} />
            <div className="profile-data">
              <div className="text-link">
                {patient.tag}&nbsp;({patient.age.value})
              </div>
              <div className="risk-factors">
                <h3>Known Risk Factors</h3>
                {patientRiskDescriptions[patient.tag]}
              </div>
            </div>
          </Link>
        </div>
      );
    });

    const sexLensedPredicate = (patient) => patient.sex.value === 'M';
    const eGRFLensedPredicate = (patient) => patient.eGFR.value === '';
    const kidneyLengthLensedPredicate = (patient) => patient.kidneyLength.value > 16.5 && patient.age.value < 45;
    const hyperBefore35LensedPredicate = (patient) => patient.history.hyperBefore35.value === 'Yes';
    const urologicalBefore35LensedPredicate = (patient) => patient.history.urologicalBefore35.value === 'Yes';

    const familyESRDLensedPredicate = (patient) => {
      const regex = /\d+/g;
      var matches = patient.history.familyESRD.value.match(regex) // creates array from matches

      return patient.history.familyESRD.value.includes('Yes') && matches.find(x => Number(x) < 59)

    }


    const mutationsLensedPredicate = (patient) => patient.mutations.value === 'Truncating PKD1';
    const tKVLensedPredicate = (patient) => true //patient.tKV.value === true //hacky as fuck;
    const otherRiskFactorsLensedPredicate = (patient) => {
      if (!!patient['otherRiskFactors']) {
        if (!!patient.otherRiskFactors['available']) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    }


    const riskRow = (lensedPredicate) =>
    (<>{patientSubset.map(patient => {


      return (
        <div className="col">
          {lensedPredicate(patient) ? <span className="circle" /> : null}
        </div>
      )
    })}</>)





    const riskFactorTable = () => {
      return (
        <div>

          <div className="row">
            <div className="col">
              <div className="risk-factor">
                <div className="icon">
                  <img src={MaleIcon} alt="Male Icon" />
                </div>
                <p>Being male</p>
              </div>
            </div>
            {riskRow(sexLensedPredicate)}
          </div>
          <div className="row">
            <div className="col">
              <div className="risk-factor">
                <div className="icon">
                  <img src={EGFRIcon} alt="eGFR Icon" />
                </div>
                <p>Rapid kidney function decline*
                </p>
              </div>
            </div>
            {riskRow(eGRFLensedPredicate)}
          </div>
          <div className="row">
            <div className="col">
              <div className="risk-factor">
                <div className="icon">
                  <img src={USKLIcon} alt="US-KL Icon" />
                </div>
                <p>Large kidney length measured by ultrasound†
                </p>
              </div>
            </div>
            {riskRow(kidneyLengthLensedPredicate)}
          </div>
          <div className="row">
            <div className="col">
              <div className="risk-factor">
                <div className="icon">
                  <img src={HeartIcon} alt="Heart Icon" />
                </div>
                <p>High Blood Pressure at an early age
                </p>
              </div>
            </div>
            {riskRow(hyperBefore35LensedPredicate)}
          </div>
          <div className="row">
            <div className="col">
              <div className="risk-factor">
                <div className="icon">
                  <img src={DropIcon} alt="Drop Icon" />
                </div>
                <p>Events affecting the urinary tract (e.g. blood in the urine or lower back pain)
                </p>
              </div>
            </div>
            {riskRow(urologicalBefore35LensedPredicate)}
          </div>
          <div className="row">
            <div className="col">
              <div className="risk-factor">
                <div className="icon">
                  <img src={ChartIcon} alt="Chart Icon" />
                </div>
                <p>Family history of kidney failure by age 58
                </p>
              </div>
            </div>
            {riskRow(familyESRDLensedPredicate)}
          </div>
          {/* <div className="row">
            <div className="col">
              <div className="risk-factor">
                <div className="icon">
                  <img src={HelixIcon} alt="Helix Icon" />
                </div>
                <p>
                  Truncating <i>PKD1</i> mutation
                </p>
              </div>
            </div>
            {riskRow(mutationsLensedPredicate)}
          </div> */}
          <div className="row">
            <div className="col">
              <div className="risk-factor">
                <div className="icon">
                  <img src={TKVIcon} alt="TKV Icon" />
                </div>
                <p>Large kidney volume compared to healthy kidneys
                </p>
              </div>
            </div>
            {riskRow(tKVLensedPredicate)}
          </div>
          <div className="row">
            <div className="col">
              <div className="risk-factor">
                <div className="icon">
                  <img src={RiskIcon} alt="Other Risk Factors Icon" />
                </div>
                <p>Other Risk Factors (e.g., Obesity)</p>
              </div>
            </div>
            {riskRow(otherRiskFactorsLensedPredicate)}
          </div>

        </div>


      );
    }




    return (
      <Page
        title="Autosomal Dominant Polycystic Kidney Disease (ADPKD) Progression Simulation"
        className="simulator-homepage"
      >
        <p className="page-crumb">Case Profiles</p>
        <h1 className="title">Disease Progression Simulation</h1>

        {/** patient table */}
        <div className="patient-table">
          <div className="row borderless instruction-row">
            <div className="col" />
            <div className="select-patient-container">
              <div className="instruction-title">
                Select a <strong>hypothetical</strong> patient
              </div>
            </div>
          </div>
          <div className="row header borderless">
            <div className="col">
              <strong>KNOWN RISK FACTORS <sup>1</sup>:</strong>
            </div>
            {makeProfileLinks}
          </div>
          <div className="risk-factors-table">{riskFactorTable()}</div>
        </div>
        <div className="footer-section">
          <p>
            *Confirmed eGFR decline <span className="symbol">≥</span> 5
            mL/min/1.73 m<sup>2</sup> within a year and/or{" "}
            <span className="symbol">≥</span> 2.5 mL/min/1.73 m<sup>2</sup> per
            year over a period of five years.<sup>2</sup>
          </p>
          <p>
            †When MRI-/CT-calculated TKV is not available, US-KL may be a useful
            surrogate when rapid progression is defined as CKD stage 3
            development within 8 years.<sup>2,3</sup>
          </p>
          <p className="glossary">
            ADPKD=autosomal dominant polycystic kidney disease; PKD=polycystic
            kidney disease; TKV=total kidney volume; US-KL=ultrasound kidney
            length; ESKD=end-stage kidney disease; eGFR=estimated glomerular
            filtration rate; MRI=magnetic resonance imaging; CT=computed
            tomography.
          </p>
          <div className="references">
            <strong>References:</strong>
            <p>
              1. Schrier RW, et al. Predictors of autosomal dominant polycystic
              kidney disease progression. <i>J Am Soc Nephrol</i>.
              2014;25:2399-2418.
            </p>
            <p>
              2. Gansevoort RT, et al. Recommendations for the use of tolvaptan
              in autosomal dominant polycystic kidney disease: a position
              statement on behalf of the ERA-EDTA Working Groups on Inherited
              Kidney Disorders and European Renal Best Practice.{" "}
              <i>Nephrol Dial Transplant</i>. 2016;31(3):337-48.
            </p>
            <p>
              3. Bhutani H, et al. A comparison of ultrasound and magnetic
              resonance imaging shows that kidney length predicts chronic kidney
              disease in autosomal dominant polycystic kidney disease.{" "}
              <i>Kidney Int</i>. 2015;88:146-151.
            </p>

          </div>
        </div>
      </Page>
    );
  }
}
