import PropTypes from "prop-types";
import React from "react";
import "./index.scss";

const ImagingChart = ({ patient, onClose }) => (
  <div className="ic-modal">
    <div className="modal-content">
      <span className="close" onClick={onClose} onTouchEnd={onClose} />
      <h1 className="title-bar">
        ADPKD Imaging Classification*:&nbsp;{patient.tag}
      </h1>
      <figure
        style={{
          display: "flex",
          "flex-direction": "column",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center"
        }}
      >
        <h3>ADPKD imaging classification by htTKV and Age*</h3>
        <img
          style={{ "margin-top": "20px" }}
          src={`${process.env.REACT_APP_PUBLIC_URL}/images/${patient.tag}-imaging-chart.jpg`}
          alt="IC"
        />
      </figure>
      <p>
        ADPKD Imaging Classification is only for use in ADPKD patients
        classified as Typical (Class 1). Atypical (Class 2) patients should be
        excluded.
      </p>
      <p>
        Adapted with permission of The American Society of Nephrology, from
        Irazabal MV, et al. Imaging classification of autosomal polycystic
        kidney disease: a simple model for selecting patients for clinical
        trials. <i>J Am Soc Nephrol</i>. 2015;26(1):160-172.
      </p>
      <p>
        *Classification is a simple model for selecting patients for clinical
        trials.
      </p>
    </div>
  </div>
);

ImagingChart.propTypes = {
  patient: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired
};

export default ImagingChart;
