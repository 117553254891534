import React from "react";
import PropTypes from "prop-types";
import { VictoryLabel } from "victory";

const CkdLabel = ({ y, scaleFactor, isTkv, text }) => {
  return isTkv ? null : (
    <VictoryLabel
      x={252 * scaleFactor}
      y={scaleFactor === 1 ? y : y * scaleFactor * 1.1}
      style={{ fontSize: 7 * scaleFactor }}
      text={text}
    />
  );
};

CkdLabel.propTypes = {
  y: PropTypes.number.isRequired,
  scaleFactor: PropTypes.number.isRequired,
  isTkv: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};

export default CkdLabel;
