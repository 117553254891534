export default {
  diseaseProgression: {
    baseURL: "/videos/disease-progression",
    title: "Autosomal Dominant Polycystic Kidney Disease",
    subtitle: "Disease Progression",
    description:
      "In this presentation, Jill Green, PhD, Medical Science Liaison for Otsuka Pharmaceutical Development & Commercialization, Inc., discusses the pathogenesis and progression of ADPKD; how cyst growth precedes a decline in kidney function; variability of ADPKD progression rates; various predictors of disease progression; and the data behind using baseline htTKV to assess the rate of disease progression in Typical (Class 1) ADPKD.",
    pdf: "/pdfs/disease-progression.pdf"
  },
  glomerularFiltration: {
    baseURL: "/videos/gfr-and-tkv",
    title: "Glomerular Filtration Rate vs Total Kidney Volume (TKV)",
    subtitle:
      "Predictive Markers of Disease Progression and Why TKV is Important",
    description:
      "In this presentation, Jeremy Moretz, PharmD, BCPS, Medical Science Liaison for Otsuka Pharmaceutical Development & Commercialization, Inc., discusses the limitations of solely relying on GFR as a marker of ADPKD progression; variabilities of ADPKD progression rates; and explains why baseline htTKV is an independent predictor of GFR decline for Typical (Class 1) ADPKD, especially in younger patients.",
    pdf: "/pdfs/gfr-and-tkv.pdf"
  },
  assessmentTool: {
    baseURL: "/videos/propkd-score",
    title: "A Rapid Progression Assessment Tool",
    subtitle: "PROPKD Score Predicting Renal Outcomes in ADPKD",
    description:
      "In this presentation, Saba Gidey, PharmD, Medical Science Liaison for Otsuka Pharmaceutical Development & Commercialization, Inc., discusses the PROPKD score as a rapid progression assessment tool; PKD mutations, gender, early onset of hypertension and urological events as predictors of ADPKD progression; and the limitations of the PROPKD score.",
    pdf: "/pdfs/propkd-score.pdf"
  },
  imagingMethodology: {
    baseURL: "/videos/imaging-methodology",
    title: "Imaging Methodology",
    subtitle: "Ultrasound, CT, and MRI for Imaging in ADPKD",
    description:
      "In this presentation, Kaitlin Dehlin, PhD, Medical Science Liaison for Otsuka Pharmaceutical Development & Commercialization, Inc., discusses the use of ultrasound, MRI, and CT for measuring kidney length, cyst burden, and TKV in ADPKD; the accuracy, advantages, and disadvantages of each; and explains why MRI is the recommended imaging technique.",
    pdf: "/pdfs/imaging-methodology.pdf"
  },
  determiningRateOfProgression: {
    baseURL: "/videos/determining-rate-of-progression",
    title: "Determining the Rate of Progression in ADPKD",
    subtitle: "Total Kidney Volume (TKV) as a Predictive Marker",
    description:
      "In this panel discussion, nephrologist Linda Belayev, MD, and radiologist Ty Bae, MD, PhD, MBA, discuss how to predict rates of ADPKD progression using TKV, and why MRI is the best imaging modality for obtaining kidney measurements.",
    pdf: ""
  },
  understandingADPKD: {
    baseURL: "/videos/understand-adpkd",
    title: "Understanding ADPKD",
    subtitle: "",
    description:
      "In this animation, the mechanism of ADPKD disease is reviewed.",
    pdf: ""
  },
  burdensOfPKD: {
    baseURL: "/videos/burdens-of-pkd",
    title: "The Burdens of PKD",
    subtitle: "",
    description:
      "In this video, Ann Bartels talks about the financial, emotional, and physical difficulties of having PKD, and her hopes for finding a cure with the help of the PKD Foundation.",
    pdf: ""
  },
  lifeChangingEffectsOfPKD: {
    baseURL: "/videos/life-changing-effects-of-pkd",
    title: "The Life-Changing Effects of PKD",
    subtitle: "",
    description:
      "In this video, Nicole Harr, director of patient and community engagement at the PKD Foundation, talks about her experiences as a PKD patient and the effects it has on her family.",
    pdf: ""
  },
  physicalSymptomsOfPKD: {
    baseURL: "/videos/physical-symptoms-of-pkd",
    title: "The Physical Symptoms of PKD",
    subtitle: "",
    description:
      "In this video, Peggy Robinson, a volunteer fundraising coordinator at the PKD foundation, describes in detail the physical symptoms she lives with every day, as well as her hopes for improvement in physician understanding of PKD.",
    pdf: ""
  }
};
