import React, { Component } from "react";
import { Page } from "../../components/Page";
import KidneyLength from "../../images/ultrasound-kidney-length.jpg";

export default class UltrasoundKidneyLength extends Component {
  render() {
    return (
      <Page
        title="Prognostic Tools - Ultrasound Kidney Length (US-KL)"
        className="ultrasound-kidney-length"
      >
        <h1 className="title">Ultrasound Kidney Length (US-KL)</h1>
        <p>
          US-KL alone can be used to determine whether there is risk of rapid
          disease progression in ADPKD.*<sup>1</sup>
        </p>
        <p>
          MRI is the recommended imaging modality for the most accurate and
          reproducible measurement of kidney length, kidney cyst burden, and
          TKV. As such, MRI/CT-calculated TKV is a strong predictor of future
          renal insufficiency in ADPKD. However,{" "}
          <strong>
            US-measured KL is a useful surrogate when MRI/CT-calculated TKV is
            not feasible.
          </strong>
          <sup>1</sup>
        </p>
        <div className="image-block">
          <div>
            <p className="title">
              US-measured predictor of rapid progression<sup>&#8224;</sup> KL
              &gt; 16.5 cm<span> in patients less than 45 years old</span>
            </p>
            <p className="footnote">
              <sup>&#8224;</sup>when rapid progression is defined as CKD stage 3
              development within 8 years<sup>1,2</sup>
            </p>
          </div>
          <img src={KidneyLength} alt="Kidney Length > 16.5 cm" />
        </div>
        <div className="footer-section">
          <div className="limitation-container">
            <strong>Limitations</strong>
            <ul>
              <li>
                In data analysis, KL was not normalized for height, which is an
                important variable.*<sup>2</sup>
              </li>
              <li>
                US-measured KL is less accurate with larger kidneys.<sup>1</sup>
              </li>
              <li>
                US measurements are operator-dependent and lack precision and
                accuracy for detecting short-term changes in kidney volume and
                increase the risk of misclassifying ADPKD progression.
                <sup>1,3,4</sup>
              </li>
              <li>
                Young patients with lengths &lt; 16.5 cm may still have rapidly
                progressing disease.<sup>3</sup>
              </li>
              <li>
                Atypical patients with slow progression may have lengths &gt;
                16.5 cm.<sup>3</sup>
              </li>
            </ul>
          </div>
          <p className="footnote">
            *Based on data analysis comparing baseline US and MRI KL
            measurements from the Consortium for Radiologic Imaging Studies of
            Polycystic Kidney Disease (CRISP)<sup>1</sup>
          </p>
          <p className="glossary">
            ADPKD=autosomal dominant polycystic kidney disease; MRI=magnetic
            resonance imaging; TKV=total kidney volume; CT=computed tomography;
            CKD=chronic kidney disease.
          </p>
          <div className="references">
            <strong>References:</strong>
            <p>
              1. Bhutani H, et al. A comparison of ultrasound and magnetic
              resonance imaging shows that kidney length predicts chronic kidney
              disease in autosomal dominant polycystic kidney disease.{" "}
              <i>Kidney Int.</i> 2015;88:146-151.
            </p>
            <p>
              2. Gansevoort RT, et al. Recommendations for the use of tolvaptan
              in autosomal dominant polycystic kidney disease: a position
              statement on behalf of the ERA-EDTA Working Groups on Inherited
              Kidney Disorders and European Renal Best Practice. <i>Nephrol Dial
              Transplant</i>. 2016;31(3):337-48.
            </p>
            <p>
              3. Chebib FT, Perrone RD, Chapman AB, Dahl NK, Harris PC, Mrug M,
              Mustafa RA, Rastogi A, Watnick T, Alan SL, Torres VE. A practical
              guide for treatment of rapidly progressive ADPKD with tolvaptan.
              <i>Journal of the American Society of Nephrology</i>. 2018;
              29(10):2458-70.
            </p>
            <p>
              4. Magistroni R, et al. A review of the imaging techniques for
              measuring kidney and cyst volume in establishing autosomal
              dominant polycystic kidney disease progression.{" "}
              <i>Am J Nephrol.</i> 2018;48:67-78.
            </p>
          </div>
        </div>
      </Page>
    );
  }
}
