import React, { Component } from "react";
import calcVolume from "../helpers/CalculateVolume";
import Constants from "../helpers/Constants";
import dataLayer from "../helpers/dataLayer";
import PI from "../images/pi.js";

export default class CalculateTKVForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      animateValues: false,
      animateTotals: false,
      animateVolume: false,
      animateResults: false,
      viewTracked: false
    };

    this.trackView = this.trackView.bind(this);
    this.timeouts = [];
  }

  componentDidUpdate(prevProps) {
    if (this.props.open !== prevProps.open) {
      // Accordion has been opened
      if (this.props.open) {
        this.animateTextInput();
        if (!this.state.viewTracked) {
          this.trackView();
          this.setState({ viewTracked: true });
        }
      } else {
        // Accordion has been closed
        this.timeouts.forEach(timeout => clearTimeout(timeout));
        this.timeouts = [];
        this.setState({
          animateValues: false,
          animateTotals: false,
          animateVolume: false,
          animateResults: false
        });
      }
    }
  }

  trackView() {
    // Send to data layer
    dataLayer.push({
      event: "tkv.calculator",
      action: "Viewed: Calculate TKV"
    });
    // Clean values so they are not picked up by future tags
    dataLayer.clean("action");
  }

  animateTextInput() {
    const stateKeys = [
      "animateValues",
      "animateTotals",
      "animateVolume",
      "animateResults"
    ];

    stateKeys.forEach((elem, k) => {
      const timeout = setTimeout(() => {
        this.setState({ [stateKeys[k]]: true });
      }, k * Constants.TYPING_SPEED);

      this.timeouts.push(timeout);
    });
  }
  render() {
    const fillValues = this.props.calculateTKV;
    const loadValues = this.props.step === 1;
    const doMath = this.props.step === 2;
    const calculateTKV = this.props.step === 3;
    const leftTKV = calcVolume(
      this.props.leftKidneyLength,
      this.props.leftKidneyWidth,
      this.props.leftKidneyDepth
    );
    const rightTKV = calcVolume(
      this.props.rightKidneyLength,
      this.props.rightKidneyWidth,
      this.props.rightKidneyDepth
    );
    const leftTKVVolume = Number(Number(leftTKV).toFixed(1));
    const rightTKVVolume = Number(Number(rightTKV).toFixed(1));
    const TKVValue = calculateTKV
      ? Number((Number(leftTKV) + Number(rightTKV)).toFixed(1))
      : null;
    const animateText = this.props.patient !== "user";
    const steps = [
      <span className="steps">
        Start with the <br />
        basic formula:
      </span>,
      <span className="steps">
        Insert the <br />
        kidney values:
      </span>,
      <span className="steps">Do the math:</span>,
      <span className="steps">
        Convert to mL <br />
        for final volume:*
      </span>
    ];
    const isHighlighted =
      this.state.animateValues ||
      this.state.animateTotals ||
      this.state.animateVolume;
    return (
      <div
        className="calculate-tkv-form"
        ref={
          loadValues
            ? el => (this.AnimatedValuesRow = el)
            : doMath
            ? el => (this.AnimatedTotalsRow = el)
            : calculateTKV
            ? el => (this.AnimatedResultsRow = el)
            : null
        }
      >
        <div className="flex">
          {steps[this.props.step]}
          <div
            className={`formula-container${isHighlighted ? " highlight" : ""}`}
          >
            {calculateTKV || doMath ? null : (
              <div className="flex">
                <div className="pi-formula">
                  <span className="pi-symbol">
                    <PI />
                  </span>
                  6
                </div>
                <span className="times-symbol">&bull;</span>
              </div>
            )}

            <div className="dimension-formula-container">
              <span className="kidney-title">
                Left Kidney{calculateTKV ? " Volume" : ""}
              </span>
              <span className="dimension-formula">
                {fillValues ? (
                  calculateTKV ? (
                    animateText ? (
                      <span className="kidney-value">
                        <div
                          className={
                            this.state.animateVolume
                              ? `animated ${Constants.animation.data}`
                              : "vis-hidden"
                          }
                        >
                          {leftTKVVolume.toLocaleString()}
                          &nbsp;mL
                        </div>
                      </span>
                    ) : (
                      <span className="kidney-value">
                        {leftTKVVolume.toLocaleString()}
                        &nbsp;mL
                      </span>
                    )
                  ) : doMath ? (
                    animateText ? (
                      <div
                        className={
                          this.state.animateTotals
                            ? `animated ${Constants.animation.data}`
                            : "vis-hidden"
                        }
                      >
                        <span className="kidney-value">
                          {(leftTKV * 1000).toLocaleString()}&nbsp;mm
                          <sup>3</sup>
                        </span>
                      </div>
                    ) : (
                      <span className="kidney-value">
                        {(leftTKV * 1000).toLocaleString()}&nbsp;mm<sup>3</sup>
                      </span>
                    )
                  ) : (
                    <span>
                      (
                      {animateText ? (
                        <div
                          className={
                            this.state.animateValues
                              ? `animated ${Constants.animation.data}`
                              : "vis-hidden"
                          }
                        >
                          <span className="kidney-value">
                            {this.props.leftKidneyLength}
                            <span> mm</span>
                          </span>
                        </div>
                      ) : (
                        <span className="kidney-value">
                          {this.props.leftKidneyLength}
                          <span> mm</span>
                        </span>
                      )}
                      <span className="times-symbol">x</span>
                      {animateText ? (
                        <div
                          className={
                            this.state.animateValues
                              ? `animated ${Constants.animation.data}`
                              : "vis-hidden"
                          }
                        >
                          <span className="kidney-value">
                            {this.props.leftKidneyWidth}
                            <span> mm</span>
                          </span>
                        </div>
                      ) : (
                        <span className="kidney-value">
                          {this.props.leftKidneyWidth}
                          <span> mm</span>
                        </span>
                      )}
                      <span className="times-symbol">x</span>
                      {animateText ? (
                        <div
                          className={
                            this.state.animateValues
                              ? `animated ${Constants.animation.data}`
                              : "vis-hidden"
                          }
                        >
                          <span className="kidney-value">
                            {this.props.leftKidneyDepth}
                            <span> mm</span>
                          </span>
                        </div>
                      ) : (
                        <span className="kidney-value">
                          {this.props.leftKidneyDepth}
                          <span> mm</span>
                        </span>
                      )}
                      )
                    </span>
                  )
                ) : (
                  <span>
                    ( L<sub>L</sub>
                    <span className="times-symbol">x</span>W<sub>L</sub>
                    <span className="times-symbol">x</span>D<sub>L</sub> )
                  </span>
                )}
              </span>
            </div>
          </div>
          <span className="plus-symbol">+</span>
          <div
            className={`formula-container${isHighlighted ? " highlight" : ""}`}
          >
            {calculateTKV || doMath ? null : (
              <div className="flex">
                <div className="pi-formula">
                  <span className="pi-symbol">
                    <PI />
                  </span>
                  6
                </div>
                <span className="times-symbol">&bull;</span>
              </div>
            )}
            <div className="dimension-formula-container">
              <span className="kidney-title">
                Right Kidney{calculateTKV ? " Volume" : ""}
              </span>
              <span className="dimension-formula">
                {fillValues ? (
                  calculateTKV ? (
                    animateText ? (
                      <span className="kidney-value">
                        <div
                          className={
                            this.state.animateVolume
                              ? `animated ${Constants.animation.data}`
                              : "vis-hidden"
                          }
                        >
                          {rightTKVVolume.toLocaleString()}
                          &nbsp;mL
                        </div>
                      </span>
                    ) : (
                      <span className="kidney-value">
                        {rightTKVVolume.toLocaleString()}
                        &nbsp;mL
                      </span>
                    )
                  ) : doMath ? (
                    animateText ? (
                      <div
                        className={
                          this.state.animateTotals
                            ? `animated ${Constants.animation.data}`
                            : "vis-hidden"
                        }
                      >
                        <span className="kidney-value">
                          {(rightTKV * 1000).toLocaleString()}&nbsp;mm
                          <sup>3</sup>
                        </span>
                      </div>
                    ) : (
                      <span className="kidney-value">
                        {(rightTKV * 1000).toLocaleString()}&nbsp;mm<sup>3</sup>
                      </span>
                    )
                  ) : (
                    <span>
                      (
                      {animateText ? (
                        <div
                          className={
                            this.state.animateValues
                              ? `animated ${Constants.animation.data}`
                              : "vis-hidden"
                          }
                        >
                          <span className="kidney-value">
                            {this.props.rightKidneyLength}
                            <span> mm</span>
                          </span>
                        </div>
                      ) : (
                        <span className="kidney-value">
                          {this.props.rightKidneyLength}
                          <span> mm</span>
                        </span>
                      )}
                      <span className="times-symbol">x</span>
                      {animateText ? (
                        <div
                          className={
                            this.state.animateValues
                              ? `animated ${Constants.animation.data}`
                              : "vis-hidden"
                          }
                        >
                          <span className="kidney-value">
                            {this.props.rightKidneyWidth}
                            <span> mm</span>
                          </span>
                        </div>
                      ) : (
                        <span className="kidney-value">
                          {this.props.rightKidneyWidth}
                          <span> mm</span>
                        </span>
                      )}
                      <span className="times-symbol">x</span>
                      {animateText ? (
                        <div
                          className={
                            this.state.animateValues
                              ? `animated ${Constants.animation.data}`
                              : "vis-hidden"
                          }
                        >
                          <span className="kidney-value">
                            {this.props.rightKidneyDepth}
                            <span> mm</span>
                          </span>
                        </div>
                      ) : (
                        <span className="kidney-value">
                          {this.props.rightKidneyDepth}
                          <span> mm</span>
                        </span>
                      )}
                      )
                    </span>
                  )
                ) : (
                  <span>
                    ( L<sub>R</sub>
                    <span className="times-symbol">x</span>W<sub>R</sub>
                    <span className="times-symbol">x</span>D<sub>R</sub> )
                  </span>
                )}
              </span>
            </div>
          </div>
          {calculateTKV ? (
            <span className="formula-result final">
              =&nbsp;&nbsp;
              {animateText ? (
                <div
                  className={
                    this.state.animateResults
                      ? `animated ${Constants.animation.data}`
                      : "vis-hidden"
                  }
                >
                  <span className="tkv-value-container">
                    <div>TKV</div>
                    <span className="tkv-value">
                      {TKVValue.toLocaleString()} <span>mL</span>
                    </span>
                  </span>
                </div>
              ) : (
                <span className="tkv-value-container">
                  <div>TKV</div>
                  <span className="tkv-value">
                    {TKVValue.toLocaleString()} <span>mL</span>
                  </span>
                </span>
              )}
            </span>
          ) : (
            <span className="formula-result">=&nbsp;&nbsp;TKV</span>
          )}
          {calculateTKV ? (
            <span className="conversionText">
              *Unit conversion: 1,000 mm<sup>3</sup> = 1 mL
            </span>
          ) : null}
        </div>
      </div>
    );
  }
}
